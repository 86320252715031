import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Divider from "@mui/material/Divider";
import InboxIcon from "@mui/icons-material/Inbox";
import DraftsIcon from "@mui/icons-material/Drafts";
import { ITranslatable } from "interfaces";
import { Stack, Typography } from "@mui/material";
import { useTenantContext } from "components/contexts/TenantContext";
import { translatables } from "helpers/translatables";

export default function TranslatablesList({
  translatableItems,
  setTranslatableItem,
  translatable,
  selectedIndex,
  setSelectedIndex,
}: {
  translatableItems: ITranslatable[];
  setTranslatableItem: any;
  translatable: typeof translatables.products;
  selectedIndex: null | number;
  setSelectedIndex: any;
}) {
  const { categoriesList } = useTenantContext();

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number,
    translatableItem: ITranslatable
  ) => {
    setSelectedIndex(index);
    setTranslatableItem(translatableItem);
  };

  return (
    <List
      sx={{
        width: "100%",
        maxWidth: 360,
        maxHeight: 700,
        bgcolor: "background.paper",
        overflow: "auto",
      }}
      component="nav"
    >
      {translatableItems.map((translatableItem, index) => {
        return (
          <ListItemButton
            key={
              translatableItem.id.toString() +
              index.toString() +
              translatableItem.name
            }
            selected={selectedIndex === index}
            onClick={(event) =>
              handleListItemClick(event, index, translatableItem)
            }
          >
            <ListItemText primary={translatableItem.name} />
          </ListItemButton>
        );
      })}

      {translatable.id === translatables.categories.id &&
        categoriesList.map((category: any, index: number) => {
          return (
            <ListItemButton
              key={category.id.toString() + category.name}
              selected={selectedIndex === index}
              onClick={(event) => handleListItemClick(event, index, category)}
            >
              <Stack sx={{ minHeight: "50px" }} justifyContent={"flex-end"}>
                {category.parentName && (
                  <Typography
                    sx={{
                      color: "primary.dark",
                      fontSize: "10px",
                      fontWeight: 500,
                    }}
                  >
                    {category.parentName}
                  </Typography>
                )}
                <Typography>{category.name}</Typography>
              </Stack>
            </ListItemButton>
          );
        })}
    </List>
  );
}
