import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useTranslation from "components/customHooks/translations";
import { getSubscription } from "services/products";
import LoadingIcon from "components/Feedback/LoadingIcon";
import PageHeader from "components/PageHeader";
import SubscriptionsForm from "./SubscriptionsForm";
import { Box, Button, Stack } from "@mui/material";
import { FileCopyOutlined } from "@mui/icons-material";

const EditSubscriptions = () => {
  const [subscription, setSubscription] = useState<any>(null);
  const [subscriptionisLoading, setSubscriptionisLoading] = useState(true);
  const translation = useTranslation();
  const { subscriptionId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (subscriptionId !== undefined) {
      getSubscription(subscriptionId)
        .then((subscription: any) => {
          if (subscription.data) {
            setSubscription(subscription.data);
            setSubscriptionisLoading(false);
          }
        })
        .catch((e: any) => console.log(e));
    }
  }, [subscriptionId]);

  return (
    <>
      {subscriptionisLoading ? (
        <LoadingIcon />
      ) : (
        <>
          <Stack direction="row" justifyContent={"space-between"}>
            <PageHeader>{translation.editSubscriptionTitle}</PageHeader>
            <Box>
              <Button
                variant="outlined"
                onClick={() => {
                  navigate("/copy-subscription/" + subscriptionId);
                }}
                startIcon={<FileCopyOutlined />}
              >
                {translation.subscriptions.copyButton}
              </Button>
            </Box>
          </Stack>
          {subscription && (
            <SubscriptionsForm
              subscriptionToEdit={subscription}
            ></SubscriptionsForm>
          )}
        </>
      )}
    </>
  );
};

export default EditSubscriptions;
