export const invoiceRecurrences = [
  {
    slug: "onetime",
    label: "Single Invoice",
  },
  {
    slug: "monthly",
    label: "Every Month",
  },
  {
    slug: "quarterly",
    label: "Every 3 Months",
  },
  {
    slug: "yearly",
    label: "Every Year",
  },
];
