import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import useTranslation from "components/customHooks/translations";
import { getProduct } from "services/products";
import LoadingIcon from "components/Feedback/LoadingIcon";
import PageHeader from "components/PageHeader";
import ProductsForm from "./ProductsForm";
import { Box, Button, Stack } from "@mui/material";
import { FileCopyOutlined } from "@mui/icons-material";

const EditProducts = () => {
  const [product, setProduct] = useState<any>(null);
  const [productisLoading, setProductisLoading] = useState(true);
  const translation = useTranslation();
  const { productId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (productId !== undefined) {
      getProduct(productId)
        .then((product) => {
          if (product.data) {
            setProduct(product.data);
            setProductisLoading(false);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [productId]);

  return (
    <>
      {productisLoading ? (
        <LoadingIcon />
      ) : (
        <>
          <Stack direction="row" justifyContent={"space-between"}>
            <PageHeader>{translation.editProductTitle}</PageHeader>
            <Box>
              <Button
                variant="outlined"
                onClick={() => {
                  navigate("/copy-product/" + productId);
                }}
                startIcon={<FileCopyOutlined />}
              >
                {translation.products.copyButton}
              </Button>
            </Box>
          </Stack>
          {product && <ProductsForm productToEdit={product}></ProductsForm>}
        </>
      )}
    </>
  );
};

export default EditProducts;
