import React, { useEffect, useMemo, useState } from "react";
import useTranslation from "components/customHooks/translations";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Avatar,
  Box,
  Button,
  Card,
  CircularProgress,
  DialogContentText,
  Divider,
  Drawer,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import {
  IAddress,
  ICustomer,
  IInvoiceLine,
  IInvoiceSeries,
  IInvoiceTemplate,
  IProduct,
  ITax,
  IVariation,
} from "interfaces";
import { useUserContext } from "components/contexts/UserContext";
import { useLoadingContext } from "components/contexts/LoadingContext";
import {
  createInvoiceTemplate,
  getAvailablePaymentOptionsByTenant,
  getCustomer,
  getPaymentOptionsByTenant,
  searchCustomers,
  updateInvoiceTemplate,
} from "services/tenants";
import { ExpandMore } from "@mui/icons-material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import {
  calculateGrossPrice,
  calculateNetPrice,
  calculateTaxAmount,
  errorMessage,
  getApiResponseData,
} from "helpers";
import { useNavigate } from "react-router-dom";
import { exchangeCurrency, searchProducts } from "services/products";
import AddressForm from "pages/Customers/AddressForm";
import { invoiceRecurrences } from "helpers/invoiceRecurrences";
import { Formik } from "formik";
import ArrayInvoiceLinesField from "components/Form/ArrayInvoiceLinesField";
import { LoadingButton } from "@mui/lab";
import QuillTextField from "components/Form/QuillTextField/QuillTextField";
import { routeNames } from "routes";
import AddressCard from "components/Customers/AddressCard";
import ProductSearch from "components/Orders/ProductSearch";
import { formDrawerWidth } from "components/Form/constants";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { getUnit } from "@mui/material/styles/cssUtils";
import { useTenantContext } from "components/contexts/TenantContext";

interface CustomProps {
  onChange: (event: { target: { value: string } }) => void;
  name: string | undefined;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, name, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              value: values.value,
            },
          });
        }}
        decimalScale={2}
        allowNegative={false}
        valueIsNumericString
      />
    );
  }
);

const NumericPercentageFormatCustom = React.forwardRef<
  NumericFormatProps,
  CustomProps
>(function NumericPercentageFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      max={100}
      min={0}
      isAllowed={(values) => {
        const { floatValue } = values;
        if (floatValue) return floatValue <= 100;
        else return true;
      }}
      allowNegative={false}
      decimalScale={2}
      valueIsNumericString
      suffix="%"
    />
  );
});

const InvoiceTemplateForm = ({
  recurrence = "onetime",
  invoiceTemplateToEdit,
}: {
  recurrence: string;
  invoiceTemplateToEdit?: IInvoiceTemplate | null;
}) => {
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const { selectedTenant, tenants } = useUserContext();
  const t = useTranslation();
  const { loading, setLoading } = useLoadingContext();
  const [customerSearch, setCustomerSearch] = useState("");
  const [updateAddresses, setUpdateAddresses] = useState(false);
  const [customers, setCustomers] = useState<any>([]);
  const [showNewAddress, setShowNewAddress] = useState(false);
  const [billingAddress, setBillingAddress] = useState<IAddress | null>(null);
  const [loadingSearchedProducts, setLoadingSearchedProducts] = useState(false);
  const [billingAddressToEdit, setBillingAddressToEdit] =
    useState<IAddress | null>(null);
  const [payments, setPayments] = useState<any>([]);
  const [discountFieldFocus, setDiscountFieldFocus] = useState<
    "" | "percentage" | "amount"
  >("");
  const [selectedCustomer, setSelectedCustomer] = useState<ICustomer | null>(
    null
  );
  const {
    taxes,
    updateTaxesTrigger,
    setUpdateTaxesTrigger,
    taxesLoading,
    setTaxesLoading,
    invoiceSeries,
    updateInvoiceSeriesTrigger,
    setUpdateInvoiceSeriesTrigger,
    invoiceSeriesLoading,
    setInvoiceSeriesLoading,
  } = useTenantContext();
  // const [invoiceTemplate, setInvoiceTemplate] = useState<IInvoiceTemplate>({
  //   tenant:

  // })

  const [indexForDiscount, setIndexForDiscount] = useState<number | null>(null);
  const [openSetDiscount, setOpenSetDiscount] = useState<boolean>(false);
  const [discount, setDiscount] = useState<any>("");
  const [discountPercentage, setDiscountPercentage] = useState<any>("");

  const [selectedPayment, setSelectedPayment] = useState<any>(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const theme = useTheme();
  const [expanded, setExpanded] = React.useState<string | false>("customer");
  const [invoiceTemplate, setInvoiceTemplate] =
    useState<IInvoiceTemplate | null>(null);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const navigate = useNavigate();

  useEffect(() => {
    setLoading(true);
    if (tenants !== null) {
      getAvailablePaymentOptionsByTenant(tenants[selectedTenant].id)
        .then((res) => {
          const availablePayments = getApiResponseData(res).filter(
            (payment: any) => {
              return payment.type == "wire";
            }
          );
          if (availablePayments.length === 1) {
            setSelectedPayment(availablePayments[0]);
          }
          if (invoiceTemplateToEdit) {
            availablePayments.forEach((payment: any) => {
              if (payment["@id"] == invoiceTemplateToEdit.paymentMethod) {
                setSelectedPayment(payment);
              }
            });
          }
          setPayments(availablePayments);
          setLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }, [selectedTenant, tenants, invoiceTemplateToEdit]);

  useEffect(() => {
    let series = null;
    invoiceSeries.forEach((invoiceSingleSeries: IInvoiceSeries) => {
      if (
        tenants[selectedTenant].settings.defaultInvoiceSeries ==
        invoiceSingleSeries["@id"]
      ) {
        series = invoiceSingleSeries;
      }
    });
    if (series !== null) {
      if (!invoiceTemplateToEdit) {
        setInvoiceTemplate({
          tenant: "/tenants/" + tenants[selectedTenant].id,
          series: series,
          invoiceLines: [],
          recurrence: recurrence,
          currency: tenants[selectedTenant].settings.defaultCurrency,
          subtotal: 0,
          discounts: 0,
          vat: 0,
          total: 0,
          notes: "",
          paymentMethod: null,
        });
      }
      setLoading(false);
    }
  }, [invoiceSeries, invoiceTemplateToEdit]);

  useEffect(() => {
    if (invoiceTemplateToEdit) {
      console.log(invoiceTemplateToEdit);
      if (invoiceTemplateToEdit?.customer?.id) {
        getCustomer(invoiceTemplateToEdit?.customer?.id.toString()).then(
          (res) => {
            if (invoiceTemplateToEdit?.customer)
              setSelectedCustomer(invoiceTemplateToEdit.customer);
            if (invoiceTemplateToEdit.billAddressSnapshot) {
              setBillingAddress(invoiceTemplateToEdit.billAddressSnapshot);
            }
          }
        );
      }
      const invoiceLines = invoiceTemplateToEdit.invoiceLines.map(
        (invoiceLine) => {
          for (const tax of taxes) {
            if (invoiceLine.vatPercentage === tax.taxPercentage) {
              return {
                ...invoiceLine,
                vatPercentage: tax["@id"],
              };
            }
          }
          return {
            ...invoiceLine,
          };
        }
      );
      console.log(invoiceLines);
      setInvoiceTemplate({
        tenant: "/tenants/" + tenants[selectedTenant].id,
        series: invoiceTemplateToEdit.series,
        invoiceLines: invoiceLines as any,
        recurrence: invoiceTemplateToEdit.recurrence,
        currency: invoiceTemplateToEdit.currency,
        subtotal: invoiceTemplateToEdit.subtotal,
        discounts: invoiceTemplateToEdit.discounts,
        vat: invoiceTemplateToEdit.vat,
        total: invoiceTemplateToEdit.total,
        notes: invoiceTemplateToEdit.notes,
        paymentMethod: invoiceTemplateToEdit.paymentMethod,
      });
    }
  }, [invoiceTemplateToEdit, taxes]);

  useEffect(() => {
    if (selectedCustomer) {
      if (selectedCustomer.id) {
        getCustomer(selectedCustomer.id.toString()).then((res) => {
          setSelectedCustomer(res.data);
        });
      }
    }
  }, [updateAddresses]);

  useEffect(() => {
    const getData = setTimeout(() => {
      if (customerSearch.length > 1) {
        setLoadingSearchedProducts(true);
        setCustomers([]);
        searchCustomers(customerSearch, tenants[selectedTenant].id)
          .then((response) => {
            setLoadingSearchedProducts(false);

            setCustomers(response.data["hydra:member"]);
          })
          .catch((e) => {
            setLoadingSearchedProducts(false);
            setErrorMessage(errorMessage(e));
          });
      }
    }, 1000);

    return () => clearTimeout(getData);
  }, [customerSearch]);

  const setNewAddress = (address: IAddress) => {
    setExpanded("details");
    setBillingAddress(address);
  };

  const handleSetCustomer = (customer: ICustomer) => {
    setBillingAddress(null);
    setSelectedCustomer(customer);
    setCustomers([]);
    setCustomerSearch("");
  };

  const clearPage = () => {
    setSelectedCustomer(null);
    setSelectedPayment(null);
    setBillingAddress(null);
  };

  const handleAddProduct = async (
    values: any,
    setFieldValue: any,
    product: IProduct,
    variation?: IVariation
  ) => {
    const oldValues = values.invoiceLines.map((v: any) => {
      return { ...v };
    });
    if (!invoiceTemplate) {
      return;
    }
    let netPrice = 0;
    let grossPrice = 0;
    let originalNetPrice = 0;
    let originalGrossPrice = 0;
    if (product.isVariable && variation) {
      netPrice = variation.netPrice;
      grossPrice = variation.grossPrice;
      originalNetPrice = variation.netPrice;
      originalGrossPrice = variation.grossPrice;
    } else {
      netPrice = product.netPrice;
      grossPrice = product.grossPrice;
      originalNetPrice = product.netPrice;
      originalGrossPrice = product.grossPrice;
    }

    let rate = undefined;
    const defaultCurency = invoiceTemplate.currency;

    if (defaultCurency !== product.priceCurrency) {
      setLoading(true);
      const responseNet = await exchangeCurrency(
        product.priceCurrency,
        defaultCurency,
        netPrice
      );
      netPrice = responseNet.data.amount;
      rate = responseNet.data.rate;
      const responseGross = await exchangeCurrency(
        product.priceCurrency,
        defaultCurency,
        grossPrice
      );

      setLoading(false);
      grossPrice = responseGross.data.amount;
    }

    const newTax = product.tax as any;
    oldValues.unshift({
      item: product.name,
      itemDescription: rate
        ? "1 " + product.priceCurrency + " = " + rate + defaultCurency
        : "",
      qty: 1,
      unitPrice: tenants[selectedTenant].settings.taxIncluded
        ? netPrice
        : grossPrice,
      amount: "",
      currency: product.priceCurrency,
      exchangeRate: rate,
      originalPrice: tenants[selectedTenant].settings.taxIncluded
        ? originalNetPrice
        : originalGrossPrice,
      totalVat: Math.round(netPrice / grossPrice),
      priceHasVat: tenants[selectedTenant].settings.taxIncluded,
      vatPercentage: newTax["@id"],
      um: "pcs",
      discount: false,
    });

    setFieldValue("invoiceLines", [...oldValues]);
  };

  async function submitForm(values: any, setIsSubmitting: any) {
    if (!selectedCustomer) {
      setErrorMessage(t.invoices.customerNotSelected);
      return;
    }

    if (!selectedPayment) {
      setErrorMessage(t.invoices.paymentMethodNotSelected);
      return;
    }

    if (!billingAddress) {
      setErrorMessage(t.invoices.billAddressNotSelected);
      return;
    }
    if (invoiceTemplate && billingAddress) {
      let hasErrors = false;
      const invoiceLinesLocal = values.invoiceLines.map(
        (invoiceLine: IInvoiceLine) => {
          let taxPercentage = 0;
          for (const tax of taxes) {
            if ((invoiceLine.vatPercentage as any) === tax["@id"]) {
              taxPercentage = Number(tax.taxPercentage);
            }
          }
          return {
            ...invoiceLine,
            amount: 0,
            totalVat: 0,
            vatPercentage: taxPercentage,
          };
        }
      );
      invoiceLinesLocal.pop();

      if (invoiceLinesLocal.length === 0) {
        setErrorMessage(t.invoices.missingInvoiceLines);
        hasErrors = true;
      }

      invoiceLinesLocal.forEach((invoiceLine: any) => {
        if (invoiceLine.item === "") {
          setErrorMessage(t.invoices.missingLineName);
          hasErrors = true;
        }
        if (invoiceLine.qty == "") {
          setErrorMessage(t.invoices.missingLineQty);
          hasErrors = true;
        }
        if (invoiceLine.unitPrice == "") {
          setErrorMessage(t.invoices.missingLineUnitPrice);
          hasErrors = true;
        }
      });

      if (hasErrors) {
        return;
      }

      const newInvoiceTemplate = {
        id: invoiceTemplateToEdit ? invoiceTemplateToEdit.id : undefined,
        customer: selectedCustomer?.["@id"],
        tenant: "/tenants/" + tenants[selectedTenant].id,
        series: invoiceTemplate.series?.["@id"],
        currency: invoiceTemplate.currency,
        invoiceLines: invoiceLinesLocal,
        billAddress: !invoiceTemplateToEdit ? billingAddress["@id"] : undefined,
        recurrence: invoiceTemplate.recurrence,
        paymentMethod: selectedPayment["@id"],
        subtotal: 0,
        discounts: 0,
        vat: 0,
        total: 0,
        notes: values.notes,
      };
      setIsSubmitting(true);
      if (!invoiceTemplateToEdit) {
        createInvoiceTemplate(newInvoiceTemplate as any)
          .then(() => {
            setSuccessMessage(t.savedMessage);
            setIsSubmitting(false);
            navigate(routeNames.invoices);
          })
          .catch((e) => {
            setErrorMessage(errorMessage(e));
            setIsSubmitting(false);
          });
      } else {
        updateInvoiceTemplate(newInvoiceTemplate as any)
          .then(() => {
            setSuccessMessage(t.savedMessage);
            setIsSubmitting(false);
            navigate(routeNames.invoices);
          })
          .catch((e) => {
            setErrorMessage(errorMessage(e));
            setIsSubmitting(false);
          });
      }
    }
  }

  const getUnitPrice = (invoiceLine: IInvoiceLine) => {
    const anyValue = invoiceLine as any;
    let unitPrice = anyValue.unitPrice;
    if (anyValue.currency !== invoiceTemplate?.currency) {
      unitPrice =
        Math.round(
          (anyValue.exchangeRate * anyValue.originalPrice + Number.EPSILON) *
            100
        ) / 100;
    }
    return unitPrice;
  };

  const calculateSubtotal = (invoiceLines: IInvoiceLine[]) => {
    const subtotal = invoiceLines.reduce((accumulator, currentValue) => {
      const anyValue = currentValue as any;
      if (anyValue.discount === true) {
        return accumulator;
      }
      const unitPrice = getUnitPrice(anyValue);

      let amount =
        anyValue.qty && unitPrice
          ? Math.round((anyValue.qty * unitPrice + Number.EPSILON) * 100) / 100
          : 0;

      let taxPercentage = 0;

      for (const tax of taxes) {
        if (anyValue.vatPercentage === tax["@id"]) {
          taxPercentage = Number(tax.taxPercentage);
        }
      }

      if (currentValue.priceHasVat) {
        amount = calculateGrossPrice(amount, taxPercentage);
      }
      return (
        Math.round((accumulator + Number(amount) + Number.EPSILON) * 100) / 100
      );
    }, 0);
    return subtotal;
  };

  const calculateTotalDiscounts = (invoiceLines: IInvoiceLine[]) => {
    const subtotal = invoiceLines.reduce((accumulator, currentValue) => {
      const anyValue = currentValue as any;
      if (anyValue.discount === false) {
        return accumulator;
      }
      const unitPrice = getUnitPrice(anyValue);
      let amount =
        anyValue.qty && unitPrice
          ? Math.round((anyValue.qty * unitPrice + Number.EPSILON) * 100) / 100
          : 0;

      let taxPercentage = 0;

      for (const tax of taxes) {
        if (anyValue.vatPercentage === tax["@id"]) {
          taxPercentage = Number(tax.taxPercentage);
        }
      }

      if (currentValue.priceHasVat) {
        amount = calculateGrossPrice(amount, taxPercentage);
      }
      return (
        Math.round((accumulator + Number(amount) + Number.EPSILON) * 100) / 100
      );
    }, 0);
    return subtotal;
  };

  const calculateTotalVAT = (invoiceLines: IInvoiceLine[]) => {
    const totalVAT = invoiceLines.reduce((accumulator, currentValue) => {
      const anyValue = currentValue as any;
      const unitPrice = getUnitPrice(anyValue);
      const amount =
        anyValue.qty && unitPrice
          ? Math.round((anyValue.qty * unitPrice + Number.EPSILON) * 100) / 100
          : 0;

      let taxPercentage = 0;

      for (const tax of taxes) {
        if (anyValue.vatPercentage === tax["@id"]) {
          taxPercentage = Number(tax.taxPercentage);
        }
      }
      const vat =
        amount && taxPercentage
          ? calculateTaxAmount(amount, taxPercentage, anyValue.priceHasVat)
          : 0;
      return (
        Math.round((accumulator + Number(vat) + Number.EPSILON) * 100) / 100
      );
    }, 0);
    return totalVAT;
  };

  const calculateTotal = (invoiceLines: IInvoiceLine[]) => {
    const total = invoiceLines.reduce((accumulator, currentValue) => {
      const anyValue = currentValue as any;
      const unitPrice = getUnitPrice(anyValue);
      let amount =
        anyValue.qty && unitPrice
          ? Math.round((anyValue.qty * unitPrice + Number.EPSILON) * 100) / 100
          : 0;

      let taxPercentage = 0;
      for (const tax of taxes) {
        if (anyValue.vatPercentage === tax["@id"]) {
          taxPercentage = Number(tax.taxPercentage);
        }
      }

      if (!currentValue.priceHasVat) {
        amount = calculateNetPrice(amount, taxPercentage);
      }
      return (
        Math.round((accumulator + Number(amount) + Number.EPSILON) * 100) / 100
      );
    }, 0);
    return total;
  };
  return (
    <>
      {invoiceTemplate && taxes.length > 0 && (
        <>
          <Formik
            initialValues={{
              invoiceLines: [
                ...invoiceTemplate.invoiceLines,
                {
                  item: "",
                  itemDescription: "",
                  qty: "",
                  unitPrice: "",
                  amount: "",
                  totalVat: "",
                  originalPrice: 0,
                  exchangeRate: 1,
                  priceHasVat: tenants[selectedTenant].settings.taxIncluded,
                  currency: tenants[selectedTenant].settings.defaultCurrency,
                  vatPercentage: taxes[0]["@id"],
                  um: "pcs",
                  discount: false,
                  generalDiscount: false,
                },
              ],
              currency: invoiceTemplate.currency,
              notes: invoiceTemplate.notes,
              paymentMethod: invoiceTemplate.paymentMethod,
            }}
            onSubmit={(values, actions) => {
              const invoiceLinesLocal = [...values.invoiceLines];
              invoiceLinesLocal.pop();

              const newInvoiceTemplate = {
                ...invoiceTemplate,
                customer: selectedCustomer?.["@id"],
                tenant: "/tenants/" + tenants[selectedTenant].id,
                series: invoiceTemplate.series?.["@id"],
                invoiceLines: invoiceLinesLocal,

                subtotal: 0,
                discounts: 0,
                vat: 0,
                total: 0,
                notes: values.notes,
                paymentMethod: selectedPayment["@id"],
              };
              createInvoiceTemplate(invoiceTemplate)
                .then(() => {
                  setSuccessMessage(t.savedMessage);

                  navigate(routeNames.invoices);
                })
                .catch((e) => {
                  setErrorMessage(errorMessage(e));
                  setIsSubmitting(false);
                });
            }}
            enableReinitialize
          >
            {({
              values,
              errors,
              handleSubmit,
              isSubmitting,
              setFieldValue,
              setSubmitting,
              setFieldError,
              resetForm,
            }) => {
              let hasGeneralDiscount = false;
              values.invoiceLines.map((invoiceLine) => {
                if (invoiceLine.generalDiscount) {
                  hasGeneralDiscount = true;
                }
              });
              return (
                <>
                  <form noValidate className="" onSubmit={handleSubmit}>
                    <Stack spacing={2}>
                      <Accordion
                        expanded={expanded === "customer"}
                        onChange={handleChange("customer")}
                      >
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Stack direction="row" spacing={1}>
                            <Typography variant="h5" sx={{ mb: 3 }}>
                              {t.order.customer}
                            </Typography>
                            {selectedCustomer && (
                              <Typography variant="h5" sx={{ mb: 3 }}>
                                {" "}
                                - {selectedCustomer.name}
                              </Typography>
                            )}
                          </Stack>
                        </AccordionSummary>

                        <AccordionDetails>
                          <Stack spacing={2}>
                            {!invoiceTemplateToEdit && (
                              <Stack spacing={2}>
                                <DialogContentText>
                                  {t.order.setCustomerDescription}
                                </DialogContentText>
                                <Box>
                                  <TextField
                                    placeholder={t.order.search}
                                    variant="standard"
                                    value={customerSearch}
                                    onChange={(e) => {
                                      setCustomerSearch(e.target.value);
                                    }}
                                  ></TextField>
                                </Box>
                                <Box>
                                  {loadingSearchedProducts && (
                                    <Box display="flex" justifyContent="center">
                                      <CircularProgress />
                                    </Box>
                                  )}
                                  {customers.length > 0 && (
                                    <List>
                                      {customers.map((customer: any) => {
                                        return (
                                          <ListItem
                                            key={
                                              "searchedcustomer-" + customer.id
                                            }
                                            disablePadding
                                            sx={{ width: "100%" }}
                                          >
                                            <ListItemButton
                                              onClick={() => {
                                                setFieldValue;
                                                handleSetCustomer(customer);
                                              }}
                                            >
                                              <ListItemText
                                                primary={customer.email}
                                              ></ListItemText>
                                            </ListItemButton>
                                          </ListItem>
                                        );
                                      })}
                                    </List>
                                  )}
                                </Box>
                              </Stack>
                            )}
                            {selectedCustomer && (
                              <Stack spacing={2}>
                                <Typography>
                                  Name:{" "}
                                  {selectedCustomer.firstName +
                                    " " +
                                    selectedCustomer.lastName}
                                </Typography>
                                <Typography>
                                  Email: {selectedCustomer.email}
                                </Typography>
                                <Typography variant="h5">
                                  Billing address
                                </Typography>
                                {!invoiceTemplateToEdit ? (
                                  <Box>
                                    <Button
                                      variant={"contained"}
                                      onClick={() => setShowNewAddress(true)}
                                    >
                                      {t.customers.newAddress}
                                    </Button>
                                  </Box>
                                ) : (
                                  <Stack spacing={2}>
                                    <Box>
                                      <Button
                                        variant={"contained"}
                                        onClick={() => {
                                          setBillingAddressToEdit(
                                            billingAddress
                                          );
                                          setShowNewAddress(true);
                                        }}
                                      >
                                        {t.customers.editAddress}
                                      </Button>
                                    </Box>
                                    {billingAddress && (
                                      <AddressCard
                                        address={billingAddress}
                                        selected={true}
                                        onClick={() => {
                                          setNewAddress(billingAddress);
                                        }}
                                      ></AddressCard>
                                    )}
                                  </Stack>
                                )}
                                {!invoiceTemplateToEdit && (
                                  <Stack direction={"row"} flexWrap={"wrap"}>
                                    {selectedCustomer.addresses.map(
                                      (item: any, ind: any) => {
                                        return (
                                          <AddressCard
                                            key={"billing-address-" + ind}
                                            address={item}
                                            selected={
                                              billingAddress
                                                ? item.id === billingAddress.id
                                                  ? true
                                                  : false
                                                : false
                                            }
                                            onClick={() => {
                                              setNewAddress(item);
                                            }}
                                          ></AddressCard>
                                        );
                                      }
                                    )}
                                  </Stack>
                                )}
                              </Stack>
                            )}
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "details"}
                        onChange={handleChange("details")}
                      >
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography variant="h5" sx={{ mb: 3 }}>
                            {t.invoices.invoiceDetails}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Stack spacing={2}>
                            <Box>
                              <FormControl
                                variant="standard"
                                sx={{ minWidth: 230 }}
                              >
                                <InputLabel
                                  id={"recurrence-select-standard-label"}
                                >
                                  {t.invoices.recurrence}
                                </InputLabel>
                                <Select
                                  disabled={!!invoiceTemplateToEdit}
                                  value={invoiceTemplate.recurrence}
                                  onChange={(e) => {
                                    setInvoiceTemplate({
                                      ...invoiceTemplate,
                                      recurrence: e.target.value,
                                    });
                                  }}
                                >
                                  {invoiceRecurrences.map(
                                    (invoiceRecurrences) => (
                                      <MenuItem
                                        key={invoiceRecurrences.slug}
                                        value={invoiceRecurrences.slug}
                                      >
                                        {invoiceRecurrences.label}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            </Box>
                            <Box>
                              <FormControl
                                variant="standard"
                                sx={{ minWidth: 230 }}
                              >
                                <InputLabel id={"series-select-standard-label"}>
                                  {t.invoices.series}
                                </InputLabel>
                                <Select
                                  value={invoiceTemplate.series["@id"]}
                                  disabled={!!invoiceTemplateToEdit}
                                  onChange={(e) => {
                                    let series = null;
                                    invoiceSeries.forEach(
                                      (invoiceSingleSeries: IInvoiceSeries) => {
                                        if (
                                          e.target.value ==
                                          invoiceSingleSeries["@id"]
                                        ) {
                                          series = invoiceSingleSeries;
                                        }
                                      }
                                    );
                                    if (series !== null) {
                                      setInvoiceTemplate({
                                        ...invoiceTemplate,
                                        series: series,
                                      });
                                    }
                                  }}
                                >
                                  {invoiceSeries.map(
                                    (invoiceSingleSeries: IInvoiceSeries) => (
                                      <MenuItem
                                        key={
                                          "invoiceseries-" +
                                          invoiceSingleSeries.id
                                        }
                                        value={invoiceSingleSeries["@id"]}
                                      >
                                        {invoiceSingleSeries.series} (no.{" "}
                                        {invoiceSingleSeries.currentNumber})
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            </Box>
                            <Box>
                              <FormControl
                                variant="standard"
                                sx={{ minWidth: 230 }}
                              >
                                <InputLabel
                                  id={"currency-select-standard-label"}
                                >
                                  {t.invoices.currency}
                                </InputLabel>
                                <Select
                                  value={invoiceTemplate.currency}
                                  disabled={!!invoiceTemplateToEdit}
                                  onChange={(e) => {
                                    setInvoiceTemplate({
                                      ...invoiceTemplate,
                                      currency: e.target.value,
                                    });
                                  }}
                                >
                                  {tenants[
                                    selectedTenant
                                  ].settings.enabledCurrencies.map(
                                    (enabledCurrency: string) => (
                                      <MenuItem
                                        key={enabledCurrency}
                                        value={enabledCurrency}
                                      >
                                        {enabledCurrency}
                                      </MenuItem>
                                    )
                                  )}
                                </Select>
                              </FormControl>
                            </Box>
                            <Stack spacing={2}>
                              <Typography variant="h6">
                                Payment Method
                              </Typography>
                              <Stack direction={"row"} flexWrap={"wrap"}>
                                {payments &&
                                  payments.map((item: any, ind: any) => {
                                    return (
                                      <Card
                                        key={"shipping-address-" + ind}
                                        sx={{
                                          backgroundColor: "grey.100",
                                          p: "1rem",
                                          m: "8px",
                                          position: "relative",
                                          boxShadow: "none",
                                          border: "1px solid",
                                          cursor: "pointer",
                                          borderColor:
                                            item.id === selectedPayment?.id
                                              ? "primary.main"
                                              : "transparent",
                                        }}
                                        onClick={() => {
                                          setSelectedPayment(item);
                                        }}
                                      >
                                        <Typography mb={0.5}>
                                          {item.label}
                                        </Typography>
                                      </Card>
                                    );
                                  })}
                              </Stack>
                            </Stack>
                            <Box>
                              <Button
                                variant={"contained"}
                                onClick={() => setExpanded("invoiceLines")}
                              >
                                {t.nextButton}
                              </Button>
                            </Box>
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "invoiceLines"}
                        onChange={handleChange("invoiceLines")}
                      >
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography variant="h5" sx={{ mb: 3 }}>
                            {t.invoices.invoiceLines}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Stack spacing={2}>
                            <Box sx={{ px: 2 }}>
                              <ProductSearch
                                handleAddProduct={(
                                  product: IProduct,
                                  variation: IVariation
                                ) => {
                                  handleAddProduct(
                                    values,
                                    setFieldValue,
                                    product,
                                    undefined
                                  );
                                }}
                              ></ProductSearch>
                            </Box>
                            <Box>
                              <ArrayInvoiceLinesField
                                name="invoiceLines"
                                taxes={taxes}
                                setOpenSetDiscount={setOpenSetDiscount}
                                setIndexForDiscount={setIndexForDiscount}
                                hasGeneralDiscount={hasGeneralDiscount}
                              />
                            </Box>
                            <Stack
                              direction={"row"}
                              sx={{ p: 2 }}
                              justifyContent={"space-between"}
                            >
                              <Box>
                                <Button
                                  variant={"outlined"}
                                  onClick={() => {
                                    setOpenSetDiscount(true);
                                    setIndexForDiscount(null);
                                  }}
                                  disabled={hasGeneralDiscount}
                                >
                                  {t.invoices.addDiscount}
                                </Button>
                              </Box>
                              <Stack alignItems={"flex-end"}>
                                <Box sx={{ minWidth: "400px" }}>
                                  <Stack spacing={2}>
                                    <Stack
                                      direction="row"
                                      justifyContent={"space-between"}
                                    >
                                      <Typography variant="body2">
                                        Subtotal (without VAT):{" "}
                                      </Typography>
                                      <Typography variant="body2">
                                        {calculateSubtotal(
                                          values.invoiceLines as any
                                        ) +
                                          " " +
                                          invoiceTemplate.currency}
                                      </Typography>
                                    </Stack>
                                    <Stack
                                      direction="row"
                                      justifyContent={"space-between"}
                                    >
                                      <Typography variant="body2">
                                        Discounts (without VAT):{" "}
                                      </Typography>
                                      <Typography variant="body2">
                                        {calculateTotalDiscounts(
                                          values.invoiceLines as any
                                        ) +
                                          " " +
                                          invoiceTemplate.currency}
                                      </Typography>
                                    </Stack>
                                    <Stack
                                      direction="row"
                                      justifyContent={"space-between"}
                                    >
                                      <Typography variant="body2">
                                        Total VAT:{" "}
                                      </Typography>
                                      <Typography variant="body2">
                                        {calculateTotalVAT(
                                          values.invoiceLines as any
                                        ) +
                                          " " +
                                          invoiceTemplate.currency}
                                      </Typography>
                                    </Stack>
                                    <Divider />
                                    <Stack
                                      direction="row"
                                      justifyContent={"space-between"}
                                    >
                                      <Typography variant="body2">
                                        Total:{" "}
                                      </Typography>
                                      <Typography variant="body2">
                                        {calculateTotal(
                                          values.invoiceLines as any
                                        ) +
                                          " " +
                                          invoiceTemplate.currency}
                                      </Typography>
                                    </Stack>
                                  </Stack>
                                </Box>

                                <Stack
                                  direction="row"
                                  spacing={3}
                                  sx={{ mt: 3 }}
                                >
                                  <Button
                                    variant={"outlined"}
                                    onClick={() => setExpanded("other")}
                                  >
                                    {t.nextButton}
                                  </Button>
                                  <LoadingButton
                                    variant={"contained"}
                                    onClick={() =>
                                      submitForm(values, setSubmitting)
                                    }
                                    loading={isSubmitting}
                                  >
                                    {t.saveButton}
                                  </LoadingButton>
                                </Stack>
                              </Stack>
                            </Stack>
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                      <Accordion
                        expanded={expanded === "other"}
                        onChange={handleChange("other")}
                      >
                        <AccordionSummary expandIcon={<ExpandMore />}>
                          <Typography variant="h5" sx={{ mb: 3 }}>
                            Other details
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Stack spacing={2}>
                            <Typography variant="body2">Notes:</Typography>
                            <QuillTextField
                              name="notes"
                              noMedia
                            ></QuillTextField>
                            <Box>
                              <LoadingButton
                                variant={"contained"}
                                onClick={() =>
                                  submitForm(values, setSubmitting)
                                }
                                loading={isSubmitting}
                              >
                                {t.saveAndBackButton}
                              </LoadingButton>
                            </Box>
                          </Stack>
                        </AccordionDetails>
                      </Accordion>
                    </Stack>
                    <Drawer
                      anchor="right"
                      open={openSetDiscount}
                      onClose={() => {
                        setOpenSetDiscount(false);
                        setDiscount("");
                        setDiscountPercentage("");
                        if (indexForDiscount !== null) {
                          setIndexForDiscount(null);
                        }
                      }}
                      sx={{
                        "& .MuiDrawer-paper": { width: formDrawerWidth },
                      }}
                    >
                      <Stack spacing={2} sx={{ p: 2 }}>
                        <Typography variant="h5">
                          {t.order.applyDiscount}
                        </Typography>
                        {indexForDiscount !== null ? (
                          <Stack spacing={2}>
                            <TextField
                              variant="standard"
                              label={t.order.discountPercentage}
                              onFocus={() =>
                                setDiscountFieldFocus("percentage")
                              }
                              onBlur={() => setDiscountFieldFocus("")}
                              onChange={(event) => {
                                if (event.target.value === "") {
                                  setDiscount("");
                                  setDiscountPercentage("");
                                  return;
                                }
                                if (discountFieldFocus == "percentage") {
                                  const percentage = parseFloat(
                                    event.target.value
                                  );

                                  let unitPrice =
                                    values.invoiceLines[indexForDiscount]
                                      .currency === invoiceTemplate.currency
                                      ? values.invoiceLines[indexForDiscount]
                                          .unitPrice
                                      : values.invoiceLines[indexForDiscount]
                                          .originalPrice;
                                  if (unitPrice) {
                                    unitPrice =
                                      typeof unitPrice === "string"
                                        ? parseFloat(unitPrice)
                                        : unitPrice;

                                    setDiscount(
                                      Math.round(unitPrice * percentage) / 100
                                    );
                                  }
                                }
                              }}
                              value={
                                discountPercentage ? discountPercentage : ""
                              }
                              placeholder="%"
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                inputComponent:
                                  NumericPercentageFormatCustom as any,
                              }}
                            ></TextField>

                            <TextField
                              variant="standard"
                              label={
                                t.order.discountAmmount +
                                " (" +
                                values.invoiceLines[indexForDiscount].currency +
                                ")"
                              }
                              value={discount}
                              onChange={(event) => {
                                if (event.target.value === "") {
                                  setDiscount("");
                                  setDiscountPercentage("");
                                  return;
                                }
                                const discountValue = parseFloat(
                                  event.target.value
                                );
                                let unitPrice =
                                  values.invoiceLines[indexForDiscount]
                                    .currency === invoiceTemplate.currency
                                    ? values.invoiceLines[indexForDiscount]
                                        .unitPrice
                                    : values.invoiceLines[indexForDiscount]
                                        .originalPrice;
                                if (unitPrice) {
                                  unitPrice =
                                    typeof unitPrice === "string"
                                      ? parseFloat(unitPrice)
                                      : unitPrice;
                                  if (discountValue > unitPrice) {
                                    setDiscount(unitPrice);
                                    return;
                                  }

                                  setDiscount(discountValue);
                                  if (!discountValue) {
                                    setDiscountPercentage("");
                                    return;
                                  }
                                  setDiscountPercentage(
                                    Math.round(
                                      (discountValue / unitPrice) * 10000
                                    ) / 100
                                  );
                                }
                              }}
                              InputProps={{
                                inputComponent: NumericFormatCustom as any,
                              }}
                            ></TextField>
                            {values.invoiceLines[indexForDiscount] && (
                              <TextField
                                variant="standard"
                                label={
                                  t.order.discountSalePrice +
                                  " (" +
                                  values.invoiceLines[indexForDiscount]
                                    .currency +
                                  ")"
                                }
                                onChange={(event) => {
                                  const unitPrice =
                                    values.invoiceLines[indexForDiscount]
                                      .currency === invoiceTemplate.currency
                                      ? values.invoiceLines[indexForDiscount]
                                          .unitPrice
                                      : values.invoiceLines[indexForDiscount]
                                          .originalPrice;
                                  if (event.target.value == "") {
                                    setDiscount(unitPrice);
                                    return;
                                  }
                                  if (
                                    (unitPrice as any) -
                                      parseFloat(event.target.value) <
                                    0
                                  ) {
                                    setDiscount(0);
                                  } else {
                                    setDiscount(
                                      (unitPrice as any) -
                                        parseFloat(event.target.value)
                                    );
                                  }
                                }}
                                InputLabelProps={{ shrink: true }}
                                value={
                                  (values.invoiceLines[indexForDiscount]
                                    .currency === invoiceTemplate.currency
                                    ? (values.invoiceLines[indexForDiscount]
                                        .unitPrice as any)
                                    : (values.invoiceLines[indexForDiscount]
                                        .originalPrice as any)) - discount
                                }
                                InputProps={{
                                  inputComponent: NumericFormatCustom as any,
                                }}
                              ></TextField>
                            )}
                          </Stack>
                        ) : (
                          <Stack spacing={2}>
                            <TextField
                              variant="standard"
                              label={t.order.discountPercentage}
                              onChange={(event) => {
                                if (event.target.value === "") {
                                  setDiscount("");
                                  setDiscountPercentage("");
                                  return;
                                }
                                const percentage = parseFloat(
                                  event.target.value
                                );
                                const tempTotal = calculateTotal(
                                  values.invoiceLines as any
                                );
                                setDiscount(
                                  Math.round(tempTotal * percentage) / 100
                                );
                              }}
                              value={
                                discountPercentage ? discountPercentage : ""
                              }
                              placeholder="%"
                              InputLabelProps={{ shrink: true }}
                              InputProps={{
                                inputComponent:
                                  NumericPercentageFormatCustom as any,
                              }}
                            ></TextField>

                            <TextField
                              variant="standard"
                              label={t.order.discountAmmount}
                              value={discount}
                              onChange={(event) => {
                                if (event.target.value === "") {
                                  setDiscount("");
                                  setDiscountPercentage("");
                                  return;
                                }
                                const discountValue = parseFloat(
                                  event.target.value
                                );

                                const subtotal = calculateTotal(
                                  values.invoiceLines as any
                                );

                                if (discountValue > subtotal) {
                                  setDiscount(subtotal);
                                  return;
                                }

                                setDiscount(discountValue);
                                if (!discountValue) {
                                  setDiscountPercentage("");
                                  return;
                                }

                                setDiscountPercentage(
                                  Math.round(
                                    (discountValue / subtotal) * 10000
                                  ) / 100
                                );
                              }}
                              InputProps={{
                                inputComponent: NumericFormatCustom as any,
                              }}
                            ></TextField>

                            <TextField
                              variant="standard"
                              label={t.order.subtotal}
                              onChange={(event) => {
                                const tempTotal = calculateTotal(
                                  values.invoiceLines as any
                                );
                                if (event.target.value == "") {
                                  setDiscount(tempTotal);
                                  return;
                                }
                                if (
                                  tempTotal - parseFloat(event.target.value) <
                                  0
                                ) {
                                  setDiscount(0);
                                } else {
                                  setDiscount(
                                    tempTotal - parseFloat(event.target.value)
                                  );
                                }
                              }}
                              InputLabelProps={{ shrink: true }}
                              value={
                                calculateTotal(values.invoiceLines as any) -
                                discount
                              }
                              InputProps={{
                                inputComponent: NumericFormatCustom as any,
                              }}
                            ></TextField>
                          </Stack>
                        )}

                        <Stack
                          direction={"row"}
                          spacing={2}
                          justifyContent={"space-between"}
                        >
                          <Button
                            variant={"outlined"}
                            onClick={() => {
                              setOpenSetDiscount(false);
                              if (indexForDiscount !== null) {
                                setIndexForDiscount(null);
                              }
                              setDiscount("");
                              setDiscountPercentage("");
                            }}
                          >
                            {t.closeButton}
                          </Button>
                          <Button
                            variant="contained"
                            onClick={() => {
                              if (!discount) {
                                setErrorMessage(
                                  t.order.discountAmmountRequired
                                );
                                return;
                              }
                              if (indexForDiscount !== null) {
                                let unitPrice = -discount;
                                if (
                                  values.invoiceLines[indexForDiscount]
                                    .currency !== invoiceTemplate.currency
                                ) {
                                  const exchageRate = values.invoiceLines[
                                    indexForDiscount
                                  ].exchangeRate as any;
                                  unitPrice = unitPrice * exchageRate;
                                }
                                const newInvoiceLine: any = {
                                  ...values.invoiceLines[indexForDiscount],
                                  item: "Discount",
                                  discount: true,
                                  itemDescription:
                                    "For " +
                                    values.invoiceLines[indexForDiscount].item,
                                  unitPrice: unitPrice,
                                  originalPrice:
                                    values.invoiceLines[indexForDiscount]
                                      .currency !== invoiceTemplate.currency
                                      ? -discount
                                      : undefined,
                                  currency:
                                    values.invoiceLines[indexForDiscount]
                                      .currency,
                                };
                                const newInvoiceLines = [
                                  ...values.invoiceLines,
                                ];
                                newInvoiceLines.splice(
                                  indexForDiscount + 1,
                                  0,
                                  newInvoiceLine
                                );

                                setFieldValue("invoiceLines", newInvoiceLines);
                              } else {
                                const newInvoiceLines = [
                                  ...values.invoiceLines,
                                ] as any;
                                newInvoiceLines.splice(
                                  newInvoiceLines.length - 1,
                                  0,
                                  {
                                    item: "Discount",
                                    itemDescription: "",
                                    qty: 1,
                                    unitPrice: -discount,
                                    amount: 0,
                                    currency: invoiceTemplate.currency,
                                    totalVat: 0,
                                    priceHasVat:
                                      tenants[selectedTenant].settings
                                        .taxIncluded,
                                    vatPercentage:
                                      values.invoiceLines[0].vatPercentage,
                                    um: "pcs",
                                    discount: true,
                                    generalDiscount: true,
                                  }
                                );
                                setFieldValue("invoiceLines", newInvoiceLines);
                              }
                              setOpenSetDiscount(false);
                              setDiscount("");
                              setDiscountPercentage("");
                              setIndexForDiscount(null);
                            }}
                          >
                            {t.saveButton}
                          </Button>
                        </Stack>
                      </Stack>
                    </Drawer>
                  </form>
                </>
              );
            }}
          </Formik>
          {selectedCustomer && (
            <AddressForm
              updateTrigger={updateAddresses}
              setUpdateTrigger={setUpdateAddresses}
              setShowNewAddress={setShowNewAddress}
              showNewAddress={showNewAddress}
              customer={selectedCustomer}
              setNewAddress={setNewAddress}
              addressToEdit={billingAddressToEdit}
              setAddressToEdit={setBillingAddressToEdit}
            ></AddressForm>
          )}
        </>
      )}
    </>
  );
};

export default InvoiceTemplateForm;
