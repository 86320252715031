import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import * as Yup from "yup";
import useTranslation from "components/customHooks/translations";
import FormTextField from "components/Form/FormTextField/FormTextField";
import SelectField from "components/Form/SelectField";
import { Formik } from "formik";
import { createAddress, updateAddress } from "services/tenants";
import {
  Box,
  Button,
  Drawer,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import SwitchField from "components/Form/SwitchField/SwitchField";
import { formDrawerWidth } from "components/Form/constants";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { IAddress, ICustomer } from "interfaces";
import { errorMessage } from "helpers";
import DropZoneBrandField from "components/Form/DropZoneBrandField";
import AutocompleteCountryField from "components/Form/AutocompleteCountryField";
import countrylist from "helpers/countryList";
import { LoadingButton } from "@mui/lab";

const AddressForm = ({
  showNewAddress,
  setShowNewAddress,
  addressToEdit,
  setAddressToEdit,
  updateTrigger,
  setUpdateTrigger,
  setNewAddress,
  customer,
}: {
  showNewAddress: boolean;
  setShowNewAddress: React.Dispatch<React.SetStateAction<boolean>>;
  addressToEdit?: IAddress | null;
  setAddressToEdit?: React.Dispatch<React.SetStateAction<IAddress | null>>;
  updateTrigger: boolean;
  setUpdateTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setNewAddress?: any;
  customer?: ICustomer;
}) => {
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();

  const translation = useTranslation();

  const addressSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, translation.signUpShortError)
      .max(50, translation.signUpLongError)
      .required(translation.required),
    streetAddress: Yup.string()
      .min(2, translation.signUpShortError)
      .max(255, translation.signUpLongError)
      .required(translation.required),
    city: Yup.string()
      .min(2, translation.signUpShortError)
      .max(255, translation.signUpLongError)
      .required(translation.required),
    county: Yup.string()
      .min(2, translation.signUpShortError)
      .max(63, translation.signUpLongError)
      .required(translation.required),
    country: Yup.string()
      .min(2, translation.signUpShortError)
      .max(63, translation.signUpLongError)
      .required(translation.required),
    phoneNumber: Yup.string()
      .min(2, translation.signUpShortError)
      .max(15, translation.signUpLongError)
      .required(translation.required),
    label: Yup.string()
      .min(2, translation.signUpShortError)
      .max(20, translation.signUpLongError)
      .required(translation.required),
  });

  return (
    <>
      <Drawer
        anchor="right"
        open={showNewAddress}
        onClose={() => {
          setShowNewAddress(false);
          if (setAddressToEdit) {
            setAddressToEdit(null);
          }
        }}
        sx={{
          "& .MuiDrawer-paper": { width: formDrawerWidth },
        }}
      >
        <Formik
          initialValues={{
            name: addressToEdit ? addressToEdit.name : "",
            streetAddress: addressToEdit ? addressToEdit.streetAddress : "",
            city: addressToEdit ? addressToEdit.city : "",
            county: addressToEdit ? addressToEdit.county : "",
            country: addressToEdit ? addressToEdit.country : "",
            phoneNumber: addressToEdit ? addressToEdit.phoneNumber : "",
            label: addressToEdit ? addressToEdit.label : "",
            additionalInfo: addressToEdit ? addressToEdit.additionalInfo : "",
            postalCode: addressToEdit ? addressToEdit.postalCode : "",
            companyName: addressToEdit ? addressToEdit.companyName : "",
            vatId: addressToEdit ? addressToEdit.vatId : "",
            fiscalId: addressToEdit ? addressToEdit.fiscalId : "",
          }}
          enableReinitialize
          validationSchema={addressSchema}
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            if (addressToEdit) {
              updateAddress({
                ...addressToEdit,
                name: values.name,
                streetAddress: values.streetAddress,
                city: values.city,
                county: values.county,
                country: values.country,
                phoneNumber: values.phoneNumber,
                label: values.label,
                additionalInfo: values.additionalInfo,
                postalCode: values.postalCode,
                companyName: values.companyName,
                vatId: values.vatId,
                fiscalId: values.fiscalId,
              })
                .then((res) => {
                  setSubmitting(false);
                  setShowNewAddress(false);
                  if (setAddressToEdit) {
                    setAddressToEdit(null);
                  }

                  setSuccessMessage(translation.savedMessage);
                  setUpdateTrigger(!updateTrigger);
                  if (setNewAddress) {
                    setNewAddress(res.data);
                  }
                })
                .catch((e) => {
                  setErrorMessage(errorMessage(e));
                  setSubmitting(false);
                });
            } else {
              if (customer) {
                createAddress({
                  name: values.name,
                  streetAddress: values.streetAddress,
                  city: values.city,
                  county: values.county,
                  country: values.country,
                  phoneNumber: values.phoneNumber,
                  label: values.label,
                  additionalInfo: values.additionalInfo,
                  postalCode: values.postalCode,
                  companyName: values.companyName,
                  vatId: values.vatId,
                  customer: customer["@id"],
                  fiscalId: values.fiscalId,
                })
                  .then((res) => {
                    setSubmitting(false);
                    setShowNewAddress(false);
                    if (setAddressToEdit) {
                      setAddressToEdit(null);
                    }
                    setSuccessMessage(translation.savedMessage);
                    setUpdateTrigger(!updateTrigger);
                    if (setNewAddress) {
                      setNewAddress(res.data);
                    }
                  })
                  .catch((e) => {
                    setErrorMessage(errorMessage(e));
                    setSubmitting(false);
                  });
              }
            }
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form noValidate className="" onSubmit={handleSubmit}>
              <Box sx={{ p: 4 }}>
                <Typography variant="h5">
                  {addressToEdit
                    ? translation.customers.newAddress
                    : translation.customers.editAddress}
                </Typography>
                <Stack spacing={2}>
                  <FormTextField
                    label={translation.customers.labelLabel}
                    name="label"
                  />
                  <FormTextField
                    label={translation.customers.addressNameLabel}
                    name="name"
                  />
                  <FormTextField
                    label={translation.customers.contactPhoneNumberLabel}
                    name="phoneNumber"
                  />
                  <FormTextField
                    label={translation.customers.companyNameLabel}
                    name="companyName"
                  />
                  <FormTextField
                    label={translation.customers.vatIdLabel}
                    name="vatId"
                  />
                  <FormTextField
                    label={translation.customers.fiscalIdLabel}
                    name="fiscalId"
                  />
                  <FormTextField
                    label={translation.customers.streetAddressLabel}
                    name="streetAddress"
                  />
                  <FormTextField
                    label={translation.customers.postalCodeLabel}
                    name="postalCode"
                  />
                  <FormTextField
                    label={translation.customers.countyLabel}
                    name="county"
                  />
                  <FormTextField
                    label={translation.customers.cityLabel}
                    name="city"
                  />
                  <AutocompleteCountryField
                    controlId="country01"
                    label={translation.customers.countryLabel}
                    type="text"
                    name="country"
                    options={countrylist}
                  />
                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"space-between"}
                  >
                    <Button
                      variant={"outlined"}
                      onClick={() => {
                        setShowNewAddress(false);
                        if (setAddressToEdit) {
                          setAddressToEdit(null);
                        }
                      }}
                    >
                      {translation.closeButton}
                    </Button>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={isSubmitting}
                    >
                      {translation.saveButton}
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Box>
            </form>
          )}
        </Formik>
      </Drawer>
    </>
  );
};

export default AddressForm;
