import { useState, useEffect } from "react";
import { parseJwt } from "helpers";
import { getUser, login, refreshLogin } from "services/users";
import { getParentCategoriesByTenant } from "services/products";
import { useUserContext } from "components/contexts/UserContext";
import { ICategory, ICategoryHierarchy } from "interfaces";

export default function useCategories() {
  const [categories, setCategories] = useState<ICategory[]>([]);
  const [updateCategoriesTrigger, setUpdateCategoriesTrigger] = useState(false);
  const [categoriesList, setCategoriesList] = useState<ICategoryHierarchy[]>(
    []
  );
  const [parentCategories, setParentCategories] = useState<
    ICategoryHierarchy[]
  >([]);

  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [requestLoading, setRequestLoading] = useState(true);

  const { tenantsIsLoading, selectedTenant, tenants } = useUserContext();

  useEffect(() => {
    setCategoriesLoading(true);
    setRequestLoading(true);
    if (tenants !== null) {
      if (tenants[selectedTenant]) {
        getParentCategoriesByTenant(tenants[selectedTenant].id)
          .then((res) => {
            setRequestLoading(false);
            setCategories(res.data["hydra:member"]);
          })
          .catch((e) => console.log(e));
      }
    }
  }, [selectedTenant, tenants, updateCategoriesTrigger]);

  useEffect(() => {
    if (categoriesList.length > 0) {
      setCategoriesLoading(false);
    }
  }, [categoriesList]);

  useEffect(() => {
    if (categories.length > 0) {
      const newParentCategories: ICategoryHierarchy[] = [];
      const newCategoriesList: ICategoryHierarchy[] = [];
      categories.forEach((category: ICategory) => {
        newParentCategories.push({
          id: category.id,
          name: category.name,
        });
        newCategoriesList.push({
          id: category.id,
          name: category.name,
        });
        if (category.subcategories)
          category.subcategories.forEach((subcategories: ICategory) => {
            newParentCategories.push({
              id: subcategories.id,
              name: subcategories.name,
              parentName: category.name,
            });
            newCategoriesList.push({
              id: subcategories.id,
              name: subcategories.name,
              parentName: category.name,
            });
            if (subcategories.subcategories)
              subcategories.subcategories.forEach(
                (subsubcategory: ICategory) => {
                  newCategoriesList.push({
                    id: subsubcategory.id,
                    name: subsubcategory.name,
                    parentName: category.name + " • " + subcategories.name,
                  });
                }
              );
          });
      });

      setParentCategories(newParentCategories);
      setCategoriesList(newCategoriesList);
    }
    if (requestLoading == false) {
      setCategoriesLoading(false);
    }
  }, [categories]);

  return {
    categories,
    categoriesList,
    parentCategories,
    updateCategoriesTrigger,
    setUpdateCategoriesTrigger,
    categoriesLoading,
    setCategoriesLoading,
  };
}
