import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import { Formik, FormikHelpers } from "formik";
import {
  createSettings,
  getSettingsByTenant,
  updateSettings,
} from "services/tenants";

import PapperCollapse from "components/PaperCollapse";
import { useLoadingContext } from "components/contexts/LoadingContext";
import {
  Autocomplete,
  Box,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { ISettings } from "interfaces";
import { LoadingButton } from "@mui/lab";
import { errorMessage } from "helpers";
import currencies from "helpers/currencies";
import FormTextField from "components/Form/FormTextField/FormTextField";
import InvoiceSeries from "./InvoiceSeries";
import Taxes from "pages/Taxes";
import { useTenantContext } from "components/contexts/TenantContext";
import Currencies from "./Currencies";
import LocalizationSettings from "./LocalizationSettings";

interface CurrencyFormValues {
  defaultCurrency?: string;
  enabledCurrencies: string[];
}

interface InvoiceFormValues {
  invoiceSeries?: string;
}

const GeneralCustomOptions = () => {
  const { updateTrigger, setUpdateTrigger } = useUserContext();
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const {
    settings,
    updateSettingsTrigger,
    setUpdateSettingsTrigger,
    settingsLoading,
    setSettingsLoading,
  } = useTenantContext();
  const [enabledCurrencies, setEnabledCurrencies] = useState([""]);
  const translation = useTranslation();

  useEffect(() => {
    if (settings !== null) {
      if (settings.enabledCurrencies && settings.enabledCurrencies.length > 0) {
        setEnabledCurrencies(settings.enabledCurrencies);
      } else {
        setEnabledCurrencies(Object.keys(currencies));
      }
    }
  }, [settings]);

  return (
    <Stack spacing={4}>
      {settings == null ? (
        <>
          {!settingsLoading && (
            <Typography>{translation.waitForSettings}</Typography>
          )}
        </>
      ) : (
        <>
          <Currencies></Currencies>
          <LocalizationSettings></LocalizationSettings>
          <Taxes settings={settings}></Taxes>
          <InvoiceSeries settings={settings}></InvoiceSeries>
        </>
      )}
    </Stack>
  );
};

export default GeneralCustomOptions;
