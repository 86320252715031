import React, { useEffect, useState } from "react";
import useTranslation from "components/customHooks/translations";
import { useUserContext } from "components/contexts/UserContext";
import LoadingIcon from "components/Feedback/LoadingIcon";
import PageHeader from "components/PageHeader";
import InvoiceTemplateForm from "./InvoiceTemplateForm";
import { useParams, useNavigate } from "react-router-dom";
import {
  getInvoiceTemplate,
  getInvoiceTemplatesByTenant,
} from "services/tenants";
import { IInvoiceTemplate } from "interfaces";

const EditRecurrence = () => {
  const { tenantsIsLoading, selectedTenant } = useUserContext();
  const [invoiceTemplateIsLoading, setInvoiceTemplateIsLoading] =
    useState(true);
  const [invoiceTemplate, setInvoiceTemplate] =
    useState<IInvoiceTemplate | null>(null);

  const translation = useTranslation();
  const { templateId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (templateId !== undefined) {
      getInvoiceTemplate(templateId)
        .then((response) => {
          if (response.data) {
            console.log(response.data);
            setInvoiceTemplate(response.data);
            setInvoiceTemplateIsLoading(false);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [templateId]);

  return (
    <>
      {invoiceTemplateIsLoading ? (
        <LoadingIcon />
      ) : (
        selectedTenant != null && (
          <>
            <PageHeader>{translation.editRecurrenceTitle}</PageHeader>
            {invoiceTemplate && (
              <InvoiceTemplateForm
                recurrence="monthly"
                invoiceTemplateToEdit={invoiceTemplate}
              ></InvoiceTemplateForm>
            )}
          </>
        )
      )}
    </>
  );
};

export default EditRecurrence;
