import { Field } from "formik";
import TextField from "@mui/material/TextField";

const FormTextField = ({
  label,
  name,
  type = "text",
  multiline = false,
  minRows = 1,
  lineRows = true,
  disabled = false,
  helperText = "",
}: {
  label?: string;
  name: string;
  type: string;
  multiline?: boolean;
  minRows?: number;
  lineRows?: boolean;
  disabled?: boolean;
  helperText?: string;
}) => {
  return (
    <Field name={name}>
      {({ field, form }: any) => {
        const isValid = !form.errors[field.name];
        const isInvalid = form.touched[field.name] && !isValid;
        return (
          <TextField
            {...field}
            minRows={multiline && lineRows ? 3 : 1}
            multiline={multiline}
            disabled={disabled}
            label={label}
            type={type}
            variant="standard"
            value={field.value}
            error={isInvalid}
            helperText={isInvalid ? form.errors[field.name] : helperText}
          />
        );
      }}
    </Field>
  );
};

FormTextField.defaultProps = {
  type: "text",
  inputGroupPrepend: null,
};

export default FormTextField;
