import { useState, useEffect } from "react";
import { parseJwt } from "helpers";
import { getUser, login, refreshLogin } from "services/users";
import { useUserContext } from "components/contexts/UserContext";
import { ITax } from "interfaces";
import { getTaxesByTenant } from "services/tenants";

export default function useTaxes() {
  const [taxes, setTaxes] = useState<ITax[]>([]);
  const [updateTaxesTrigger, setUpdateTaxesTrigger] = useState(false);

  const [taxesLoading, setTaxesLoading] = useState(true);

  const { selectedTenant, tenants } = useUserContext();

  useEffect(() => {
    setTaxesLoading(true);
    if (tenants !== null) {
      if (tenants[selectedTenant]) {
        getTaxesByTenant(tenants[selectedTenant].id)
          .then((res) => {
            if (res.data["hydra:member"].length > 0) {
              setTaxes(res.data["hydra:member"]);
              setTaxesLoading(false);
            }
            setTaxesLoading(false);
          })
          .catch((e) => {
            setTaxesLoading(false);
          });
      }
    }
  }, [selectedTenant, tenants, updateTaxesTrigger]);

  return {
    taxes,
    updateTaxesTrigger,
    setUpdateTaxesTrigger,
    taxesLoading,
    setTaxesLoading,
  };
}
