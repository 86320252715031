import axios from "helpers/axios";
import { backendURL } from "config";
import { IInvoice, IOrder } from "interfaces";

export async function getOrdersByTenant(
  tenantId: string,
  page = 1,
  itemsPerPage = 10,
  orderBy = "id",
  order = "desc",
  statusFilter = ""
) {
  const filterQuery = statusFilter !== "" ? "&status=" + statusFilter : "";
  const URL =
    backendURL +
    "/orders?tenant.id=" +
    tenantId +
    "&page=" +
    page +
    "&itemsPerPage=" +
    itemsPerPage +
    "&order[" +
    orderBy +
    "]=" +
    order +
    filterQuery;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => error);
  return res;
}

export async function getOrder(orderId: string) {
  const URL = backendURL + "/orders/" + orderId;
  const res = axios
    .get(URL)
    .then((res) => res)
    .catch((error) => error);
  return res;
}

export async function createOrder(order: any) {
  const URL = backendURL + "/orders";

  const res = axios
    .post(URL, order)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function createAwb(orderId: string, shippingMethodId: string) {
  const URL = backendURL + "/awbs";

  const res = await axios.post(URL, {
    shopOrder: orderId,
    shippingMethod: shippingMethodId,
  });

  return res;
}

export async function createInvoice(invoice: any) {
  const URL = backendURL + "/invoices";

  const res = await axios.post(URL, invoice);

  return res;
}

export async function getInvoice(invoiceId: string) {
  const URL = backendURL + invoiceId;

  const res = await axios.get(URL);

  return res;
}

export async function getInvoiceDownload(invoiceId: string) {
  const URL = backendURL + invoiceId + "/download";

  const res = await axios.get(URL);

  return res;
}

export async function editOrder(order: any) {
  const URL = backendURL + "/orders/" + order.id;

  const res = axios
    .patch(URL, order)
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function editOrderStatus(orderId: any, status: string) {
  const URL = backendURL + "/orders/" + orderId;

  const res = axios
    .patch(URL, { status })
    .then((res) => res)
    .catch((error) => {
      throw error;
    });
  return res;
}

export async function updateOrder(order: IOrder) {
  if (order.id) {
    const URL = backendURL + "/orders/" + order.id;
    const res = axios
      .put(URL, order)
      .then((res) => res)
      .catch((error) => {
        throw error;
      });
    return res;
  }
}
