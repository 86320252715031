import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Stack,
  Button,
  ToggleButton,
  Collapse,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  SelectChangeEvent,
} from "@mui/material";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import { getProductsByTenant } from "services/products";
import { useNavigate } from "react-router-dom";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CircularProgress from "@mui/material/CircularProgress";
import EnhancedTableHead, {
  HeadCell,
  Order,
} from "components/Table/EnhancedTableHead";
import { Add, HiveOutlined, PlusOne } from "@mui/icons-material";
import { routeNames } from "routes";
import ProductsMenu from "components/Menu/ProductsMenu";
import LoadingIcon from "components/Feedback/LoadingIcon";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { ICategoryHierarchy, IProduct, IVariation } from "interfaces";
import { useTenantContext } from "components/contexts/TenantContext";

interface Data {
  id: number;
  name: string;
  type: string;
  variations: string;
  sku: string;
  stock: number | string;
  price: string;
}

const headCells: HeadCell[] = [
  {
    id: "name",
    numeric: true,
    disablePadding: false,
    label: "Name",
    sorting: false,
  },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Type",
    sorting: false,
  },
  {
    id: "sku",
    numeric: true,
    disablePadding: false,
    label: "SKU",
    sorting: false,
  },
  {
    id: "stock",
    numeric: true,
    disablePadding: false,
    label: "Stock",
    sorting: false,
  },
  {
    id: "price",
    numeric: true,
    disablePadding: false,
    label: "Price",
    sorting: false,
  },
];

const Products = () => {
  const { selectedTenant, tenants } = useUserContext();
  const [products, setProducts] = useState<any>(null);
  const [productsCount, setProductsCount] = useState(0);
  const [pagesNumber, setPagesNumber] = useState(0);
  const [categoryFilter, setCategoryFilter] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [rows, setRows] = useState<Data[]>([]);
  const translation = useTranslation();

  const { loading, setLoading } = useLoadingContext();
  const { categoriesList } = useTenantContext();
  const navigate = useNavigate();

  const [order, setOrder] = React.useState<Order>("desc");
  const [orderBy, setOrderBy] = React.useState<string>("id");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, orderId: number) => {
    navigate("/edit-product/" + orderId);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const productsToData = (products: any) => {
    const data = products.map((product: IProduct) => {
      console.log(product);
      let stock = product.stockCount;
      if (product.isVariable && product.variations) {
        stock = 0;
        product.variations.forEach((variation: IVariation) => {
          if (variation.stockCount) {
            stock += variation.stockCount;
          }
        });
      }
      const price = tenants[selectedTenant].settings.taxIncluded
        ? product.netPrice
        : product.grossPrice;

      let productType = "";
      const variations: string[] = [];

      if (product.isVariable) {
        productType = "Variable";
        product.variations?.forEach((variation) => {
          if (
            (product.hasStock && variation.stockCount > 0) ||
            !product.hasStock
          )
            variations.push(variation.name);
        });
      } else {
        productType = "Simple";
      }

      return {
        id: product.id,
        name: product.name,
        type: productType,
        stock: stock,
        sku: product.sku,
        variations: variations.join(", "),
        price: price.toString() + " " + product.priceCurrency,
      };
    });

    return data;
  };

  useEffect(() => {
    setLoading(true);

    if (tenants !== null) {
      console.log(tenants);
      getProductsByTenant(
        tenants[selectedTenant].id,
        page + 1,
        rowsPerPage,
        orderBy,
        order,
        categoryFilter
      )
        .then((res) => {
          setProducts(res.data["hydra:member"]);
          setRows(productsToData(res.data["hydra:member"]));
          setProductsCount(res.data["hydra:totalItems"]);
          setPagesNumber(Math.ceil(res.data["hydra:totalItems"] / rowsPerPage));
          setLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }, [
    selectedTenant,
    tenants,
    page,
    rowsPerPage,
    orderBy,
    order,
    categoryFilter,
  ]);

  useEffect(() => {
    setPage(0);
  }, [selectedTenant]);

  const handleChangeCategoryFilter = (event: SelectChangeEvent) => {
    setCategoryFilter(event.target.value);
    setPage(0);
  };

  return (
    <>
      <ProductsMenu />
      <Box sx={{ width: "100%" }}>
        <Stack
          direction="row"
          spacing={2}
          justifyContent={"flex-end"}
          sx={{ mb: 4 }}
        >
          <Box>
            <Button
              variant="outlined"
              startIcon={<HiveOutlined />}
              onClick={() => setShowFilters(!showFilters)}
            >
              {translation.filters}
            </Button>
          </Box>
          <Box sx={{ mb: 2 }}>
            <Button
              variant={"contained"}
              startIcon={<Add />}
              onClick={() => navigate(routeNames.newProducts)}
            >
              {translation.newProductTitle}
            </Button>
          </Box>
        </Stack>
        <Stack>
          <Collapse in={showFilters}>
            <Stack direction="row" sx={{ marginBottom: 4 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 200 }}>
                <InputLabel id="status-select-standard-label">
                  {translation.products.categoryField}
                </InputLabel>
                <Select
                  labelId="status-select-standard-label"
                  id="status-select-standard"
                  value={categoryFilter}
                  onChange={handleChangeCategoryFilter}
                  label={translation.status}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {categoriesList
                    ? categoriesList.map((category: ICategoryHierarchy) => {
                        return (
                          <MenuItem
                            value={category.id}
                            key={"productCategory" + category.id}
                          >
                            <Stack
                              sx={{ minHeight: "50px" }}
                              justifyContent={"flex-end"}
                            >
                              {category.parentName && (
                                <Typography
                                  sx={{
                                    color: "primary.dark",
                                    fontSize: "10px",
                                    fontWeight: 500,
                                  }}
                                >
                                  {category.parentName}
                                </Typography>
                              )}
                              <Typography>{category.name}</Typography>
                            </Stack>
                          </MenuItem>
                        );
                      })
                    : ""}
                  {/* {orderStatuses.map((orderStatus) => (
                    <MenuItem key={orderStatus.slug} value={orderStatus.slug}>
                      {orderStatus.label}
                    </MenuItem>
                  ))} */}
                </Select>
              </FormControl>
            </Stack>
          </Collapse>
        </Stack>
        {rows.length > 0 ? (
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHead
                  headCells={headCells}
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {!loading ? (
                    rows.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          tabIndex={-1}
                          key={row.id}
                          style={{
                            height: 100,
                            cursor: "pointer",
                          }}
                        >
                          <TableCell>
                            <Stack>
                              {row.name}
                              <Typography variant="caption">
                                {row.variations}
                              </Typography>
                            </Stack>
                          </TableCell>
                          <TableCell>{row.type}</TableCell>
                          <TableCell>{row.sku}</TableCell>
                          <TableCell>{row.stock}</TableCell>
                          <TableCell>{row.price}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow
                      style={{
                        height: 100 * rowsPerPage,
                      }}
                    >
                      <TableCell colSpan={6}>
                        <Stack alignItems="center">
                          <CircularProgress />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={productsCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              backIconButtonProps={
                loading
                  ? {
                      disabled: loading,
                    }
                  : undefined
              }
              nextIconButtonProps={
                loading
                  ? {
                      disabled: loading,
                    }
                  : undefined
              }
            />
          </Paper>
        ) : (
          !loading && (
            <Typography>{translation.products.noProductsAvailable}</Typography>
          )
        )}
      </Box>
    </>
  );
};

export default Products;
