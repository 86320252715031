import axios from "axios";
// axios instance for making requests
const axiosInstance = axios.create();

// request interceptor for adding token
axiosInstance.interceptors.request.use((config) => {
  // add token to request headers
  config.headers["X-DTW-ADMIN"] = "true";
  config.headers["Authorization"] = "Bearer " + localStorage.getItem("token");
  config.headers["Content-Type"] =
    config.method === "patch"
      ? "application/merge-patch+json"
      : "application/json";

  return config;
});

export default axiosInstance;
