import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import { Formik } from "formik";
import { updateSettings } from "services/tenants";

import PapperCollapse from "components/PaperCollapse";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { Box, MenuItem, Stack } from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import SwitchField from "components/Form/SwitchField/SwitchField";
import ArrayNumberPercentageField from "components/Form/ArrayNumberPercentageField";
import { LoadingButton } from "@mui/lab";
import { errorMessage } from "helpers";
import { useTenantContext } from "components/contexts/TenantContext";
import MarketingMenu from "components/Menu/MarketingMenu";
import SelectField from "components/Form/SelectField";

const NewsletterSettings = () => {
  const { selectedTenant, tenants, updateTrigger, setUpdateTrigger } =
    useUserContext();
  const { taxes, taxesLoading, setUpdateTaxesTrigger, updateTaxesTrigger } =
    useTenantContext();
  const { loading, setLoading } = useLoadingContext();
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const {
    settings,
    updateSettingsTrigger,
    setUpdateSettingsTrigger,
    settingsLoading,
    setSettingsLoading,
  } = useTenantContext();
  const [isEditingTax, setIsEditingTax] = useState(false);
  const translation = useTranslation();

  const submitForm = async (values: any, actions: any) => {
    try {
      await updateSettings({
        ...settings,
        id: settings.id,
        newsletterFormEnabled: values.newsletterFormEnabled,
        newletterFormType: values.newletterFormType,
      });
    } catch (e) {
      actions.setSubmitting(false);
      setErrorMessage(errorMessage(e));
      setUpdateTrigger(!updateTrigger);
      setUpdateSettingsTrigger(!updateSettingsTrigger);
      return;
    }
    actions.setSubmitting(false);
    setUpdateSettingsTrigger(!updateSettingsTrigger);
    setSuccessMessage(translation.savedMessage);
  };

  return (
    <>
      <PapperCollapse title={translation.newsletterSubscribersSettingsTitle}>
        <Formik
          initialValues={{
            newsletterFormEnabled: settings.newsletterFormEnabled
              ? settings.newsletterFormEnabled
              : false,
            newletterFormType: settings.newletterFormType
              ? settings.newletterFormType
              : "",
          }}
          enableReinitialize
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            submitForm(values, actions);
          }}
        >
          {({ isSubmitting, submitForm, values, resetForm }) => (
            <Box sx={{ height: "100%" }}>
              <Stack spacing={2} sx={{ width: 600 }}>
                <SwitchField
                  name="newsletterFormEnabled"
                  label={translation.newsletterSubscribers.enableNewsletterForm}
                ></SwitchField>

                <SelectField
                  name="newletterFormType"
                  label={translation.newsletterSubscribers.newsletterFormType}
                >
                  <MenuItem value={"simple"}>
                    {translation.newsletterSubscribers.simpleType}
                  </MenuItem>
                  <MenuItem value={"medium"}>
                    {translation.newsletterSubscribers.mediumType}
                  </MenuItem>
                  <MenuItem value={"full"}>
                    {translation.newsletterSubscribers.fullType}
                  </MenuItem>
                </SelectField>

                <Box display={"flex"} justifyContent="flex-start">
                  <LoadingButton
                    type="submit"
                    onClick={submitForm}
                    variant="contained"
                    loading={isSubmitting}
                    disabled={isEditingTax}
                  >
                    {translation.save}
                  </LoadingButton>
                </Box>
              </Stack>
            </Box>
          )}
        </Formik>
      </PapperCollapse>
    </>
  );
};

export default NewsletterSettings;
