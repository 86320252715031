import React, { useEffect, useState } from "react";
import useTranslation from "components/customHooks/translations";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Popover,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { IInvoice, IInvoiceTemplate } from "interfaces";
import { useUserContext } from "components/contexts/UserContext";
import { useLoadingContext } from "components/contexts/LoadingContext";
import {
  getInvoicesByTenant,
  getInvoiceTemplatesByTenant,
} from "services/tenants";
import {
  Add,
  Dangerous,
  EditOutlined,
  HiveOutlined,
  Warning,
  WarningAmber,
} from "@mui/icons-material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { errorMessage, formattedDate } from "helpers";
import { useNavigate } from "react-router-dom";
import { invoiceStatuses } from "helpers/invoiceStatuses";
import EnhancedTableHead, {
  HeadCell,
} from "components/Table/EnhancedTableHead";
import { getInvoiceDownload } from "services/orders";
import { routeNames } from "routes";

interface Data {
  id: number;
  customer: string;
  series: string;
  recurrence: string;
  total: string;
}

const headCells: HeadCell[] = [
  {
    id: "customer",
    numeric: false,
    disablePadding: false,
    label: "Customer",
    sorting: false,
  },
  {
    id: "series",
    numeric: true,
    disablePadding: false,
    label: "Series",
    sorting: false,
  },
  {
    id: "recurrence",
    numeric: true,
    disablePadding: false,
    label: "Recurrence",
    sorting: false,
  },
  {
    id: "total",
    numeric: true,
    disablePadding: false,
    label: "Total",
    sorting: false,
  },
];

const Recurrences = () => {
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const { selectedTenant, tenants } = useUserContext();
  const [invoices, setInvoices] = useState<IInvoiceTemplate[]>([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [pagesNumber, setPagesNumber] = useState(0);
  const [statusFilter, setStatusFilter] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [rows, setRows] = useState<Data[]>([]);
  const translation = useTranslation();
  const { loading, setLoading } = useLoadingContext();

  const navigate = useNavigate();

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState<string>("id");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (
    event: React.MouseEvent<unknown>,
    invoiceTemplateId: number
  ) => {
    navigate("/edit-recurrence/" + invoiceTemplateId);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeStatusFilter = (event: SelectChangeEvent) => {
    setStatusFilter(event.target.value);
    setPage(0);
  };

  const invoicesToData = (invoices: any) => {
    const data = invoices.map((invoice: IInvoiceTemplate) => {
      console.log(invoice);
      let customerName = "";
      if (invoice.customer?.name) {
        customerName = invoice.customer.name;
      }
      return {
        id: invoice.id,
        customer: customerName,
        series: invoice.series.series,
        recurrence: invoice.recurrence,
        total: invoice.total + " " + invoice.currency,
      };
    });

    return data;
  };
  useEffect(() => {
    setLoading(true);

    if (tenants !== null) {
      getInvoiceTemplatesByTenant(
        tenants[selectedTenant].id,
        page + 1,
        rowsPerPage,
        orderBy,
        order,
        true
      )
        .then((res) => {
          setInvoices(res.data["hydra:member"]);
          setRows(invoicesToData(res.data["hydra:member"]));
          setOrdersCount(res.data["hydra:totalItems"]);
          setPagesNumber(Math.ceil(res.data["hydra:totalItems"] / rowsPerPage));
          setLoading(false);
        })
        .catch((e) => setErrorMessage(errorMessage(e)));
    }
  }, [
    selectedTenant,
    tenants,
    page,
    rowsPerPage,
    orderBy,
    order,
    statusFilter,
  ]);

  return (
    <>
      <Stack sx={{ mb: 4 }}>
        <Stack direction="row" justifyContent={"flex-end"}>
          <Stack direction="row" spacing={2}>
            {/* <Box>
              <Button
                variant="outlined"
                startIcon={<HiveOutlined />}
                onClick={() => setShowFilters(!showFilters)}
              >
                {translation.filters}
              </Button>
            </Box> */}
            <Box>
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => {
                  navigate(routeNames.newRecurrence);
                }}
              >
                {translation.invoices.newRecurrence}
              </Button>
            </Box>
          </Stack>
        </Stack>
        {/* <Stack>
          <Collapse in={showFilters}>
            <Stack direction="row" sx={{ marginBottom: 4 }}>
              <FormControl variant="standard" sx={{ m: 1, minWidth: 120 }}>
                <InputLabel id="status-select-standard-label">
                  {translation.status}
                </InputLabel>
                <Select
                  labelId="status-select-standard-label"
                  id="status-select-standard"
                  value={statusFilter}
                  onChange={handleChangeStatusFilter}
                  label={translation.status}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {invoiceStatuses.map((invoiceStatus) => (
                    <MenuItem
                      key={invoiceStatus.slug}
                      value={invoiceStatus.slug}
                    >
                      {invoiceStatus.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
          </Collapse>
        </Stack> */}
      </Stack>
      {rows.length > 0 ? (
        <>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHead
                  headCells={headCells}
                  numSelected={selected.length}
                  order={order as any}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {!loading ? (
                    rows.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          sx={{ cursor: "pointer" }}
                          onClick={(event) => handleClick(event, row.id)}
                          tabIndex={-1}
                          key={row.id}
                          style={{
                            height: 100,
                          }}
                        >
                          <TableCell>{row.customer}</TableCell>
                          <TableCell>{row.series}</TableCell>
                          <TableCell>{row.recurrence}</TableCell>
                          <TableCell>{row.total}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow
                      style={{
                        height: 100 * rowsPerPage,
                      }}
                    >
                      <TableCell colSpan={6}>
                        <Stack alignItems="center">
                          <CircularProgress />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={ordersCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              backIconButtonProps={
                loading
                  ? {
                      disabled: loading,
                    }
                  : undefined
              }
              nextIconButtonProps={
                loading
                  ? {
                      disabled: loading,
                    }
                  : undefined
              }
            />
          </Paper>
        </>
      ) : (
        !loading && <Typography>{translation.invoices.noInvoices}</Typography>
      )}
    </>
  );
};

export default Recurrences;
