import { useState, useEffect } from "react";
import { useUserContext } from "components/contexts/UserContext";
import { ITax } from "interfaces";
import { getInvoiceSeriesByTenant } from "services/tenants";

export default function useInvoiceSeries() {
  const [invoiceSeries, setInvoiceSeries] = useState<ITax[]>([]);
  const [updateInvoiceSeriesTrigger, setUpdateInvoiceSeriesTrigger] =
    useState(false);

  const [invoiceSeriesLoading, setInvoiceSeriesLoading] = useState(true);

  const { selectedTenant, tenants } = useUserContext();

  useEffect(() => {
    setInvoiceSeriesLoading(true);
    if (tenants !== null) {
      if (tenants[selectedTenant]) {
        getInvoiceSeriesByTenant(tenants[selectedTenant].id)
          .then((res) => {
            if (res.data["hydra:member"].length > 0) {
              setInvoiceSeries(res.data["hydra:member"]);
              setInvoiceSeriesLoading(false);
            }
            setInvoiceSeriesLoading(false);
          })
          .catch((e) => {
            setInvoiceSeriesLoading(false);
          });
      }
    }
  }, [selectedTenant, tenants, updateInvoiceSeriesTrigger]);

  return {
    invoiceSeries,
    updateInvoiceSeriesTrigger,
    setUpdateInvoiceSeriesTrigger,
    invoiceSeriesLoading,
    setInvoiceSeriesLoading,
  };
}
