import { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import PapperCollapse from "components/PaperCollapse";
import PageHeader from "components/PageHeader";
import DnsZone from "components/DnsZone";
import { getDnsZonesByTenant, newDnsZone } from "services/dns";
import { useLoadingContext } from "components/contexts/LoadingContext";
import {
  Box,
  Button,
  Container,
  Drawer,
  Grid,
  IconButton,
  Link,
  Paper,
  Popover,
  Stack,
  Typography,
} from "@mui/material";
import { Add, ContentCopyOutlined } from "@mui/icons-material";
import * as Yup from "yup";
import { formDrawerWidth } from "components/Form/constants";
import { Formik } from "formik";
import { IDnsZone } from "interfaces";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import FormTextField from "components/Form/FormTextField/FormTextField";
import SwitchField from "components/Form/SwitchField/SwitchField";
import { LoadingButton } from "@mui/lab";

const Infrastructure = () => {
  const { selectedTenant, tenants } = useUserContext();
  const { setLoading } = useLoadingContext();
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [openForm, setOpenForm] = useState(false);
  const [dnsZones, setDnsZones] = useState<any>([]);
  const translation = useTranslation();
  const domainSchema = Yup.object().shape({
    domainName: Yup.string()
      .min(2, translation.signUpShortError)
      .max(50, translation.signUpLongError)
      .required(translation.required)
      .matches(
        /(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]/,
        translation.dnsZone.invalidDomainNameError
      ),
    isManaged: Yup.boolean().required(translation.required),
  });

  const setUpDomain = (values: any, setSubmitting: any) => {
    const dnsZone: IDnsZone = {
      domainName: values.domainName,
      tenant: tenants[selectedTenant]["@id"],
      type: values.isManaged ? "managed" : "customer",
    };
    newDnsZone(dnsZone)
      .then((response) => {
        setSuccessMessage(translation.dnsZone.newDnsZoneSuccess);
        setSubmitting(false);
        setOpenForm(false);
        setUpdateTrigger(!updateTrigger);
      })
      .catch((e: any) => {
        setSubmitting(false);
        setOpenForm(false);
        setErrorMessage(e.response.data.message);
      });
  };

  useEffect(() => {
    setLoading(true);

    if (tenants) {
      getDnsZonesByTenant(tenants[selectedTenant].id)
        .then((res) => {
          setDnsZones(res.data["hydra:member"]);
          setLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }, [selectedTenant, tenants, updateTrigger]);

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);

  const openPopOver = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Stack direction={"row"} justifyContent={"flex-end"}>
        <Box sx={{ mb: 4 }}>
          <Button
            variant={"contained"}
            startIcon={<Add />}
            onClick={() => setOpenForm(true)}
          >
            {translation.dnsZone.newDnsZone}
          </Button>
        </Box>
      </Stack>
      <Container>
        <Grid container spacing={2}>
          {dnsZones.map((dnsZone: IDnsZone, index: number) => {
            return (
              <Grid item key={"dns-zone-" + index} md={6}>
                <Paper sx={{ p: 2 }}>
                  <Stack spacing={2}>
                    <Stack direction="row" justifyContent={"space-between"}>
                      <Typography variant="h5">{dnsZone.domainName}</Typography>
                      {dnsZone.type == "managed" ? (
                        <Typography>
                          {translation.dnsZone.isManagedLabel}
                        </Typography>
                      ) : (
                        <Typography>
                          {translation.dnsZone.isNotManagedLabel}
                        </Typography>
                      )}
                    </Stack>

                    <Typography>
                      {dnsZone.type == "managed"
                        ? translation.dnsZone.status + ": " + dnsZone.status
                        : ""}
                    </Typography>
                    {dnsZone.type == "managed" ? (
                      <Stack spacing={1}>
                        <Typography variant="h6">
                          {translation.dnsZone.nameServers}
                        </Typography>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                          alignItems={"flex-end"}
                        >
                          <Stack>
                            {dnsZone.nameServers &&
                              dnsZone.nameServers.map((nameServer, index) => (
                                <Stack
                                  direction={"row"}
                                  key={"nameserver-" + index}
                                  alignItems={"center"}
                                  spacing={1}
                                >
                                  <Typography variant="caption">
                                    {nameServer}
                                  </Typography>
                                  <IconButton
                                    size="small"
                                    color="primary"
                                    onClick={(
                                      event: React.MouseEvent<HTMLButtonElement>
                                    ) => {
                                      const url = nameServer;
                                      navigator.clipboard.writeText(url);
                                      setAnchorEl(event.currentTarget);
                                      setTimeout(() => {
                                        setAnchorEl(null);
                                      }, 2000);
                                    }}
                                  >
                                    <ContentCopyOutlined />
                                  </IconButton>
                                </Stack>
                              ))}
                          </Stack>
                          <Box>
                            <Link
                              href={"/infrastructure/dnsZone/" + dnsZone.id}
                              underline="none"
                              color={"text.primary"}
                            >
                              <Button variant="contained">
                                {translation.dnsZone.seeDetailsButton}
                              </Button>
                            </Link>
                          </Box>
                        </Stack>
                      </Stack>
                    ) : (
                      <Stack alignItems={"flex-end"}>
                        <Box>
                          <Link
                            href={"/infrastructure/dnsZone/" + dnsZone.id}
                            underline="none"
                            color={"text.primary"}
                          >
                            <Button variant="contained">
                              {translation.dnsZone.seeDetailsButton}
                            </Button>
                          </Link>
                        </Box>
                      </Stack>
                    )}
                  </Stack>
                </Paper>
                <Popover
                  id="simple-popover"
                  sx={{
                    pointerEvents: "none",
                  }}
                  open={openPopOver}
                  anchorEl={anchorEl}
                  onClose={handleClose}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                >
                  <Typography sx={{ p: 1, color: "text.secondary" }}>
                    {translation.policies.copiedUrl}
                  </Typography>
                </Popover>
              </Grid>
            );
          })}
          {dnsZones.length === 0 && (
            <Typography>{translation.dnsZone.noDnsZone}</Typography>
          )}
        </Grid>
      </Container>
      <Drawer
        anchor="right"
        open={openForm}
        onClose={() => {
          setOpenForm(false);
        }}
        sx={{
          "& .MuiDrawer-paper": { width: formDrawerWidth },
        }}
      >
        <Formik
          initialValues={{
            domainName: "",
            isManaged: true,
          }}
          validationSchema={domainSchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            setUpDomain(values, setSubmitting);
          }}
        >
          {({
            handleSubmit,
            errors,
            touched,
            isValid,
            isSubmitting,
            values,
          }) => (
            <form noValidate className="" onSubmit={handleSubmit}>
              <Box sx={{ p: 4 }}>
                <Typography variant="h5">
                  {translation.dnsZone.newDnsZone}
                </Typography>
                <Stack spacing={2}>
                  <FormTextField
                    label={translation.dnsZone.domainName}
                    name="domainName"
                  />

                  <SwitchField
                    name="isManaged"
                    label={translation.dnsZone.isManagedLabel}
                  ></SwitchField>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"space-between"}
                  >
                    <Button
                      variant={"outlined"}
                      onClick={() => {
                        setOpenForm(false);
                      }}
                    >
                      {translation.closeButton}
                    </Button>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={isSubmitting}
                    >
                      {translation.dnsZone.newDnsZone}
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Box>
            </form>
          )}
        </Formik>
      </Drawer>
    </>
  );
};

export default Infrastructure;
