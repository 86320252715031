export const subscribersCSV = [
  [
    "email",
    "firstName",
    "lastName",
    "birthday",
    "phoneNumber",
    "consent",
    "createdAt",
    "ip",
    "source",
  ],
];
