import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { translatables } from "helpers/translatables";
import LocalizationForm from "./LocalizationForm";
import { searchProductsNames } from "services/products";
import { useUserContext } from "components/contexts/UserContext";
import { ITranslatable } from "interfaces";

export default function Localization() {
  const { tenants, selectedTenant } = useUserContext();
  const [expanded, setExpanded] = useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div>
      {Object.entries(translatables).map(([index, translatable]) => {
        return (
          <LocalizationForm
            key={"translatable-" + index}
            expanded={expanded == translatable.id}
            handleChange={() => handleChange(translatable.id)}
            translatable={translatable}
          ></LocalizationForm>
        );
      })}
    </div>
  );
}
