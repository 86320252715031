import useTranslation from "components/customHooks/translations";
import * as Yup from "yup";
import { Formik } from "formik";
import FormTextField from "components/Form/FormTextField/FormTextField";
import SelectField from "components/Form/SelectField";
import { useUserContext } from "components/contexts/UserContext";
import LoadingButton from "@mui/lab/LoadingButton";
import { Box, MenuItem, Stack, Paper, Button } from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { errorMessage, generateRandomString } from "helpers";
import SwitchField from "components/Form/SwitchField/SwitchField";
import FormNumberField from "components/Form/FormNumberField";
import { createCoupon, updateCoupon } from "services/tenants";
import NumberPercentageField from "components/Form/NumberPercentageField";
import DateTimePickerField from "components/Form/DateTimePickerField";
import InfoPopover from "components/InfoPopover";
import { ICoupon } from "interfaces";
import { useNavigate } from "react-router-dom";
import { routeNames } from "routes";

const CouponsForm = ({ couponToEdit }: { couponToEdit?: ICoupon }) => {
  const { selectedTenant, tenants } = useUserContext();
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();
  const navigate = useNavigate();

  const translation = useTranslation();

  const couponSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, translation.signUpShortError)
      .max(50, translation.signUpLongError)
      .required(translation.required),
    isPercentage: Yup.bool().required(translation.required),
    amount: Yup.string().required(translation.required),
  });

  async function submitForm(
    values: ICoupon,
    setSubmitting: (isSubmitting: boolean) => void,
    goBack = false
  ) {
    setSubmitting(true);
    if (!couponToEdit) {
      try {
        await createCoupon({
          name: values.name,
          isPercentage: values.isPercentage,
          worksWithDiscount: values.worksWithDiscount,
          amount: values.amount,
          isActive: values.isActive,
          description: values.description,
          endDate: values.endDate ? values.endDate : null,
          userUsesLimit: values.userUsesLimit,
          usesLimit: values.usesLimit,
          tenant: tenants[selectedTenant]["@id"],
        });
        setSuccessMessage(translation.savedMessage);

        setSubmitting(false);
        if (goBack) {
          navigate(routeNames.coupons);
        }
      } catch (error) {
        setErrorMessage(errorMessage(error));
        setSubmitting(false);
      }
    } else {
      try {
        await updateCoupon({
          ...couponToEdit,
          name: values.name,
          isPercentage: values.isPercentage,
          worksWithDiscount: values.worksWithDiscount,
          amount: values.amount,
          isActive: values.isActive,
          description: values.description,
          endDate: values.endDate ? values.endDate : null,
          userUsesLimit: values.userUsesLimit,
          usesLimit: values.usesLimit,
        });
        setSuccessMessage(translation.savedMessage);

        setSubmitting(false);
        if (goBack) {
          navigate(routeNames.coupons);
        }
      } catch (error) {
        setErrorMessage(errorMessage(error));
        setSubmitting(false);
      }
    }
  }

  const initialValues: ICoupon = {
    name: couponToEdit ? couponToEdit.name : "",
    description: couponToEdit ? couponToEdit.description : "",
    isPercentage: couponToEdit ? couponToEdit.isPercentage : false,
    amount: couponToEdit ? couponToEdit.amount : 0,
    endDate: couponToEdit?.endDate ? couponToEdit.endDate : "",
    worksWithDiscount: couponToEdit ? couponToEdit.worksWithDiscount : true,
    userUsesLimit: couponToEdit ? couponToEdit.userUsesLimit : 0,
    usesLimit: couponToEdit ? couponToEdit.usesLimit : 0,
    isActive: couponToEdit ? couponToEdit.isActive : true,
  };

  return (
    <>
      <Formik
        initialValues={initialValues}
        validationSchema={couponSchema}
        enableReinitialize
        onSubmit={() => {
          console.log("submited");
        }}
      >
        {({
          values,
          handleSubmit,
          isSubmitting,
          setFieldValue,
          setSubmitting,
          resetForm,
        }) => {
          return (
            <>
              <form noValidate className="" onSubmit={handleSubmit}>
                <Paper sx={{ p: 2 }}>
                  <Stack spacing={2}>
                    <Stack direction={"row"} spacing={2} alignItems="flex-end">
                      <Box>
                        <FormTextField
                          label={translation.coupons.nameLabel}
                          name="name"
                        />
                      </Box>
                      <Box>
                        <Button
                          variant="outlined"
                          onClick={() => {
                            setFieldValue("name", generateRandomString(8));
                          }}
                        >
                          {translation.coupons.generateName}
                        </Button>
                      </Box>
                    </Stack>
                    <Box>
                      <FormTextField
                        label={translation.coupons.descriptionLabel}
                        multiline
                        name="description"
                      />
                    </Box>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      flexWrap={"wrap"}
                      alignItems={"flex-end"}
                    >
                      <SelectField
                        name="isPercentage"
                        label={translation.coupons.isPercentageLabel}
                      >
                        <MenuItem
                          value={
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            false as any
                          }
                        >
                          {translation.coupons.fixedOption}
                        </MenuItem>
                        <MenuItem
                          value={
                            // eslint-disable-next-line @typescript-eslint/no-explicit-any
                            true as any
                          }
                        >
                          {translation.coupons.percentageOption}
                        </MenuItem>
                      </SelectField>
                      {values.isPercentage ? (
                        <NumberPercentageField name="amount"></NumberPercentageField>
                      ) : (
                        <FormNumberField
                          type="float"
                          name="amount"
                          label={translation.coupons.amountLabel}
                        ></FormNumberField>
                      )}
                    </Stack>
                    <Box>
                      <Box sx={{ mt: 2 }}>
                        <DateTimePickerField
                          name="endDate"
                          label={translation.coupons.endDateLabel}
                        ></DateTimePickerField>
                      </Box>
                    </Box>
                    <Box>
                      <SwitchField
                        name="worksWithDiscount"
                        label={translation.coupons.worksWithDiscountLabel}
                      ></SwitchField>
                    </Box>
                    <Stack direction={"row"} spacing={2}>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignItems="flex-end"
                      >
                        <FormNumberField
                          type="int"
                          name="usesLimit"
                          label={translation.coupons.usesLimitLabel}
                        ></FormNumberField>
                        <InfoPopover
                          infoContent={translation.coupons.usesLimitInfo}
                        ></InfoPopover>
                      </Stack>
                      <Stack
                        direction={"row"}
                        spacing={1}
                        alignItems="flex-end"
                      >
                        <FormNumberField
                          type="int"
                          name="userUsesLimit"
                          label={translation.coupons.userUsesLimitLabel}
                        ></FormNumberField>
                        <InfoPopover
                          infoContent={translation.coupons.userUsesLimitInfo}
                        ></InfoPopover>
                      </Stack>
                    </Stack>
                    <Stack direction={"row"} spacing={2}>
                      <LoadingButton
                        variant="contained"
                        loading={isSubmitting}
                        onClick={() => {
                          submitForm(values, setSubmitting);
                          if (!couponToEdit) {
                            resetForm();
                            setFieldValue("images", []);
                          }
                        }}
                      >
                        {translation.saveButton}
                      </LoadingButton>
                      <LoadingButton
                        variant="outlined"
                        type="submit"
                        loading={isSubmitting}
                        onClick={() => {
                          submitForm(values, setSubmitting, true);
                        }}
                      >
                        {translation.saveAndBackButton}
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Paper>
              </form>
            </>
          );
        }}
      </Formik>{" "}
    </>
  );
};

export default CouponsForm;
