export const invoiceStatuses = [
  {
    slug: "unpaid",
    label: "Unpaid",
  },
  {
    slug: "overdue",
    label: "Overdue",
  },
  {
    slug: "paid",
    label: "paid",
  },
];
