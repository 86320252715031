import React from "react";
import { useField } from "formik";
import TextField from "@mui/material/TextField";
import { Box, Stack, Typography } from "@mui/material";

import useTranslation from "components/customHooks/translations";
import FormNumberField from "../FormNumberField";
import InfoPopover from "components/InfoPopover";
import { useUserContext } from "components/contexts/UserContext";
import FormTextField from "../FormTextField/FormTextField";

const ArraySpecificationsField = ({
  label,
  name,
  disabled = false,
}: {
  label?: string;
  name: string;
  disabled?: boolean;
}) => {
  const translation = useTranslation();
  const { tenantsIsLoading, selectedTenant, tenants } = useUserContext();
  const [field, meta, helper] = useField(name);

  return (
    <>
      <Stack spacing={2}>
        <Typography>{label}</Typography>
        {field.value.map((value: any, index: number) => {
          return (
            <Stack
              direction={"row"}
              key={"variation-" + index}
              alignItems={"flex-end"}
              spacing={2}
              flexWrap={"wrap"}
            >
              <TextField
                variant="standard"
                label={translation.products.specificationName}
                value={value.name}
                disabled={disabled}
                onChange={(e) => {
                  const oldValues = field.value.map((v: any) => {
                    return { ...v };
                  });
                  oldValues[index].name = e.target.value;
                  if (index === oldValues.length - 1) {
                    oldValues.push({
                      name: "",
                      content: "",
                    });
                  }
                  if (e.target.value === "") {
                    oldValues.splice(index, 1);
                  }
                  helper.setValue([...oldValues]);
                }}
              />

              <FormTextField
                label={translation.products.specificationContent}
                multiline
                disabled={disabled}
                lineRows={false}
                name={"specifications[" + index + "].content"}
              />
            </Stack>
          );
        })}
      </Stack>
    </>
  );
};

export default ArraySpecificationsField;
