import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import { Formik } from "formik";
import {
  createCro,
  createSettings,
  createTax,
  getCroByTenant,
  getSettingsByTenant,
  getTaxesByTenant,
  updateCro,
  updateSettings,
  updateTenant,
} from "services/tenants";

import PapperCollapse from "components/PaperCollapse";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { Box, Stack } from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { ICro, ISettings, ITax } from "interfaces";
import SwitchField from "components/Form/SwitchField/SwitchField";
import ArrayNumberPercentageField from "components/Form/ArrayNumberPercentageField";
import { LoadingButton } from "@mui/lab";
import { errorMessage } from "helpers";
import { useTenantContext } from "components/contexts/TenantContext";
import MarketingMenu from "components/Menu/MarketingMenu";
import FormTextField from "components/Form/FormTextField/FormTextField";

const Cro = () => {
  const { selectedTenant, tenants, updateTrigger, setUpdateTrigger } =
    useUserContext();
  const { taxes, taxesLoading, setUpdateTaxesTrigger, updateTaxesTrigger } =
    useTenantContext();
  const { loading, setLoading } = useLoadingContext();
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const [cro, setCro] = useState<ICro | null>(null);
  const {
    settings,
    updateSettingsTrigger,
    setUpdateSettingsTrigger,
    settingsLoading,
    setSettingsLoading,
  } = useTenantContext();
  const [isEditingTax, setIsEditingTax] = useState(false);
  const translation = useTranslation();

  useEffect(() => {
    setLoading(true);

    if (tenants !== null) {
      getCroByTenant(tenants[selectedTenant].id)
        .then((res) => {
          if (res.data["hydra:member"].length > 0) {
            console.log(res.data["hydra:member"][0]);
            setCro(res.data["hydra:member"][0]);
          } else {
            setCro(null);
          }
          setLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }, [selectedTenant, tenants, updateTrigger]);

  const submitForm = async (values: any, actions: any) => {
    try {
      await updateSettings({
        ...settings,
        id: settings.id,
        freeShippingMessageEnabled: values.freeShippingMessageEnabled,
      });

      if (!cro) {
        createCro({
          freeShippingMessage: values.freeShippingMessage,
          latestProducts: values.latestProducts,
          featuredProducts: values.featuredProducts,
          tenant: "/tenants/" + tenants[selectedTenant].id,
        })
          .then(() => {
            setUpdateTrigger(!updateTrigger);
            updateTenant(
              {
                ...tenants[selectedTenant].id,
              },
              tenants[selectedTenant].id
            );
            setSuccessMessage(translation.savedMessage);
            actions.setSubmitting(false);
          })
          .catch((e) => {
            setErrorMessage(errorMessage(e));
            actions.setSubmitting(false);
          });
      } else {
        updateCro({
          id: cro.id,
          freeShippingMessage: values.freeShippingMessage,
          latestProducts: values.latestProducts,
          featuredProducts: values.featuredProducts,
        })
          .then(() => {
            setUpdateTrigger(!updateTrigger);
            updateTenant(
              {
                ...tenants[selectedTenant].id,
              },
              tenants[selectedTenant].id
            );
            setSuccessMessage(translation.savedMessage);
            actions.setSubmitting(false);
          })
          .catch((e) => {
            setErrorMessage(errorMessage(e));
            actions.setSubmitting(false);
          });
      }
    } catch (e) {
      actions.setSubmitting(false);
      setErrorMessage(errorMessage(e));
      setUpdateTrigger(!updateTrigger);
      setUpdateSettingsTrigger(!updateSettingsTrigger);
      return;
    }
    actions.setSubmitting(false);
    setUpdateSettingsTrigger(!updateSettingsTrigger);
    setSuccessMessage(translation.savedMessage);
  };

  return (
    <>
      <MarketingMenu />
      <PapperCollapse title={translation.croTitle}>
        <Formik
          initialValues={{
            freeShippingMessageEnabled: settings.freeShippingMessageEnabled
              ? settings.freeShippingMessageEnabled
              : false,
            freeShippingMessage: cro
              ? cro.freeShippingMessage
                ? cro.freeShippingMessage
                : ""
              : "",
            latestProducts: cro ? cro.latestProducts : "",
            featuredProducts: cro ? cro.featuredProducts : "",
          }}
          enableReinitialize
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            submitForm(values, actions);
          }}
        >
          {({ isSubmitting, submitForm, values, resetForm }) => (
            <Box sx={{ height: "100%" }}>
              <Stack spacing={2} sx={{ width: 600 }}>
                <SwitchField
                  name="freeShippingMessageEnabled"
                  label={translation.cro.freeShippingMessageEnabledLabel}
                ></SwitchField>

                <FormTextField
                  multiline
                  name="freeShippingMessage"
                  label={translation.cro.freeShippingMessageLabel}
                />

                <FormTextField
                  name="latestProducts"
                  label={translation.cro.latestProductsLabel}
                />

                <FormTextField
                  name="featuredProducts"
                  label={translation.cro.featuredProductsLabel}
                />

                <Box display={"flex"} justifyContent="flex-start">
                  <LoadingButton
                    type="submit"
                    onClick={submitForm}
                    variant="contained"
                    loading={isSubmitting}
                    disabled={isEditingTax}
                  >
                    {translation.save}
                  </LoadingButton>
                </Box>
              </Stack>
            </Box>
          )}
        </Formik>
      </PapperCollapse>
    </>
  );
};

export default Cro;
