import { Quill } from "react-quill-new";

const InlineBlot = Quill.import("blots/block");

class LoadingImage extends InlineBlot {
  static create(src) {
    const node = super.create(src);
    if (src === true) return node;

    const image = document.createElement("img");
    image.setAttribute("src", src);
    node.appendChild(image);

    return node;
  }
}

LoadingImage.blotName = "imageBlot";
LoadingImage.className = "image-uploading";
LoadingImage.tagName = "span";
// Quill.register({ "formats/imageBlot": LoadingImage });

export default LoadingImage;
