import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import * as Yup from "yup";
import useTranslation from "components/customHooks/translations";
import FormTextField from "components/Form/FormTextField/FormTextField";
import SelectField from "components/Form/SelectField";
import { Formik } from "formik";
import {
  getPaymentOptionsByTenant,
  createPayment,
  deletePayment,
  updatePayment,
  postLinkAccount,
  getAccountOnboardingStatus,
  updateInvoiceSeries,
  createInvoiceSeries,
  deleteInvoiceSeries,
  getInvoiceSeriesByTenant,
} from "services/tenants";

import IconButton from "@mui/material/IconButton";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  MenuItem,
  Stack,
  Switch,
  TextField,
  Typography,
} from "@mui/material";
import PapperCollapse from "components/PaperCollapse";
import { formDrawerWidth } from "components/Form/constants";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import { IInvoiceSeries, ISettings } from "interfaces";
import FormNumberField from "components/Form/FormNumberField";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { LoadingButton } from "@mui/lab";
import { errorMessage } from "helpers";
import SwitchField from "components/Form/SwitchField/SwitchField";
import useInvoiceSeries from "components/customHooks/useInvoice";
import { useTenantContext } from "components/contexts/TenantContext";

const InvoiceSeries = ({ settings }: { settings: ISettings }) => {
  const { selectedTenant, tenants, updateTrigger, setUpdateTrigger } =
    useUserContext();
  const {
    invoiceSeries,
    updateInvoiceSeriesTrigger,
    setUpdateInvoiceSeriesTrigger,
    invoiceSeriesLoading,
    setInvoiceSeriesLoading,
  } = useTenantContext();
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();

  const [seriesForAction, setSeriesForAction] = useState<IInvoiceSeries | null>(
    null
  );
  const [action, setAction] = useState<null | "delete" | "edit" | "create">(
    null
  );

  const translation = useTranslation();

  const invoiceSingleSeriesSchema = Yup.object().shape({
    series: Yup.string()
      .min(1, translation.signUpShortError)
      .max(15, translation.signUpLongError)
      .required(translation.required),
    description: Yup.string()
      .min(1, translation.signUpShortError)
      .max(255, translation.signUpLongError),
    info: Yup.string()
      .min(2, translation.signUpShortError)
      .max(255, translation.signUpLongError),
    firstNumber: Yup.number().required(translation.required),
  });

  const handleEdit = (invoiceSeries: IInvoiceSeries) => {
    setSeriesForAction(invoiceSeries);
    setAction("edit");
  };

  const resetAction = () => {
    setSeriesForAction(null);
    setAction(null);
    setUpdateInvoiceSeriesTrigger(!updateInvoiceSeriesTrigger);
  };

  const handleDelete = (invoiceSeries: IInvoiceSeries) => {
    setSeriesForAction(invoiceSeries);
    setAction("delete");
  };

  const handleClose = () => {
    setAction(null);
    setSeriesForAction(null);
  };

  return (
    <>
      {selectedTenant != null && (
        <PapperCollapse
          title={translation.invoiceSeries.title}
          createFunction={() => setAction("create")}
        >
          <Stack spacing={2} sx={{ pt: 4 }} divider={<Divider />}>
            {invoiceSeries && invoiceSeries.length > 0 ? (
              invoiceSeries.map((invoiceSingleSeries: IInvoiceSeries) => {
                return (
                  <Stack spacing={1} key={invoiceSingleSeries["@id"]}>
                    <Stack
                      direction="row"
                      spacing={2}
                      alignItems={"center"}
                      justifyContent={"space-between"}
                    >
                      <Stack direction="row" spacing={1} alignItems={"center"}>
                        <Typography color={"primary.main"}>
                          {invoiceSingleSeries.series}
                        </Typography>
                        <Divider orientation="vertical" flexItem={true} />
                        <Stack>
                          {settings.defaultInvoiceSeries ===
                            invoiceSingleSeries["@id"] && (
                            <Typography color={"primary.main"} variant="body2">
                              {translation.invoiceSeries.defaultInvoiceSeries}
                            </Typography>
                          )}
                          <Typography variant="caption">
                            {invoiceSingleSeries.description}
                          </Typography>
                          <Typography variant="caption">
                            {translation.invoiceSeries.currentNumberLabel}:{" "}
                            {invoiceSingleSeries.currentNumber}
                          </Typography>
                          <Typography variant="caption">
                            {translation.invoiceSeries.firstNumberLabel}:{" "}
                            {invoiceSingleSeries.firstNumber}
                          </Typography>
                        </Stack>
                      </Stack>
                      <Box>
                        <IconButton
                          aria-label="edit"
                          onClick={() => {
                            handleEdit(invoiceSingleSeries);
                          }}
                        >
                          <EditOutlined color="primary"></EditOutlined>
                        </IconButton>
                        <IconButton
                          aria-label="delete"
                          onClick={() => {
                            handleDelete(invoiceSingleSeries);
                          }}
                        >
                          <DeleteOutlined color="warning"></DeleteOutlined>
                        </IconButton>
                      </Box>
                    </Stack>
                  </Stack>
                );
              })
            ) : (
              <>
                {!invoiceSeriesLoading && (
                  <p>{translation.invoiceSeries.noInvoiceSeries}</p>
                )}
              </>
            )}
          </Stack>
        </PapperCollapse>
      )}
      <Drawer
        anchor="right"
        open={action !== null && action !== "delete"}
        onClose={() => {
          handleClose();
        }}
        sx={{
          "& .MuiDrawer-paper": { width: formDrawerWidth },
        }}
      >
        <Formik
          initialValues={{
            series:
              action === "edit"
                ? seriesForAction
                  ? seriesForAction.series
                  : ""
                : "",
            description:
              action === "edit"
                ? seriesForAction
                  ? seriesForAction.description
                  : ""
                : "",
            firstNumber:
              action === "edit"
                ? seriesForAction
                  ? seriesForAction.firstNumber
                  : 1
                : 1,
            setDefault:
              invoiceSeries.length == 0 ||
              (seriesForAction !== null
                ? settings.defaultInvoiceSeries === seriesForAction["@id"]
                : false),
          }}
          validationSchema={invoiceSingleSeriesSchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            if (action === "edit") {
              if (seriesForAction) {
                updateInvoiceSeries({
                  ...seriesForAction,
                  series: values.series,
                  description: values.description,
                  firstNumber: Number(values.firstNumber),
                  setDefault: values.setDefault,
                })
                  .then((res) => {
                    setSuccessMessage(translation.savedMessage);
                    setSubmitting(false);
                    handleClose();
                    setUpdateTrigger(!updateTrigger);
                    setUpdateInvoiceSeriesTrigger(!updateInvoiceSeriesTrigger);
                  })
                  .catch((e) => {
                    setSubmitting(false);
                    setErrorMessage(errorMessage(e));
                  });
              }
            } else {
              createInvoiceSeries({
                series: values.series,
                description: values.description,
                currentNumber: Number(values.firstNumber),
                firstNumber: Number(values.firstNumber),
                tenant: "/tenants/" + tenants[selectedTenant].id,
                setDefault: values.setDefault,
              })
                .then((res) => {
                  const invoiceSingleSeries = res.data;

                  setSuccessMessage(translation.savedMessage);
                  setSubmitting(false);
                  handleClose();
                  setUpdateTrigger(!updateTrigger);
                  setUpdateInvoiceSeriesTrigger(!updateInvoiceSeriesTrigger);
                })
                .catch((e) => {
                  setSubmitting(false);
                  setErrorMessage(errorMessage(e));
                });
            }
          }}
        >
          {({ handleSubmit, isSubmitting, values }) => (
            <form noValidate className="" onSubmit={handleSubmit}>
              <Box sx={{ p: 4 }}>
                <Typography variant="h5">
                  {action === "edit"
                    ? translation.invoiceSeries.editInvoiceSeries
                    : translation.invoiceSeries.newInvoiceSeries}
                </Typography>

                <Stack spacing={2}>
                  <FormTextField
                    label={translation.invoiceSeries.seriesLabel}
                    name="series"
                    disabled={action === "edit"}
                  />
                  <FormTextField
                    label={translation.invoiceSeries.descriptionLabel}
                    name="description"
                  />

                  <FormNumberField
                    label={translation.invoiceSeries.firstNumberLabel}
                    type={"int"}
                    name="firstNumber"
                  />
                  <SwitchField
                    label={translation.invoiceSeries.setDefaultLabel}
                    name="setDefault"
                    disabled={
                      invoiceSeries.length == 0 ||
                      (seriesForAction !== null
                        ? settings.defaultInvoiceSeries ===
                          seriesForAction["@id"]
                        : false)
                    }
                  />
                  <Stack justifyContent={"space-between"}>
                    <Stack
                      direction={"row"}
                      spacing={2}
                      justifyContent={"space-between"}
                    >
                      <Button
                        variant={"outlined"}
                        onClick={() => {
                          handleClose();
                        }}
                      >
                        {translation.closeButton}
                      </Button>
                      <LoadingButton
                        variant="contained"
                        type="submit"
                        loading={isSubmitting}
                      >
                        {translation.saveButton}
                      </LoadingButton>
                    </Stack>
                  </Stack>
                </Stack>
              </Box>
            </form>
          )}
        </Formik>
      </Drawer>
      <Dialog
        open={action === "delete"}
        onClose={() => handleClose()}
        aria-labelledby="alert-dialog-title-confirm-delete"
        aria-describedby="alert-dialog-confirm-delete"
      >
        <DialogTitle id="alert-dialog-title-confirm-delete">
          {translation.invoiceSeries.deleteInvoiceSeries}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-confirm-delete">
            <Typography>
              {translation.invoiceSeries.deleteInvoiceSeriesMessage}
            </Typography>
            <Typography fontWeight={700}>
              {seriesForAction ? seriesForAction.series : ""}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"} onClick={() => handleClose()}>
            {translation.closeButton}
          </Button>
          <LoadingButton
            variant={"contained"}
            onClick={() => {
              if (seriesForAction) {
                setInvoiceSeriesLoading(true);
                deleteInvoiceSeries(seriesForAction)
                  .then(() => {
                    setInvoiceSeriesLoading(false);
                    setSuccessMessage(translation.deletedMessage);
                    resetAction();
                  })
                  .catch((e) => {
                    setInvoiceSeriesLoading(false);
                    setErrorMessage(errorMessage(e));
                    handleClose();
                  });
              }
            }}
            loading={invoiceSeriesLoading}
          >
            {translation.confirmDelete}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default InvoiceSeries;
