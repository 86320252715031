import React, { useState, useContext } from "react";
import useCategories from "components/customHooks/useCategories";
import useTaxes from "components/customHooks/useTaxes";
import useInvoiceSeries from "components/customHooks/useInvoice";
import useSettings from "components/customHooks/useSettings";

const TenantContext = React.createContext();
export const useTenantContext = () => useContext(TenantContext);

export default function TenantContextProvider({ children }) {
  const {
    categories,
    categoriesList,
    parentCategories,
    updateCategoriesTrigger,
    setUpdateCategoriesTrigger,
    categoriesLoading,
    setCategoriesLoading,
  } = useCategories();

  const {
    taxes,
    updateTaxesTrigger,
    setUpdateTaxesTrigger,
    taxesLoading,
    setTaxesLoading,
  } = useTaxes();

  const {
    invoiceSeries,
    updateInvoiceSeriesTrigger,
    setUpdateInvoiceSeriesTrigger,
    invoiceSeriesLoading,
    setInvoiceSeriesLoading,
  } = useInvoiceSeries();

  const {
    settings,
    updateSettingsTrigger,
    setUpdateSettingsTrigger,
    settingsLoading,
    setSettingsLoading,
  } = useSettings();

  return (
    <TenantContext.Provider
      value={{
        categories,
        categoriesList,
        parentCategories,
        updateCategoriesTrigger,
        setUpdateCategoriesTrigger,
        categoriesLoading,
        setCategoriesLoading,
        taxes,
        updateTaxesTrigger,
        setUpdateTaxesTrigger,
        taxesLoading,
        setTaxesLoading,
        invoiceSeries,
        updateInvoiceSeriesTrigger,
        setUpdateInvoiceSeriesTrigger,
        invoiceSeriesLoading,
        setInvoiceSeriesLoading,
        settings,
        updateSettingsTrigger,
        setUpdateSettingsTrigger,
        settingsLoading,
        setSettingsLoading,
      }}
    >
      {children}
    </TenantContext.Provider>
  );
}
