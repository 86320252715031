import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import * as Yup from "yup";
import useTranslation from "components/customHooks/translations";
import FormTextField from "components/Form/FormTextField/FormTextField";
import SelectField from "components/Form/SelectField";
import { Formik } from "formik";
import {
  getPaymentOptionsByTenant,
  createPayment,
  deletePayment,
  updatePayment,
  postLinkAccount,
  getAccountOnboardingStatus,
} from "services/tenants";

import IconButton from "@mui/material/IconButton";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  MenuItem,
  Stack,
  Switch,
  Typography,
} from "@mui/material";
import PapperCollapse from "components/PaperCollapse";
import { formDrawerWidth } from "components/Form/constants";
import { DeleteOutlined, EditOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { errorMessage } from "helpers";

const Payments = () => {
  const { selectedTenant, tenants } = useUserContext();
  const [payments, setPayments] = useState<any>(null);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [paymentsLoading, setPaymentsLoading] = useState(true);
  const [paymentForAction, setPaymentForAction] = useState<any>(null);
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();
  const [action, setAction] = useState<null | "delete" | "edit" | "create">(
    null
  );
  const [stripePayment, setStripePayment] = useState<any>(false);
  const [stripeStatusLoading, setStripeStatusLoading] = useState(true);
  const [stripeAccountStatus, setStripeAccountStatus] = useState({
    details_submitted: false,
    charges_enabled: false,
  });
  const translation = useTranslation();

  const paymentSchema = Yup.object().shape({
    label: Yup.string()
      .min(2, translation.signUpShortError)
      .max(50, translation.signUpLongError)
      .required(translation.required),
    type: Yup.string()
      .min(2, translation.signUpShortError)
      .max(50, translation.signUpLongError)
      .required(translation.required),
    info: Yup.string()
      .min(2, translation.signUpShortError)
      .max(255, translation.signUpLongError),
    description: Yup.string()
      .min(2, translation.signUpShortError)
      .max(255, translation.signUpLongError),
    iban: Yup.string().when("type", {
      is: "wire",
      then: Yup.string()
        .min(4, translation.signUpShortError)
        .max(34, translation.signUpLongError)
        .required(translation.required),
    }),
  });

  const linkAccount = (paymentId: string) => {
    postLinkAccount(paymentId).then((response) => {
      window.location.href = response.data.onboardingUrl;
    });
  };

  const togglePaymentActive = (payment: any) => {
    setPaymentsLoading(true);
    payment.isActive = !payment.isActive;
    updatePayment(payment).then((response) => {
      setUpdateTrigger(!updateTrigger);
    });
  };

  useEffect(() => {
    setPaymentsLoading(true);

    if (tenants !== null) {
      getPaymentOptionsByTenant(tenants[selectedTenant].id)
        .then((res) => {
          let stripeExists = false;
          res.data["hydra:member"].forEach((item: any) => {
            if (item.type === "stripe") {
              stripeExists = true;
              setStripePayment(item);
            }
          });
          if (!stripeExists) {
            setStripePayment(false);
          }
          setPayments(res.data["hydra:member"]);
          setPaymentsLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }, [selectedTenant, tenants, updateTrigger]);

  useEffect(() => {
    if (stripePayment) {
      getAccountOnboardingStatus(stripePayment.id).then((response) => {
        setStripeAccountStatus({
          details_submitted: response.data.details_submitted,
          charges_enabled: response.data.charges_enabled,
        });
        setStripeStatusLoading(false);
      });
    }
  }, [stripePayment]);

  const handleEdit = (payment: any) => {
    setPaymentForAction(payment);
    setAction("edit");
  };

  const resetAction = () => {
    setPaymentForAction(null);
    setAction(null);
    setUpdateTrigger(!updateTrigger);
  };

  const handleDelete = (payment: any) => {
    setPaymentForAction(payment);
    setAction("delete");
  };

  return (
    <>
      {selectedTenant != null && (
        <PapperCollapse createFunction={() => setAction("create")}>
          <Stack spacing={2} sx={{ pt: 4 }} divider={<Divider />}>
            {payments && payments.length > 0 ? (
              payments.map((payment: any) => {
                return (
                  <Stack spacing={1} key={payment["@id"]}>
                    <Stack direction="row" spacing={2} alignItems={"center"}>
                      <Switch
                        disabled={
                          (payment.type === "stripe" &&
                            !stripeStatusLoading &&
                            !stripeAccountStatus.charges_enabled) ||
                          paymentsLoading
                        }
                        checked={payment.isActive}
                        onChange={() => togglePaymentActive(payment)}
                      ></Switch>
                      <Box flexGrow={1}>
                        <Stack direction="row" spacing={1}>
                          <Typography color={"primary.main"}>
                            {payment.type}
                          </Typography>
                          <Typography>•</Typography>
                          <Typography>{payment.label}</Typography>
                        </Stack>
                      </Box>
                      <IconButton
                        aria-label="edit"
                        onClick={() => {
                          handleEdit(payment);
                        }}
                      >
                        <EditOutlined color="primary"></EditOutlined>
                      </IconButton>
                      <IconButton
                        aria-label="delete"
                        onClick={() => {
                          handleDelete(payment);
                        }}
                      >
                        <DeleteOutlined color="warning"></DeleteOutlined>
                      </IconButton>
                    </Stack>
                    <Typography variant="caption">
                      {payment.description}
                    </Typography>
                    {payment.type === "wire" && (
                      <Typography>IBAN: {payment.iban}</Typography>
                    )}
                    {payment.type === "stripe" &&
                      payment.isActive === false &&
                      stripeStatusLoading && <CircularProgress />}
                    {payment.type === "stripe" && !stripeStatusLoading && (
                      <Typography>
                        Stripe Status:{" "}
                        {stripeAccountStatus.charges_enabled
                          ? "verified"
                          : "not verified"}
                      </Typography>
                    )}
                    {payment.type === "stripe" &&
                      payment.isActive === false &&
                      !stripeStatusLoading &&
                      !stripeAccountStatus.details_submitted && (
                        <Box>
                          <Button
                            variant={"outlined"}
                            onClick={() => linkAccount(payment.id)}
                          >
                            {translation.stripeOnboardingButton}
                          </Button>
                        </Box>
                      )}
                  </Stack>
                );
              })
            ) : (
              <>
                {!paymentsLoading && <p>{translation.noPaymentsAvailable}</p>}
              </>
            )}
          </Stack>
        </PapperCollapse>
      )}
      <Drawer
        anchor="right"
        open={action !== null && action !== "delete"}
        onClose={() => {
          setAction(null);
        }}
        sx={{
          "& .MuiDrawer-paper": { width: formDrawerWidth },
        }}
      >
        <Formik
          initialValues={{
            label:
              action === "edit"
                ? paymentForAction.label
                  ? paymentForAction.label
                  : ""
                : "",
            type:
              action === "edit"
                ? paymentForAction.type
                  ? paymentForAction.type
                  : ""
                : "",
            iban:
              action === "edit"
                ? paymentForAction.iban
                  ? paymentForAction.iban
                  : ""
                : "",
            description:
              action === "edit"
                ? paymentForAction.description
                  ? paymentForAction.description
                  : ""
                : "",
            info:
              action === "edit"
                ? paymentForAction.info
                  ? paymentForAction.info
                  : ""
                : "",
          }}
          validationSchema={paymentSchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            if (action === "edit") {
              updatePayment({
                id: paymentForAction.id,
                label: values.label,
                type: values.type,
                iban: values.iban,
                description: values.description,
                info: values.info,
              })
                .then((res) => {
                  setSuccessMessage(translation.savedMessage);
                  setSubmitting(false);
                  setAction(null);
                  setUpdateTrigger(!updateTrigger);
                })
                .catch((e) => setErrorMessage(errorMessage(e)));
            } else {
              createPayment(
                tenants[selectedTenant].id,
                values.label,
                values.type,
                values.iban,
                values.description,
                values.info
              )
                .then((res) => {
                  setSuccessMessage(translation.savedMessage);
                  const payment = res.data;
                  if (payment.type === "stripe") {
                    linkAccount(payment.id);
                  }
                  setSubmitting(false);
                  setAction(null);
                  setUpdateTrigger(!updateTrigger);
                })
                .catch((e) => setErrorMessage(errorMessage(e)));
            }
          }}
        >
          {({ handleSubmit, isSubmitting, values }) => (
            <form noValidate className="" onSubmit={handleSubmit}>
              <Box sx={{ p: 4 }}>
                <Typography variant="h5">
                  {action === "edit"
                    ? translation.editPayment
                    : translation.newPayment}
                </Typography>
                <Stack spacing={2}>
                  {action === "create" && (
                    <SelectField label={translation.paymentType} name="type">
                      <MenuItem value="wire">Wire</MenuItem>
                      {stripePayment === false && (
                        <MenuItem value="stripe">Stripe</MenuItem>
                      )}
                      <MenuItem value="cash">Cash</MenuItem>
                    </SelectField>
                  )}
                  <FormTextField
                    label={translation.paymentLabel}
                    name="label"
                  />

                  {values.type === "wire" && (
                    <FormTextField
                      label={translation.paymentIban}
                      name="iban"
                    />
                  )}
                  <FormTextField
                    label={translation.paymentInfo}
                    multiline
                    name="info"
                    helperText={translation.paymentInfoDescription}
                  />
                  <FormTextField
                    label={translation.paymentDescription}
                    name="description"
                    multiline
                    helperText={translation.paymentDescriptionDescription}
                  />
                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"space-between"}
                  >
                    <Button
                      variant={"outlined"}
                      onClick={() => setAction(null)}
                    >
                      {translation.closeButton}
                    </Button>
                    <LoadingButton
                      variant="contained"
                      type="submit"
                      loading={isSubmitting}
                    >
                      {translation.savePayment}
                    </LoadingButton>
                  </Stack>
                </Stack>
              </Box>
            </form>
          )}
        </Formik>
      </Drawer>
      <Dialog
        open={action === "delete"}
        onClose={() => setAction(null)}
        aria-labelledby="alert-dialog-title-confirm-delete"
        aria-describedby="alert-dialog-confirm-delete"
      >
        <DialogTitle id="alert-dialog-title-confirm-delete">
          {translation.deletePayment}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-confirm-delete">
            <Typography>{translation.deletePaymentMessage}</Typography>
            <Typography fontWeight={700}>
              {paymentForAction ? paymentForAction.label : ""}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"} onClick={() => setAction(null)}>
            {translation.closeButton}
          </Button>
          <Button
            variant={"contained"}
            onClick={() => {
              deletePayment(paymentForAction).then(() => {
                resetAction();
              });
            }}
          >
            {translation.confirmDelete}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Payments;
