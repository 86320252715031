import { CompressOutlined } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { Box, Button, LinearProgress, Stack } from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import DropZoneImportField from "components/Form/DropZoneImportField/DropZoneImportField";
import ProductsMenu from "components/Menu/ProductsMenu";
import { Formik } from "formik";
import { IBulkUploadedProduct, IImageObject } from "interfaces";
import { useEffect, useRef, useState } from "react";
import { bulkUploadProducts, exportProductsByTenant } from "services/products";
import BulkProductsImages from "./BulkProductsImages";
import { CSVLink, CSVDownload } from "react-csv";
import { exampleCSV, importCSV } from "helpers/importCSV";
import { IProduct } from "interfaces";
import { errorMessage } from "helpers";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CsvImports from "./CsvImports";

interface FileUploadValues {
  file: null | File;
}

const ExportImport = () => {
  const { selectedTenant, tenants } = useUserContext();

  const translation = useTranslation();
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const initialValues: FileUploadValues = { file: null };
  const [exportedProducts, setExportedProducts] = useState<any>(importCSV);
  const [exportLoading, setExportLoading] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const csvLink = useRef<any>();
  const [openLoadingImport, setOpenLoadingImport] = useState(false);

  const handleClose = () => {
    setOpenLoadingImport(false);
  };
  const exportProducts = () => {
    setExportLoading(true);
    exportProductsByTenant(tenants[selectedTenant].id)
      .then((response) => {
        console.log(response);
        setExportLoading(false);
        const newExportedProducts = [...exportedProducts];
        const products = response.data["hydra:member"];
        products.forEach((product: any) => {
          console.log(product);
          const productImages = product.images;
          const productImagesUrls = productImages.map(
            (productImage: any) => productImage.contentUrl
          );
          const productImagesUrlsString = productImagesUrls.join(",");
          newExportedProducts.push([
            product.id,
            product.isVariable ? "parent" : "simple",
            product.name,
            product.sku,
            "",
            product.hasStock ? 1 : 0,
            product.stockCount,
            product.stockLimit,
            product.subcategory.name,
            "",
            product.description.replace(/"/g, '""'),
            product.slogan,
            product.brand,
            product.featured,
            product.tax.taxPercentage,
            product.netPrice,
            product.grossPrice,
            product.priceCurrency,
            product.visibility,
            product.unitOfMeasurement,
            product.weight,
            product.length,
            product.width,
            product.height,
            product.isVirtual ? 1 : 0,
            product.slug,
            product.pn,
            product.barcode,
            product.otherInfo,
            product.cost,
            product.productDiscount ? product.productDiscount.percentage : "",
            product.productDiscount
              ? tenants[selectedTenant].settings.taxIncluded
                ? product.productDiscount.netPrice
                : product.productDiscount.grossPrice
              : "",
            product.productDiscount ? product.productDiscount.startDate : "",
            product.productDiscount ? product.productDiscount.endDate : "",
            productImagesUrlsString,
          ]);
          if (product.isVariable) {
            product.variations.forEach((variation: any) => {
              newExportedProducts.push([
                variation.id,
                "variation",
                product.name + "-" + variation.name,
                variation.sku,
                product.sku,
                product.hasStock ? 1 : 0,
                variation.stockCount,
                "",
                "",
                variation.name,
                "",
                "",
                "",
                product.featured ? 1 : 0,
                product.tax.taxPercentage,
                variation.netPrice,
                variation.grossPrice,
                variation.priceCurrency,
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                "",
                variation.barcode,
                "",
                "",
                variation.productDiscount
                  ? variation.productDiscount.percentage
                  : "",
                variation.productDiscount
                  ? tenants[selectedTenant].settings.taxIncluded
                    ? variation.productDiscount.netPrice
                    : variation.productDiscount.grossPrice
                  : "",
                variation.productDiscount
                  ? variation.productDiscount.startDate
                  : "",
                variation.productDiscount
                  ? variation.productDiscount.endDate
                  : "",
                "",
              ]);
            });
          }
        });
        setExportedProducts(newExportedProducts);
      })
      .catch((e) => {
        console.log(e);
        setErrorMessage("Error exporting products!");
      });
  };

  useEffect(() => {
    if (exportedProducts.length > 1) {
      csvLink.current.link.click();
      setExportedProducts(importCSV);
    }
  }, [exportedProducts]);

  return (
    <>
      <ProductsMenu />

      <Box sx={{ width: "100%", mt: 4 }}>
        <Formik
          initialValues={initialValues}
          onSubmit={(values, actions) => {
            setOpenLoadingImport(true);

            const formData = new FormData();
            if (values.file) {
              actions.setSubmitting(true);
              formData.append("tenant", tenants[selectedTenant].id);
              formData.append("delimiter", ",");
              formData.append("hasHeaderRow", "true");
              formData.append("file", values.file, values.file.name);
              bulkUploadProducts(formData)
                .then((response) => {
                  setSuccessMessage(
                    translation.productsExportImport.uploadSuccess
                  );
                  //setBulkUploadedProducts(response.data.products);
                  setUpdateTrigger(!updateTrigger);
                  actions.resetForm();
                  actions.setSubmitting(false);
                  setOpenLoadingImport(false);
                })
                .catch((error) => {
                  actions.resetForm();
                  actions.setSubmitting(false);
                  setOpenLoadingImport(false);
                  setErrorMessage(errorMessage(error));
                });
            } else {
              setErrorMessage("Please upload a file.");
              actions.setSubmitting(false);
            }
          }}
          enableReinitialize
        >
          {({ handleSubmit, errors, isSubmitting }) => {
            return (
              <form noValidate className="" onSubmit={handleSubmit}>
                <Stack>
                  <DropZoneImportField
                    name="file"
                    label={translation.productsExportImport.importFile}
                    labelDescription={
                      translation.productsExportImport.importDescriptionField
                    }
                  ></DropZoneImportField>

                  <Box display={"flex"} justifyContent={"flex-start"}>
                    <LoadingButton
                      variant="contained"
                      loading={isSubmitting}
                      type="submit"
                    >
                      {translation.productsExportImport.import}
                    </LoadingButton>
                  </Box>
                </Stack>
              </form>
            );
          }}
        </Formik>
        <Box sx={{ mb: 2, mt: 8 }}>
          <CSVLink data={exampleCSV} filename="import-products.csv">
            <Button variant={"outlined"}>
              {translation.productsExportImport.downloadExampleCSV}
            </Button>
          </CSVLink>
        </Box>
        <Box sx={{ my: 2 }}>
          <LoadingButton
            loading={exportLoading}
            variant={"contained"}
            onClick={() => exportProducts()}
          >
            {translation.productsExportImport.exportProducts}
          </LoadingButton>
          <CSVLink
            data={exportedProducts}
            ref={csvLink as any}
            enclosingCharacter='"'
            filename="export-products.csv"
          ></CSVLink>
        </Box>
        <Dialog
          open={openLoadingImport}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {translation.productsExportImport.uploadingProductsTitle}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {translation.productsExportImport.uploadingProductsMessage}
            </DialogContentText>
          </DialogContent>
          <LinearProgress />
        </Dialog>
      </Box>
      <CsvImports updateTrigger={updateTrigger}/>
    </>
  );
};

export default ExportImport;
