import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import * as Yup from "yup";
import useTranslation from "components/customHooks/translations";
import FormTextField from "components/Form/FormTextField/FormTextField";
import SelectField from "components/Form/SelectField";
import { Formik } from "formik";
import { createCategory, updateCategory } from "services/products";
import {
  Box,
  Button,
  Drawer,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import SwitchField from "components/Form/SwitchField/SwitchField";
import { formDrawerWidth } from "components/Form/constants";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { ICategory, ICategoryHierarchy } from "interfaces";
import { errorMessage } from "helpers";
import DropZoneBrandField from "components/Form/DropZoneBrandField";

const CategoryForm = ({
  showNewCategory,
  setShowNewCategory,
  categoryToEdit,
  setCategoryToEdit,
  parentCategories,
  updateTrigger,
  setUpdateTrigger,
  setNewCategory,
}: {
  showNewCategory: boolean;
  setShowNewCategory: React.Dispatch<React.SetStateAction<boolean>>;
  categoryToEdit?: ICategory | null;
  setCategoryToEdit?: React.Dispatch<React.SetStateAction<ICategory | null>>;
  parentCategories: ICategoryHierarchy[];
  updateTrigger: boolean;
  setUpdateTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setNewCategory?: any;
}) => {
  const { selectedTenant, tenants } = useUserContext();
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const [parentCategoriesOptions, setParentCategoriesOptions] = useState<
    ICategoryHierarchy[]
  >([]);
  const translation = useTranslation();

  useEffect(() => {
    if (categoryToEdit) {
      setParentCategoriesOptions(
        parentCategories.filter(
          (parentCategory) =>
            parentCategory.id !== categoryToEdit.id &&
            parentCategory.parentName !== categoryToEdit.name
        )
      );
    } else {
      setParentCategoriesOptions(parentCategories);
    }
  }, [parentCategories, categoryToEdit]);

  const categorySchema = Yup.object().shape({
    name: Yup.string()
      .min(2, translation.signUpShortError)
      .max(50, translation.signUpLongError)
      .required(translation.required),
    isVisible: Yup.boolean().required(translation.required),
  });

  return (
    <>
      <Drawer
        anchor="right"
        open={showNewCategory}
        onClose={() => {
          setShowNewCategory(false);
          if (setCategoryToEdit) {
            setCategoryToEdit(null);
          }
        }}
        sx={{
          "& .MuiDrawer-paper": { width: formDrawerWidth },
        }}
      >
        <Formik
          initialValues={{
            name: categoryToEdit ? categoryToEdit.name : "",
            parent: categoryToEdit
              ? categoryToEdit.parent
                ? categoryToEdit.parent
                : ""
              : "",
            isVisible: categoryToEdit ? categoryToEdit.isVisible : true,
            image: categoryToEdit ? categoryToEdit.image : null,
          }}
          validationSchema={categorySchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            if (categoryToEdit) {
              updateCategory({
                ...categoryToEdit,
                name: values.name,
                parent: values.parent ? values.parent : null,
                isVisible: values.isVisible,
                image: values.image,
              })
                .then(() => {
                  setSubmitting(false);
                  setShowNewCategory(false);
                  if (setCategoryToEdit) {
                    setCategoryToEdit(null);
                  }
                  setSuccessMessage(translation.savedMessage);
                  setUpdateTrigger(!updateTrigger);
                })
                .catch((e) => {
                  setErrorMessage(errorMessage(e));
                  setSubmitting(false);
                });
            } else {
              createCategory(
                values.name,
                values.parent,
                tenants[selectedTenant].id,
                values.isVisible,
                values.image
              )
                .then((res) => {
                  setSubmitting(false);
                  setShowNewCategory(false);
                  if (setCategoryToEdit) {
                    setCategoryToEdit(null);
                  }
                  setSuccessMessage(translation.savedMessage);
                  setUpdateTrigger(!updateTrigger);
                  if (setNewCategory) {
                    setNewCategory(res.data["@id"]);
                  }
                })
                .catch((e) => {
                  setErrorMessage(errorMessage(e));
                  setSubmitting(false);
                });
            }
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form noValidate className="" onSubmit={handleSubmit}>
              <Box sx={{ p: 4 }}>
                <Typography variant="h5">
                  {categoryToEdit
                    ? translation.newCategory
                    : translation.editCategory}
                </Typography>
                <Stack spacing={2}>
                  <FormTextField label={translation.categoryName} name="name" />
                  <SelectField
                    name="parent"
                    label={translation.products.parentCategory}
                    width={"100%"}
                  >
                    <MenuItem value="">None</MenuItem>
                    {parentCategoriesOptions.map(
                      (category: ICategoryHierarchy) => {
                        return (
                          <MenuItem
                            value={"/categories/" + category.id}
                            key={"parentCategories" + category.id}
                          >
                            <Stack
                              sx={{
                                minHeight: "50px",
                              }}
                              justifyContent={"center"}
                            >
                              {category.parentName && (
                                <Typography
                                  sx={{
                                    color: "primary.dark",
                                    fontSize: "10px",
                                    fontWeight: 500,
                                  }}
                                >
                                  {category.parentName}
                                </Typography>
                              )}
                              <Typography>{category.name}</Typography>
                            </Stack>
                          </MenuItem>
                        );
                      }
                    )}
                  </SelectField>
                  <DropZoneBrandField
                    name="image"
                    label={translation.categories.imageField}
                    labelDescription={
                      translation.categories.imageDescriptionField
                    }
                  ></DropZoneBrandField>
                  <SwitchField
                    name="isVisible"
                    label={translation.categoryIsVisibleLabel}
                  ></SwitchField>
                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"space-between"}
                  >
                    <Button
                      variant={"outlined"}
                      onClick={() => {
                        setShowNewCategory(false);
                        if (setCategoryToEdit) {
                          setCategoryToEdit(null);
                        }
                      }}
                    >
                      {translation.closeButton}
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {translation.saveCategory}
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            </form>
          )}
        </Formik>
      </Drawer>
    </>
  );
};

export default CategoryForm;
