import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useTranslation from "components/customHooks/translations";
import LoadingIcon from "components/Feedback/LoadingIcon";
import PageHeader from "components/PageHeader";
import CustomersForm from "./CustomersForm";
import { getCustomer } from "services/tenants";
import { ICustomer } from "interfaces";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { Box, Button, Card, Paper, Stack, Typography } from "@mui/material";
import AddressForm from "./AddressForm";

const CustomerView = () => {
  const [customer, setCustomer] = useState<ICustomer | null>(null);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [showNewAddress, setShowNewAddress] = useState(false);
  const { isLoading, setLoading } = useLoadingContext();
  const translation = useTranslation();
  const { customerId } = useParams();

  useEffect(() => {
    if (customerId !== undefined) {
      setLoading(true);
      getCustomer(customerId)
        .then((customer) => {
          if (customer.data) {
            console.log(customer);
            setCustomer(customer.data);
            setLoading(false);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [customerId, updateTrigger]);

  return (
    <>
      <PageHeader>{translation.viewCustomerTitle}</PageHeader>
      {customer && (
        <Paper sx={{ p: 2 }}>
          <Typography variant="h5" sx={{ mb: 2 }}>
            Details:
          </Typography>
          <Stack direction="row" spacing={1}>
            <Typography color={"primary.main"}>
              {translation.customers.firstNameLabel}:
            </Typography>
            <Typography>{customer.firstName}</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography color={"primary.main"}>
              {translation.customers.lastNameLabel}:
            </Typography>
            <Typography>{customer.lastName}</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography color={"primary.main"}>
              {translation.customers.phoneNumberLabel}:
            </Typography>
            <Typography>{customer.phoneNumber}</Typography>
          </Stack>
          <Stack direction="row" spacing={1}>
            <Typography color={"primary.main"}>
              {translation.customers.emailLabel}:
            </Typography>
            <Typography>{customer.email}</Typography>
          </Stack>
          <Typography variant="h5" sx={{ my: 2 }}>
            Addresses:
          </Typography>
          <Stack direction={"row"} flexWrap={"wrap"}>
            {customer.addresses.map((item: any, ind: any) => {
              return (
                <Card
                  key={"shipping-address-" + ind}
                  sx={{
                    backgroundColor: "grey.100",
                    p: "1rem",
                    m: "8px",
                    position: "relative",
                    boxShadow: "none",
                    border: "1px solid",
                  }}
                >
                  <Typography mb={0.5}>{item.label}</Typography>
                  <Typography color="grey.700">{item.name}</Typography>
                  <Typography color="grey.700">
                    {item.companyName}
                    {", "}
                    {item.vatId}
                  </Typography>
                  <Typography color="grey.700">
                    {item.streetAddress}
                    {", "}
                    {item.city}
                    {", "}
                    {item.postalCode}
                  </Typography>
                  <Typography color="grey.700">{item.phoneNumber}</Typography>
                </Card>
              );
            })}
          </Stack>
          <Box sx={{ my: 2 }}>
            <Button
              variant={"contained"}
              onClick={() => {
                setShowNewAddress(true);
              }}
            >
              Add Address
            </Button>
          </Box>
        </Paper>
      )}
      {customer && (
        <AddressForm
          setShowNewAddress={setShowNewAddress}
          showNewAddress={showNewAddress}
          setUpdateTrigger={setUpdateTrigger}
          updateTrigger={updateTrigger}
          customer={customer}
        ></AddressForm>
      )}
    </>
  );
};

export default CustomerView;
