import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import LoadingIcon from "components/Feedback/LoadingIcon";
import {
  editOrderStatus,
  getOrder,
  createAwb,
  createInvoice,
  getInvoice,
  getInvoiceDownload,
  updateOrder,
} from "services/orders";
import {
  Grid,
  Box,
  Typography,
  Stack,
  Button,
  ToggleButton,
  Link,
  Modal,
  Dialog,
  Drawer,
  Toolbar,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";
import useTranslation from "components/customHooks/translations";
import Paper from "@mui/material/Paper";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import { orderStatuses } from "helpers/orderStatuses";
import PageHeader from "components/PageHeader";
import OrderProductsList from "components/Orders/OrderProductsList";
import { Done } from "@mui/icons-material";
import { useUserContext } from "components/contexts/UserContext";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { errorMessage, formattedDate } from "helpers";
import {
  IAddress,
  IInvoice,
  IOrder,
  IOrderProduct,
  IOrderView,
} from "interfaces";
import AddressCard from "components/Customers/AddressCard";
import AddressForm from "pages/Customers/AddressForm";
import { useLoadingContext } from "components/contexts/LoadingContext";
import EditProducts from "pages/Products/EditProducts";
import EditOrderProducts from "components/Orders/EditProducts";
import { drawerWidth } from "components/Layout/SideNav";

export async function loader({ params }: any) {
  return getOrder(params.orderId);
}

const OrderView = () => {
  const { selectedTenant, tenants } = useUserContext();
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const { setLoading } = useLoadingContext();
  const [showNewAddress, setShowNewAddress] = useState(false);

  const [order, setOrder] = useState<IOrderView | null>(null);
  const [orderProducts, setOrderProducts] = useState<IOrderProduct[]>([]);
  const [orderisLoading, setOrderisLoading] = useState(true);
  const [awb, setAwb] = useState<any>(null);
  const [invoice, setInvoice] = useState<IInvoice | null>(null);
  const [orderStatus, setOrderStatus] = useState(undefined);
  const [successStatus, setSuccessStatus] = useState(false);
  const [openEditProducts, setOpenEditProducts] = useState(false);
  const [openEditProductsConfirmation, setOpenEditProductsConfirmation] =
    useState(false);
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [addressToEdit, setAddressToEdit] = useState<IAddress | null>(null);
  const [loadingAwb, setLoadingAwb] = useState(false);
  const [awbError, setAwbError] = useState("");
  const translation = useTranslation();
  const { orderId } = useParams();

  useEffect(() => {
    if (orderId !== undefined) {
      setLoading(true);
      getOrder(orderId)
        .then((order) => {
          if (order.data) {
            console.log(order.data);
            if (order.data.awbs.length > 0) {
              setAwb(order.data.awbs[0]);
            }
            setOrder(order.data);
            const newOrderProducts = order.data.products.map(
              (orderProduct: IOrderProduct) => {
                return {
                  ...orderProduct,
                };
              }
            );
            setOrderProducts(newOrderProducts);
            setLoading(false);
            if (order.data.invoices.length > 0) {
              setLoadingAwb(true);
              getInvoice(order.data.invoices[0]).then((res) => {
                setLoadingAwb(false);
                setInvoice(res.data);
              });
            }
            setOrderisLoading(false);
          }
        })
        .catch((e) => console.log(e));
    }
  }, [updateTrigger]);

  const handleChangeStatus = (event: any) => {
    setOrderStatus(event.target.value);
  };

  const saveStatus = () => {
    if (orderStatus) {
      setLoadingStatus(true);
      editOrderStatus(orderId, orderStatus).then((res) => {
        if (res.status < 300) {
          setLoadingStatus(false);
          setSuccessStatus(true);
          setTimeout(() => {
            setSuccessStatus(false);
          }, 6000);
        }
      });
    }
  };

  const generateAwb = () => {
    if (order) {
      if (order["@id"]) {
        if (order.shippingMethod["@id"]) {
          setLoadingAwb(true);
          createAwb(order["@id"], order.shippingMethod["@id"])
            .then((res) => {
              setLoadingAwb(false);
              if (res.status < 300) {
                setAwb(res.data);
              }
            })
            .catch((e) => {
              setLoadingAwb(false);
              setErrorMessage(errorMessage(e));
            });
        }
      }
    }
  };

  const generateInvoice = () => {
    if (order) {
      if (order["@id"]) {
        setLoadingAwb(true);
        createInvoice({
          tenant: tenants[selectedTenant]["@id"],
          shopOrder: order["@id"],
        })
          .then((res) => {
            setLoadingAwb(false);
            setSuccessMessage(translation.invoice.invoiceGenerated);
            if (res.status < 300) {
              setInvoice(res.data);
            }
          })
          .catch((e) => {
            setLoadingAwb(false);
            setErrorMessage(errorMessage(e));
          });
      }
    }
  };

  const handleCloseEditProducts = () => {
    setOpenEditProducts(false);
  };

  const handleSaveNewOrderProducts = () => {
    if (order) {
      const newOrder: IOrder = { ...order };
      newOrder.products = orderProducts;
      setOpenEditProductsConfirmation(false);
      setLoading(true);
      console.log(newOrder);
      updateOrder(newOrder)
        .then((res) => {
          setLoading(false);
          setUpdateTrigger(!updateTrigger);
          handleCloseEditProducts();
          setSuccessMessage(translation.order.saved);
        })
        .catch((e) => {
          setErrorMessage(errorMessage(e));
          setLoading(false);
        });
    }
  };

  return (
    <>
      {orderisLoading || tenants == null ? (
        <LoadingIcon />
      ) : (
        <>
          {order && (
            <Box>
              <PageHeader>
                {translation.orderTitle + " #" + order.id}
              </PageHeader>
              <Stack spacing={2}>
                <Paper sx={{ padding: 2 }}>
                  <Stack spacing={2}>
                    <Stack
                      direction="row"
                      justifyContent={"space-between"}
                      alignItems={"center"}
                    >
                      <Stack direction="row" spacing={2} alignItems={"center"}>
                        <FormControl
                          variant="standard"
                          sx={{
                            m: 1,
                            minWidth: 120,
                          }}
                        >
                          <InputLabel id="status-select-standard-label">
                            {translation.status}
                          </InputLabel>
                          <Select
                            labelId="status-select-standard-label"
                            id="status-select-standard"
                            defaultValue={order.status}
                            onChange={handleChangeStatus}
                            label={translation.status}
                          >
                            {orderStatuses.map((orderStatus) => (
                              <MenuItem
                                key={orderStatus.slug}
                                value={orderStatus.slug}
                              >
                                {orderStatus.label}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                        <Box>
                          <LoadingButton
                            onClick={() => saveStatus()}
                            loading={loadingStatus}
                          >
                            {translation.save}
                          </LoadingButton>
                          {successStatus && <Done color="primary"></Done>}
                        </Box>
                      </Stack>
                      <Box>
                        <Stack direction={"row"} spacing={1}>
                          <Typography>
                            {translation.order.paymentMethod}:
                          </Typography>
                          <Typography
                            sx={{
                              fontWeight: 500,
                            }}
                            color={"primary.dark"}
                          >
                            {order.payment.label}
                          </Typography>
                        </Stack>
                      </Box>
                    </Stack>
                    <OrderProductsList order={order} />
                    <Stack
                      direction={"row"}
                      justifyContent="space-between"
                      spacing={2}
                      sx={{ paddingY: 4 }}
                    >
                      <Stack spacing={1}>
                        <Typography>{translation.order.usedCoupon}:</Typography>
                        {order.coupons?.map((coupon) => (
                          <Typography key={coupon.name}>
                            {coupon.name}
                          </Typography>
                        ))}
                      </Stack>
                      <Stack spacing={1} alignItems={"flex-end"}>
                        <Stack direction={"row"} spacing={1}>
                          <Typography>{translation.order.subtotal}</Typography>
                          <Box sx={{ width: "100px" }}>
                            <Typography align="right">
                              {tenants[selectedTenant].settings.taxIncluded
                                ? order.totalPrice?.netPrice
                                : order.totalPrice?.grossPrice}
                            </Typography>
                          </Box>
                          <Typography>
                            {order.totalPrice?.priceCurrency}
                          </Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1}>
                          <Typography>{translation.order.shipping}</Typography>
                          <Box sx={{ width: "100px" }}>
                            <Typography align="right">
                              {tenants[selectedTenant].settings.taxIncluded
                                ? order.shippingMethodSnapshot?.netPrice
                                : order.shippingMethodSnapshot?.grossPrice}
                            </Typography>
                          </Box>
                          <Typography>
                            {tenants[selectedTenant].settings.defaultCurrency}
                          </Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1}>
                          <Typography>
                            {translation.order.totalDiscount}
                          </Typography>
                          <Box sx={{ width: "100px" }}>
                            <Typography align="right">
                              {order.totalDiscount?.netPrice > 0 ? "-" : ""}
                              {tenants[selectedTenant].settings.taxIncluded
                                ? order.totalDiscount?.netPrice
                                : order.totalDiscount?.grossPrice}
                            </Typography>
                          </Box>
                          <Typography>
                            {order.totalDiscount.priceCurrency}
                          </Typography>
                        </Stack>
                        <Stack direction={"row"} spacing={1}>
                          <Typography>{translation.order.total}</Typography>
                          <Box sx={{ width: "100px" }}>
                            <Typography align="right">
                              {tenants[selectedTenant].settings.taxIncluded
                                ? order.finalPrice.netPrice
                                : order.finalPrice.grossPrice}
                            </Typography>
                          </Box>
                          <Typography>
                            {order.finalPrice.priceCurrency}
                          </Typography>
                        </Stack>
                      </Stack>
                    </Stack>
                    <Box>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setOpenEditProducts(true);
                        }}
                      >
                        {translation.order.editProducts}
                      </Button>
                    </Box>
                  </Stack>
                </Paper>

                <Paper sx={{ padding: 2 }}>
                  <Stack spacing={2}>
                    <Typography>{translation.order.customer}:</Typography>
                    <Stack>
                      <Typography color={"primary"}>
                        {order.customer.firstName !== null
                          ? order.customer.firstName +
                            " " +
                            order.customer.lastName
                          : order.shippingAddress.name}
                      </Typography>
                      <Typography color={"text.disabled"}>
                        {order.customer.email}
                      </Typography>
                      <Typography color={"text.disabled"}>
                        {order.customer.phoneNumber}
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
                <Paper sx={{ padding: 2 }}>
                  <Stack spacing={2}>
                    <Stack direction={"row"} spacing={1}>
                      <Typography>{translation.order.delivery}:</Typography>
                      {order.shippingMethod ? (
                        <Typography
                          sx={{
                            fontWeight: 500,
                          }}
                          color={"primary.dark"}
                        >
                          {order.shippingMethod.name}
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Stack>{" "}
                    {order.shippingMethodSnapshot.courier !== null ? (
                      awb === null ? (
                        <Box>
                          <LoadingButton
                            onClick={() => generateAwb()}
                            loading={loadingAwb}
                          >
                            {translation.order.generateAwb}
                          </LoadingButton>
                          <Typography color="error">{awbError}</Typography>
                        </Box>
                      ) : (
                        <Typography>Awb: {awb.awb}</Typography>
                      )
                    ) : (
                      ""
                    )}
                  </Stack>
                </Paper>
                <Paper sx={{ padding: 2 }}>
                  <Stack spacing={2}>
                    <Typography>{translation.invoice.invoice}</Typography>
                    {invoice === null ? (
                      <Box>
                        <LoadingButton
                          variant="contained"
                          onClick={() => generateInvoice()}
                          loading={loadingAwb}
                        >
                          {translation.order.generateInvoice}
                        </LoadingButton>
                      </Box>
                    ) : (
                      <Stack direction="row" justifyContent={"space-between"}>
                        <Link
                          component="button"
                          variant="body1"
                          onClick={() => {
                            getInvoiceDownload(
                              invoice["@id"] ? invoice["@id"] : ""
                            ).then((res) => {
                              if (res.data.url) {
                                window.open(res.data.url, "_blank");
                              }
                            });
                          }}
                          color="primary"
                        >
                          #{invoice.fullNumber}
                        </Link>
                        <Typography>
                          {formattedDate(invoice.createdAt)}
                        </Typography>
                        <Typography>{invoice.status}</Typography>
                      </Stack>
                    )}
                  </Stack>
                </Paper>
                <Paper sx={{ padding: 2 }}>
                  <Stack spacing={2}>
                    <Typography>
                      {translation.order.shippingAddress}:
                    </Typography>
                    <Box>
                      <AddressCard
                        address={order.shippingAddress}
                      ></AddressCard>
                    </Box>
                    <Box>
                      <Button
                        variant={"contained"}
                        onClick={() => {
                          setAddressToEdit(order.shippingAddress);
                          setShowNewAddress(true);
                        }}
                      >
                        {translation.customers.editAddress}
                      </Button>
                    </Box>
                  </Stack>
                </Paper>
                <Paper sx={{ padding: 2 }}>
                  <Stack spacing={2}>
                    <Typography>{translation.order.billingAddress}:</Typography>
                    <Box>
                      <AddressCard address={order.billingAddress}></AddressCard>
                    </Box>
                    <Box>
                      <Button
                        variant={"contained"}
                        onClick={() => {
                          setAddressToEdit(order.billingAddress);
                          setShowNewAddress(true);
                        }}
                      >
                        {translation.customers.editAddress}
                      </Button>
                    </Box>
                  </Stack>
                </Paper>
              </Stack>
              <Drawer
                anchor="right"
                open={openEditProducts}
                sx={{
                  zIndex: 1000,
                  "& .MuiDrawer-paper": {
                    width: `calc(100% - ${drawerWidth}px)`,
                  },
                }}
                onClose={() => handleCloseEditProducts()}
              >
                <Toolbar />
                <Stack sx={{ p: 4, height: "100%" }}>
                  <Box flexGrow={1}>
                    <EditOrderProducts
                      orderProducts={orderProducts}
                      setOrderProducts={setOrderProducts}
                    ></EditOrderProducts>
                  </Box>
                  <Box>
                    <Stack direction={"row"} spacing={2}>
                      <Button
                        variant="outlined"
                        onClick={() => {
                          handleCloseEditProducts();
                        }}
                      >
                        {translation.closeButton}
                      </Button>
                      <Button
                        variant="contained"
                        onClick={() => {
                          setOpenEditProductsConfirmation(true);
                        }}
                      >
                        {translation.saveButton}
                      </Button>
                    </Stack>
                  </Box>
                </Stack>
              </Drawer>
              <Dialog
                open={openEditProductsConfirmation}
                onClose={() => setOpenEditProductsConfirmation(false)}
                aria-labelledby="alert-dialog-title-confirm-delete"
                aria-describedby="alert-dialog-confirm-delete"
              >
                <DialogTitle id="alert-dialog-title-confirm-delete">
                  {translation.order.editProducts}
                </DialogTitle>
                <DialogContent>
                  <Typography>
                    {translation.order.editProductsConfirmation}
                  </Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    variant={"outlined"}
                    onClick={() => setOpenEditProductsConfirmation(false)}
                  >
                    {translation.closeButton}
                  </Button>
                  <Button
                    variant={"contained"}
                    onClick={() => {
                      handleSaveNewOrderProducts();
                    }}
                  >
                    {translation.saveButton}
                  </Button>
                </DialogActions>
              </Dialog>
              <AddressForm
                updateTrigger={updateTrigger}
                setUpdateTrigger={setUpdateTrigger}
                setShowNewAddress={setShowNewAddress}
                showNewAddress={showNewAddress}
                addressToEdit={addressToEdit}
                setAddressToEdit={setAddressToEdit}
              ></AddressForm>
            </Box>
          )}
        </>
      )}
    </>
  );
};

export default OrderView;
