import React, { useState, useEffect } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { getTranslatableItems, translatables } from "helpers/translatables";
import useTranslation from "components/customHooks/translations";
import { Stack } from "@mui/material";
import { searchProductsNames } from "services/products";
import { useUserContext } from "components/contexts/UserContext";
import { ITranslatable } from "interfaces";
import { useLoadingContext } from "components/contexts/LoadingContext";
import TranslatablesList from "./TranslatablesList";
import useTenant from "components/customHooks/useTenant";
import { useTenantContext } from "components/contexts/TenantContext";
import TranslationForm from "./TranslationForm";

export default function LocalizationForm({
  expanded,
  handleChange,
  translatable,
}: {
  expanded: boolean;
  handleChange: any;
  translatable: typeof translatables.products;
}) {
  const t = useTranslation();
  const { categoriesList } = useTenantContext();
  const { loading, setLoading } = useLoadingContext();
  const { tenants, selectedTenant } = useUserContext();
  const [items, setItems] = useState<ITranslatable[]>([]);
  const [selectedItem, setSelectedItem] = useState<ITranslatable | null>(null);
  const [selectedIndex, setSelectedIndex] = React.useState<number | null>(null);

  useEffect(() => {
    if (expanded) {
      setLoading(true);
      getTranslatableItems(translatable, tenants[selectedTenant].id).then(
        (res) => {
          setLoading(false);
          setItems(res);
          setSelectedItem(null);
          setSelectedIndex(null);
          if (res.length === 1) {
            setSelectedItem(res[0]);
            setSelectedIndex(0);
          }
        }
      );
    }
  }, [expanded, selectedTenant]);

  const selectNextItem = () => {
    let realItems = items;
    if (translatable.id === translatables.categories.id) {
      realItems = categoriesList;
    }
    if (selectedItem && selectedIndex !== null) {
      const index = selectedIndex + 1;
      if (index < realItems.length) {
        setSelectedItem(realItems[index]);
        setSelectedIndex(index);
      } else {
        setSelectedItem(realItems[0]);
        setSelectedIndex(0);
      }
    }
  };

  return (
    <Accordion expanded={expanded} onChange={handleChange()}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id={translatable.id}>
        <Typography
          sx={{
            width: "100%",
            maxWidth: 360,
            flexShrink: 0,
          }}
        >
          {t[translatable.title as keyof typeof t] as string}
        </Typography>
        {selectedItem !== null && (
          <Typography
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "text.secondary",
              ml: "16px",
            }}
          >
            {selectedItem.name}
          </Typography>
        )}
      </AccordionSummary>
      <AccordionDetails>
        <Stack direction="row" spacing={2}>
          <TranslatablesList
            translatable={translatable}
            translatableItems={items}
            setTranslatableItem={setSelectedItem}
            selectedIndex={selectedIndex}
            setSelectedIndex={setSelectedIndex}
          />
          {selectedItem && (
            <TranslationForm
              translatable={translatable}
              selectedItem={selectedItem}
              selectNextItem={selectNextItem}
            ></TranslationForm>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
}
