import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import {
  HomeOutlined,
  EventAvailableOutlined,
  ShoppingBasketOutlined,
  GroupOutlined,
  LightbulbOutlined,
  SellOutlined,
  LoyaltyOutlined,
  BarChartOutlined,
  DriveFileMoveOutlined,
  DescriptionOutlined,
  Language,
} from "@mui/icons-material";
import { routeNames } from "routes";
import { useUserContext } from "components/contexts/UserContext";
import logo from "assets/img/logo.svg";
import { collapseTextChangeRangesAcrossMultipleVersions } from "typescript";
import useTranslation from "components/customHooks/translations";
import { Link } from "react-router-dom";
import { Navigate, useLocation } from "react-router-dom";
import { useTenantContext } from "components/contexts/TenantContext";

export const drawerWidth = 240;

export default function SideNav() {
  const { settings, settingsLoading } = useTenantContext();
  const { user, isLoading, userId, tenants, selectedTenant } = useUserContext();
  const translation = useTranslation();
  const location = useLocation();
  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        "& .MuiDrawer-paper": {
          width: drawerWidth,
          boxSizing: "border-box",
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar>
        <Link to={user ? routeNames.home : routeNames.home}>
          <img src={logo} className="logo-navbar"></img>
        </Link>
      </Toolbar>
      <Divider />
      <List>
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname.includes(routeNames.dashboard)}
            component={Link}
            to={routeNames.dashboard}
          >
            <ListItemIcon>
              <HomeOutlined />
            </ListItemIcon>
            <ListItemText
              color="primary"
              primary={translation.dashboardTitle}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname.includes(routeNames.do)}
            component={Link}
            to={routeNames.do}
          >
            <ListItemIcon>
              <EventAvailableOutlined />
            </ListItemIcon>
            <ListItemText color="primary" primary={translation.doTitle} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname.includes(routeNames.invoices)}
            component={Link}
            to={routeNames.invoices}
          >
            <ListItemIcon>
              <DescriptionOutlined />
            </ListItemIcon>
            <ListItemText color="primary" primary={translation.invoicesTitle} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname.includes(routeNames.orders)}
            component={Link}
            to={routeNames.orders}
          >
            <ListItemIcon>
              <ShoppingBasketOutlined />
            </ListItemIcon>
            <ListItemText color="primary" primary={translation.ordersTitle} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname.includes(routeNames.customers)}
            component={Link}
            to={routeNames.customers}
          >
            <ListItemIcon>
              <GroupOutlined />
            </ListItemIcon>
            <ListItemText
              color="primary"
              primary={translation.customersTitle}
            />
          </ListItemButton>
        </ListItem>
        <ListItem sx={{ paddingTop: 0, paddingX: 0, paddingBottom: 2 }}>
          <ListItemButton
            selected={location.pathname.includes(routeNames.helpdesk)}
            component={Link}
            to={routeNames.helpdesk}
          >
            <ListItemIcon>
              <LightbulbOutlined />
            </ListItemIcon>
            <ListItemText color="primary" primary={translation.helpdeskTitle} />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname.includes(routeNames.products)}
            component={Link}
            to={routeNames.products}
          >
            <ListItemIcon>
              <SellOutlined />
            </ListItemIcon>
            <ListItemText color="primary" primary={translation.productsTitle} />
          </ListItemButton>
        </ListItem>

        <ListItem sx={{ paddingTop: 0, paddingX: 0, paddingBottom: 2 }}>
          <ListItemButton
            selected={location.pathname.includes(routeNames.subscriptions)}
            component={Link}
            to={routeNames.subscriptions}
          >
            <ListItemIcon>
              <LoyaltyOutlined />
            </ListItemIcon>
            <ListItemText
              color="primary"
              primary={translation.subscriptionsTitle}
            />
          </ListItemButton>
        </ListItem>
        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname.includes(routeNames.coupons)}
            sx={{ paddingY: 1 }}
            component={Link}
            to={routeNames.coupons}
          >
            <ListItemIcon>
              <BarChartOutlined />
            </ListItemIcon>
            <ListItemText
              color="primary"
              primary={translation.marketingTitle}
            />
          </ListItemButton>
        </ListItem>
        {!settingsLoading &&
          settings.enabledLocales &&
          settings.enabledLocales.length > 1 && (
            <ListItem disablePadding>
              <ListItemButton
                selected={location.pathname.includes(routeNames.localization)}
                sx={{ paddingY: 1 }}
                component={Link}
                to={routeNames.localization}
              >
                <ListItemIcon>
                  <Language />
                </ListItemIcon>
                <ListItemText
                  color="primary"
                  primary={translation.localizationTitle}
                />
              </ListItemButton>
            </ListItem>
          )}

        <ListItem disablePadding>
          <ListItemButton
            selected={location.pathname.includes(routeNames.reports)}
            component={Link}
            to={routeNames.reports}
          >
            <ListItemIcon>
              <DriveFileMoveOutlined />
            </ListItemIcon>
            <ListItemText color="primary" primary={translation.reportsTitle} />
          </ListItemButton>
        </ListItem>
      </List>
    </Drawer>
  );
}
