import axios from "axios";
// axios instance for making requests
const axiosFormDataInstance = axios.create();

// request interceptor for adding token
axiosFormDataInstance.interceptors.request.use((config) => {
  // add token to request headers
  config.headers["X-DTW-ADMIN"] = "true";
  config.headers["Authorization"] = "Bearer " + localStorage.getItem("token");
  config.headers["Content-Type"] = "multipart/form-data";

  return config;
});

export default axiosFormDataInstance;
