import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import {
  deleteSubsCategory,
  getProductsByTenant,
  getSubsCategoriesByTenant,
  updateSubsCategory,
} from "services/products";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Paper,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import LoadingIcon from "components/Feedback/LoadingIcon";
import Grid from "@mui/material/Unstable_Grid2/Grid2";
import ProductsMenu from "components/Menu/ProductsMenu";
import {
  Add,
  ExpandMore,
  ExpandLess,
  Delete,
  DeleteOutline,
} from "@mui/icons-material";
import SubsCategoryForm from "./SubsCategoryForm";
import { ISubsCategory } from "interfaces";
import { errorMessage } from "helpers";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import SelectField from "components/Form/SelectField";
import { Formik } from "formik";
import { LoadingButton } from "@mui/lab";
import { getValueFromValueOptions } from "@mui/x-data-grid/components/panel/filterPanel/filterPanelUtils";
import SubscriptionsMenu from "components/Menu/SubscriptionsMenu";

const SubsCategories = () => {
  const { tenantsIsLoading, selectedTenant, tenants } = useUserContext();
  const [subsCategories, setSubsCategories] = useState<ISubsCategory[]>([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const [subsCategoriesOpen, setSubsCategoriesOpen] = useState<boolean[]>([]);
  const [subsCategoriesLoading, setSubsCategoriesLoading] = useState(true);
  const [showNewSubsCategory, setShowNewSubsCategory] = useState(false);
  const [subsCategoryToEdit, setSubsCategoryToEdit] =
    useState<ISubsCategory | null>(null);
  const [categoryToDelete, setSubsCategoryToDelete] =
    useState<ISubsCategory | null>(null);
  const [isDeleting, setIsDeleting] = useState(false);
  const [categoryToDeleteHasProducts, setSubsCategoryToDeleteHasProducts] =
    useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const theme = useTheme();
  const translation = useTranslation();

  useEffect(() => {
    setSubsCategoriesLoading(true);

    if (tenants !== null) {
      getSubsCategoriesByTenant(tenants[selectedTenant].id)
        .then((res) => {
          setSubsCategories(res.data["hydra:member"]);
          setSubsCategoriesLoading(false);
        })
        .catch((e) => console.log(e));
    }
  }, [selectedTenant, tenants, updateTrigger]);

  const handleEdit = (category: ISubsCategory) => {
    setSubsCategoryToEdit(category);
    setShowNewSubsCategory(true);
  };

  const handleDeleteSubsCategory = async (newSubsCategory: string) => {
    if (categoryToDelete) {
      setSubsCategoriesLoading(true);
      // if (categoryToDeleteHasProducts) {
      //   updateSubsCategory({
      //     ...categoryToDelete,
      //     newSubsCategory: newSubsCategory,
      //   })
      //     .then((res) => {
      //       console.log(res);
      //       setUpdateTrigger(!updateTrigger);
      //       setSuccessMessage(translation.deletedMessage);
      //       setShowDeleteModal(false);
      //       setSubsCategoryToDelete(null);
      //       setSubsCategoryToDeleteHasProducts(false);
      //     })
      //     .catch((e) => {
      //       setErrorMessage(errorMessage(e));
      //       setSubsCategoriesLoading(false);
      //     });
      // } else {
      //   deleteSubsCategory(categoryToDelete)
      //     .then(() => {
      //       setUpdateTrigger(!updateTrigger);
      //       setSuccessMessage(translation.deletedMessage);
      //       setShowDeleteModal(false);
      //       setSubsCategoryToDelete(null);
      //       setSubsCategoryToDeleteHasProducts(false);
      //     })
      //     .catch((e) => {
      //       setErrorMessage(errorMessage(e));
      //       setSubsCategoriesLoading(false);
      //     });
      // }
    }
  };

  // const handleDelete = async (category: ISubsCategory) => {
  //   if (category.id) {
  //     const res = await getProductsBySubsCategory(
  //       tenants[selectedTenant].id,
  //       category.id
  //     );
  //     if (res.data["hydra:totalItems"] > 0) {
  //       setSubsCategoryToDeleteHasProducts(true);
  //     }
  //     console.log(categoryToDeleteHasProducts);
  //     setSubsCategoryToDelete(category);
  //     setShowDeleteModal(true);
  //   }
  // };

  return (
    <>
      <SubscriptionsMenu />
      {tenantsIsLoading ? (
        <LoadingIcon />
      ) : (
        selectedTenant != null && (
          <>
            <Stack direction={"row"} justifyContent={"flex-end"}>
              <Box sx={{ mb: 2 }}>
                <Button
                  variant={"contained"}
                  startIcon={<Add />}
                  onClick={() => setShowNewSubsCategory(!showNewSubsCategory)}
                >
                  {translation.subscriptions.newSubsCategory}
                </Button>
              </Box>
            </Stack>
            <Grid container spacing={2}>
              {subsCategoriesLoading ? (
                <LoadingIcon />
              ) : subsCategories && subsCategories.length > 0 ? (
                subsCategories.map((category: ISubsCategory, index: number) => {
                  return (
                    <Grid key={"category-" + index} md={4}>
                      <Paper>
                        <List>
                          <ListItem>
                            <ListItemText primary={category.name} />
                            <IconButton
                              aria-label="edit"
                              onClick={() => {
                                handleEdit(category);
                              }}
                            >
                              <EditIcon color="primary"></EditIcon>
                            </IconButton>

                            {/* <IconButton
                              aria-label="delete"
                              onClick={() => {
                                //handleDelete(category);
                              }}
                            >
                              <Delete color="warning"></Delete>
                            </IconButton> */}
                          </ListItem>
                        </List>
                      </Paper>
                    </Grid>
                  );
                })
              ) : (
                <>
                  <p>{translation.subscriptions.noSubsCategoriesAvailable}</p>
                </>
              )}
            </Grid>
          </>
        )
      )}
      <SubsCategoryForm
        showNewSubsCategory={showNewSubsCategory}
        setShowNewSubsCategory={setShowNewSubsCategory}
        subsCategoryToEdit={subsCategoryToEdit}
        setSubsCategoryToEdit={setSubsCategoryToEdit}
        updateTrigger={updateTrigger}
        setUpdateTrigger={setUpdateTrigger}
      ></SubsCategoryForm>
      <Dialog
        open={showDeleteModal}
        onClose={() => {
          setSubsCategoryToDelete(null);
          setShowDeleteModal(false);
          setSubsCategoryToDeleteHasProducts(false);
        }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {categoryToDeleteHasProducts
            ? translation.subscriptions.deleteSubsCategoryWithProductsTitle
            : translation.subscriptions.deleteSubsCategoryTitle}
        </DialogTitle>

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {categoryToDeleteHasProducts
              ? translation.subscriptions
                  .deleteSubsCategoryWithProductsDescription
              : translation.subscriptions.deleteSubsCategoryDescription}
          </DialogContentText>
          <Typography>{categoryToDelete?.name}</Typography>
        </DialogContent>
        {categoryToDeleteHasProducts ? (
          <Formik
            initialValues={{
              newSubsCategory: "",
            }}
            enableReinitialize
            onSubmit={(values, actions) => {
              if (values.newSubsCategory === "") {
                actions.setFieldError("newSubsCategory", translation.required);
              }
              //handleDeleteSubsCategory(values.newSubsCategory);
              actions.setSubmitting(false);
            }}
          >
            {({ submitForm }) => (
              <DialogContent sx={{ pt: 0 }}>
                <SelectField
                  name="newSubsCategory"
                  label={translation.subscriptions.newSubsCategoryField}
                >
                  {subsCategories
                    ? subsCategories
                        .filter(
                          (category: ISubsCategory) =>
                            category.id !== categoryToDelete?.id
                        )
                        .map((category: ISubsCategory) => {
                          return (
                            <MenuItem
                              value={"/subs_categories/" + category.id}
                              key={"productSubsCategory" + category.id}
                            >
                              <Stack
                                sx={{
                                  minHeight: "50px",
                                }}
                                justifyContent={"center"}
                              >
                                <Typography>{category.name}</Typography>
                              </Stack>
                            </MenuItem>
                          );
                        })
                    : ""}
                </SelectField>
                <DialogActions>
                  <Button
                    variant={"outlined"}
                    onClick={() => {
                      setSubsCategoryToDelete(null);
                      setShowDeleteModal(false);
                      setSubsCategoryToDeleteHasProducts(false);
                    }}
                  >
                    {translation.closeButton}
                  </Button>

                  <LoadingButton
                    type="submit"
                    onClick={submitForm}
                    variant="contained"
                    loading={subsCategoriesLoading}
                  >
                    {translation.deleteButton}
                  </LoadingButton>
                </DialogActions>
              </DialogContent>
            )}
          </Formik>
        ) : (
          <DialogActions>
            <Button
              variant={"outlined"}
              onClick={() => {
                setSubsCategoryToDelete(null);
                setShowDeleteModal(false);
                setSubsCategoryToDeleteHasProducts(false);
              }}
            >
              {translation.closeButton}
            </Button>

            <LoadingButton
              onClick={() => handleDeleteSubsCategory("")}
              variant="contained"
              loading={subsCategoriesLoading}
            >
              {translation.deleteButton}
            </LoadingButton>
          </DialogActions>
        )}
      </Dialog>
    </>
  );
};

export default SubsCategories;
