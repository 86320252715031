import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import LanguageContextProvider from "./components/contexts/LanguageContext";
import "assets/fonts/RedHatDisplay-VariableFont_wght.ttf";
import "./assets/sass/theme.scss";
import UserContextProvider from "components/contexts/UserContext";
import SnackbarContextProvider from "components/contexts/SnackbarContext";
import LoadingContextProvider from "components/contexts/LoadingContext";
import TenantContextProvider from "components/contexts/TenantContext";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <LanguageContextProvider>
    <UserContextProvider>
      <SnackbarContextProvider>
        <LoadingContextProvider>
          <TenantContextProvider>
            <App />
          </TenantContextProvider>
        </LoadingContextProvider>
      </SnackbarContextProvider>
    </UserContextProvider>
  </LanguageContextProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
