import { getApiResponseData } from "helpers";
import {
  createCategoryLocale,
  createProductLocale,
  getCategoriesByTenant,
  getCategoryLocales,
  getProduct,
  getProductLocales,
  searchProductsNames,
  updateCategoryLocale,
  updateProductLocale,
} from "services/products";
import {
  createBrandBookLocale,
  createConsentLocale,
  createCroLocale,
  createPaymentLocale,
  createPolicyLocale,
  createShippingMethodLocale,
  getBrandBookByTenant,
  getBrandBookLocales,
  getConsentByTenant,
  getConsentLocales,
  getCroByTenant,
  getCroLocales,
  getPaymentOptionById,
  getPaymentOptionLocales,
  getPaymentOptionsByTenant,
  getPoliciesByTenant,
  getPoliciesLocales,
  getShippingMethod,
  getShippingMethodLocales,
  getShippingMethodsByTenant,
  updateBrandBookLocale,
  updateConsentLocale,
  updateCroLocale,
  updatePaymentLocale,
  updatePolicyLocale,
  updateShippingMethodLocale,
} from "services/tenants";
import locales from "./locales";
import FormTextField from "components/Form/FormTextField/FormTextField";
import QuillTextField from "components/Form/QuillTextField/QuillTextField";
import ArraySpecificationsField from "components/Form/ArraySpecificationsField";
import QuillSnippetField from "components/Form/QuillSnippetField/QuillSnippetField";

export const translatables = {
  products: { id: "products", title: "productsTitle" },
  categories: { id: "categories", title: "categoriesTitle" },
  shipping: { id: "shipping", title: "shippingTitle" },
  payments: { id: "payments", title: "paymentsTitle" },
  policies: { id: "policies", title: "policiesTitle" },
  cros: { id: "cros", title: "croTitle" },
  brandBooks: { id: "brandBooks", title: "brandBookHeader" },
  consent: { id: "consent", title: "consentTitle" },
};

export const getTranslatableItems = async (translatable, tenantId) => {
  if (translatable.id === translatables.products.id) {
    const res = await searchProductsNames(tenantId);

    return getApiResponseData(res);
  } else if (translatable.id === translatables.categories.id) {
    //we have global categories in react context + we need parent category name for custom select component
    return [];
  } else if (translatable.id === translatables.shipping.id) {
    const res = await getShippingMethodsByTenant(tenantId);
    return getApiResponseData(res);
  } else if (translatable.id === translatables.payments.id) {
    const res = await getPaymentOptionsByTenant(tenantId);
    return getApiResponseData(res).map((payment) => {
      return { id: payment.id, name: payment.label };
    });
  } else if (translatable.id === translatables.policies.id) {
    const res = await getPoliciesByTenant(tenantId);
    return getApiResponseData(res);
  } else if (translatable.id === translatables.cros.id) {
    const res = await getCroByTenant(tenantId);

    return getApiResponseData(res);
  } else if (translatable.id === translatables.brandBooks.id) {
    const res = await getBrandBookByTenant(tenantId);

    return getApiResponseData(res);
  } else if (translatable.id === translatables.consent.id) {
    const res = await getConsentByTenant(tenantId);

    return getApiResponseData(res);
  }
};

export const getTranslatableItem = async (translatable, item) => {
  if (translatable.id === translatables.products.id) {
    const res = await getProduct(item.id);
    const resT = await getProductLocales(item.id);

    const orig = getApiResponseData(res);
    const trans = getApiResponseData(resT);
    return { original: orig, translations: trans };
  } else if (translatable.id === translatables.categories.id) {
    //we have global categories in react context + we need parent category name for custom select component
    const resT = await getCategoryLocales(item.id);
    const trans = getApiResponseData(resT);
    return { original: item, translations: trans };
  } else if (translatable.id === translatables.shipping.id) {
    const res = await getShippingMethod(item.id);
    const resT = await getShippingMethodLocales(item.id);
    const orig = getApiResponseData(res);
    const trans = getApiResponseData(resT);
    return { original: orig, translations: trans };
  } else if (translatable.id === translatables.payments.id) {
    const res = await getPaymentOptionById(item.id);
    const resT = await getPaymentOptionLocales(item.id);
    const orig = getApiResponseData(res);
    const trans = getApiResponseData(resT);
    return { original: orig, translations: trans };
  } else if (translatable.id === translatables.policies.id) {
    const resT = await getPoliciesLocales(item.id);
    const trans = getApiResponseData(resT);
    return { original: item, translations: trans };
  } else if (translatable.id === translatables.cros.id) {
    const resT = await getCroLocales(item.id);
    const trans = getApiResponseData(resT);
    return { original: item, translations: trans };
  } else if (translatable.id === translatables.brandBooks.id) {
    const resT = await getBrandBookLocales(item.id);
    const trans = getApiResponseData(resT);
    return { original: item, translations: trans };
  } else if (translatable.id === translatables.consent.id) {
    const resT = await getConsentLocales(item.id);
    const trans = getApiResponseData(resT);
    return { original: item, translations: trans };
  }
};

export const getLocaleLanguageName = (locale) => {
  if (locales["language-names"][locale]) {
    return locales["language-names"][locale][1];
  } else {
    return "";
  }
};

export const getTranslatableItemFields = (translatable) => {
  if (translatable.id === translatables.products.id) {
    return [
      {
        component: FormTextField,
        label: "Name",
        props: {
          name: "name",
          multiline: false,
        },
      },
      {
        component: QuillTextField,
        label: "Description",
        props: {
          name: "description",
        },
      },
      {
        component: FormTextField,
        label: "Slogan",
        props: {
          name: "slogan",
          multiline: false,
        },
      },
      {
        component: FormTextField,
        label: "Unit of Measurement",
        props: {
          name: "unitOfMeasurement",
          multiline: false,
        },
      },
      {
        component: QuillTextField,
        label: "Benefits",
        props: {
          name: "benefits",
          multiline: false,
        },
      },
      {
        component: ArraySpecificationsField,
        label: "Specifications",
        props: {
          name: "specifications",
          multiline: false,
        },
      },
    ];
  } else if (translatable.id === translatables.categories.id) {
    return [
      {
        component: FormTextField,
        label: "Name",
        props: {
          name: "name",
          multiline: false,
        },
      },
    ];
  } else if (translatable.id === translatables.shipping.id) {
    return [
      {
        component: FormTextField,
        label: "Name",
        props: {
          name: "name",
          multiline: false,
        },
      },
      {
        component: FormTextField,
        label: "Description",
        props: {
          name: "description",
          multiline: false,
        },
      },
      {
        component: FormTextField,
        label: "Info",
        props: {
          name: "info",
          multiline: false,
        },
      },
    ];
  } else if (translatable.id === translatables.payments.id) {
    return [
      {
        component: FormTextField,
        label: "Name",
        props: {
          name: "label",
          multiline: false,
        },
      },
      {
        component: FormTextField,
        label: "Description",
        props: {
          name: "description",
          multiline: false,
        },
      },
      {
        component: FormTextField,
        label: "Info",
        props: {
          name: "info",
          multiline: false,
        },
      },
    ];
  } else if (translatable.id === translatables.policies.id) {
    return [
      {
        component: FormTextField,
        label: "Name",
        props: {
          name: "name",
          multiline: false,
        },
      },
      {
        component: QuillSnippetField,
        label: "Content",
        props: {
          name: "content",
          multiline: false,
        },
      },
    ];
  } else if (translatable.id === translatables.cros.id) {
    return [
      {
        component: FormTextField,
        label: "Free Shipping Message",
        props: {
          name: "name",
          multiline: true,
        },
      },
      {
        component: FormTextField,
        label: "Latest Products",
        props: {
          name: "latestProducts",
          multiline: false,
        },
      },
      {
        component: FormTextField,
        label: "Featured Products",
        props: {
          name: "featuredProducts",
          multiline: false,
        },
      },
    ];
  } else if (translatable.id === translatables.brandBooks.id) {
    return [
      {
        component: FormTextField,
        label: "Slogan",
        props: {
          name: "slogan",
          multiline: false,
        },
      },
      {
        component: FormTextField,
        label: "Company short description",
        props: {
          name: "shortDescription",
          multiline: true,
        },
      },
    ];
  } else if (translatable.id === translatables.consent.id) {
    return [
      {
        component: FormTextField,
        label: "Modal Text",
        props: {
          name: "modalText",
          multiline: true,
        },
      },
      {
        component: FormTextField,
        label: "Accept All Button",
        props: {
          name: "acceptAll",
          multiline: false,
        },
      },
      {
        component: FormTextField,
        label: "Reject All Button",
        props: {
          name: "rejectAll",
          multiline: false,
        },
      },
      {
        component: FormTextField,
        label: "Confirm Button",
        props: {
          name: "confirm",
          multiline: false,
        },
      },
      {
        component: FormTextField,
        label: "Marketing purpose",
        props: {
          name: "marketingName",
          multiline: false,
        },
      },
      {
        component: FormTextField,
        label: "Marketing description",
        props: {
          name: "marketingDescription",
          multiline: false,
        },
      },
    ];
  }

  return [];
};

export const getTranslatableItemDefaultValues = (translatable, item) => {
  if (translatable.id === translatables.products.id) {
    return {
      name: item ? item.name : "",
      description: item ? item.description : "",
      slogan: item ? item.slogan : "",
      unitOfMeasurement: item ? item.unitOfMeasurement : "",
      benefits: item ? item.benefits : "",
      specifications: item
        ? item.specifications
          ? [...item.specifications, { name: "", content: "" }]
          : [{ name: "", content: "" }]
        : [{ name: "", content: "" }],
    };
  } else if (translatable.id === translatables.categories.id) {
    return {
      name: item ? item.name : "",
    };
  } else if (translatable.id === translatables.shipping.id) {
    return {
      name: item ? item.name : "",
      description: item ? item.description : "",
      info: item ? item.info : "",
    };
  } else if (translatable.id === translatables.payments.id) {
    return {
      label: item ? item.label : "",
      description: item ? item.description : "",
      info: item ? item.info : "",
    };
  } else if (translatable.id === translatables.policies.id) {
    return {
      name: item ? item.name : "",
      content: item ? item.content : "",
    };
  } else if (translatable.id === translatables.cros.id) {
    return {
      freeShippingMessage: item ? item.freeShippingMessage : "",
      latestProducts: item ? item.latestProducts : "",
      featuredProducts: item ? item.featuredProducts : "",
    };
  } else if (translatable.id === translatables.brandBooks.id) {
    return {
      slogan: item ? item.slogan : "",
      shortDescription: item ? item.shortDescription : "",
    };
  } else if (translatable.id === translatables.consent.id) {
    return {
      modalText: item ? item.modalText : "",
      acceptAll: item ? item.acceptAll : "",
      rejectAll: item ? item.rejectAll : "",
      confirm: item ? item.confirm : "",
      marketingName: item ? item.marketingName : "",
      marketingDescription: item ? item.marketingDescription : "",
    };
  }
  return {
    name: "item.name",
  };
};

export const updateItemTranslation = async (
  translatable,
  values,
  originalItem,
  item,
  locale
) => {
  console.log(item);
  console.log(translatable);
  if (translatable.id === translatables.products.id) {
    const specifications = [...values.specifications];
    specifications.pop();
    if (item) {
      const res = await updateProductLocale({
        id: item.id,
        name: values.name,
        description: values.description,
        benefits: values.benefits,
        unitOfMeasurement: values.unitOfMeasurement,
        slogan: values.slogan,
        specifications: specifications,
        product: item.product,
        locale: locale,
      });

      return getApiResponseData(res);
    } else {
      const res = await createProductLocale({
        name: values.name,
        description: values.description,
        benefits: values.benefits,
        unitOfMeasurement: values.unitOfMeasurement,
        slogan: values.slogan,
        specifications: specifications,
        product: originalItem["@id"],
        locale: locale,
      });
      return getApiResponseData(res);
    }
  } else if (translatable.id === translatables.categories.id) {
    if (item) {
      const res = await updateCategoryLocale({
        id: item.id,
        category: item.category,
        locale: locale,
      });

      return getApiResponseData(res);
    } else {
      const res = await createCategoryLocale({
        name: values.name,
        category: "categories/" + originalItem.id,
        locale: locale,
      });
      return getApiResponseData(res);
    }
  } else if (translatable.id === translatables.shipping.id) {
    if (item) {
      const res = await updateShippingMethodLocale({
        id: item.id,
        name: values.name,
        description: values.description,
        info: values.info,

        shippingMethod: item.shippingMethod,
        locale: locale,
      });

      return getApiResponseData(res);
    } else {
      const res = await createShippingMethodLocale({
        name: values.name,
        description: values.description,
        info: values.info,

        shippingMethod: originalItem["@id"],
        locale: locale,
      });
      return getApiResponseData(res);
    }
  } else if (translatable.id === translatables.payments.id) {
    if (item) {
      const res = await updatePaymentLocale({
        id: item.id,
        label: values.label,
        description: values.description,
        info: values.info,

        payment: item.payment,
        locale: locale,
      });

      return getApiResponseData(res);
    } else {
      const res = await createPaymentLocale({
        label: values.label,
        description: values.description,
        info: values.info,

        payment: originalItem["@id"],
        locale: locale,
      });
      return getApiResponseData(res);
    }
  } else if (translatable.id === translatables.policies.id) {
    if (item) {
      const res = await updatePolicyLocale({
        id: item.id,
        name: values.name,
        content: values.content,
        policy: item.policy,
        locale: locale,
      });

      return getApiResponseData(res);
    } else {
      const res = await createPolicyLocale({
        name: values.name,
        content: values.content,
        policy: originalItem["@id"],
        locale: locale,
      });
      return getApiResponseData(res);
    }
  } else if (translatable.id === translatables.cros.id) {
    console.log(item);
    if (item) {
      const res = await updateCroLocale({
        id: item.id,
        freeShippingMessage: values.freeShippingMessage,
        latestProducts: values.latestProducts,
        featuredProducts: values.featuredProducts,
        cro: originalItem["@id"],
        locale: locale,
      });

      return getApiResponseData(res);
    } else {
      let res = null;
      try {
        const res = await createCroLocale({
          freeShippingMessage: values.freeShippingMessage,
          latestProducts: values.latestProducts,
          featuredProducts: values.featuredProducts,
          cro: originalItem["@id"],
          locale: locale,
        });
      } catch (e) {
        console.log(e);
        return getApiResponseData(res);
      }
      return getApiResponseData(res);
    }
  } else if (translatable.id === translatables.brandBooks.id) {
    if (item) {
      const res = await updateBrandBookLocale({
        id: item.id,
        slogan: values.slogan,
        shortDescription: values.shortDescription,
        brandBook: originalItem["@id"],
        locale: locale,
      });

      return getApiResponseData(res);
    } else {
      const res = await createBrandBookLocale({
        slogan: values.slogan,
        shortDescription: values.shortDescription,
        brandBook: originalItem["@id"],
        locale: locale,
      });
      return getApiResponseData(res);
    }
  } else if (translatable.id === translatables.consent.id) {
    console.log(item);
    if (item) {
      const res = await updateConsentLocale({
        id: item.id,
        modalText: values.modalText,
        acceptAll: values.acceptAll,
        rejectAll: values.rejectAll,
        confirm: values.confirm,
        marketingName: values.marketingName,
        marketingDescription: values.marketingDescription,
        consent: originalItem["@id"],
        locale: locale,
      });

      return getApiResponseData(res);
    } else {
      let res = null;
      try {
        const res = await createConsentLocale({
          freeShippingMessage: values.freeShippingMessage,
          modalText: values.modalText,
          acceptAll: values.acceptAll,
          rejectAll: values.rejectAll,
          confirm: values.confirm,
          marketingName: values.marketingName,
          marketingDescription: values.marketingDescription,
          consent: originalItem["@id"],
          locale: locale,
        });
      } catch (e) {
        console.log(e);
        return getApiResponseData(res);
      }
      return getApiResponseData(res);
    }
  } else
    return {
      name: "item.name",
    };
};
