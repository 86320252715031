import React from "react";
import { useField } from "formik";
import TextField from "@mui/material/TextField";
import { InputAdornment, Stack, SvgIcon, Typography } from "@mui/material";
import { ReactComponent as Threads } from "assets/img/threads.svg";
import {
  FacebookRounded,
  Instagram,
  Link,
  LinkedIn,
  Pinterest,
  Telegram,
  Twitter,
  WhatsApp,
  X,
  YouTube,
} from "@mui/icons-material";
import TikTok from "components/Icons/TikTok";
import Discord from "components/Icons/Discord";

const ArraySocialField = ({
  label,
  name,
}: {
  label?: string;
  name: string;
}) => {
  const [field, meta, helper] = useField(name);

  return (
    <>
      <Stack spacing={2}>
        <Typography>{label}</Typography>

        {field.value.map((value: any, index: number) => {
          return (
            <TextField
              key={"name-" + index}
              variant="standard"
              value={value}
              onChange={(e) => {
                const oldValues = [...field.value];
                oldValues[index] = e.target.value;
                if (index === oldValues.length - 1) {
                  oldValues.push("");
                }
                if (e.target.value === "") {
                  oldValues.splice(index, 1);
                }
                helper.setValue(oldValues);
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    {value.includes("facebook") || value.includes("fb.com") ? (
                      <FacebookRounded />
                    ) : value.includes("twitter") || value.includes("x.com") ? (
                      <X />
                    ) : value.includes("instagram") ? (
                      <Instagram />
                    ) : value.includes("linkedin") ? (
                      <LinkedIn />
                    ) : value.includes("t.me") ? (
                      <Telegram />
                    ) : value.includes("wa.me") ? (
                      <WhatsApp />
                    ) : value.includes("tiktok") ? (
                      <TikTok />
                    ) : value.includes("youtube") ? (
                      <YouTube />
                    ) : value.includes("discord") ? (
                      <Discord />
                    ) : value.includes("pinterest") ? (
                      <Pinterest />
                    ) : value.includes("threads") ? (
                      <SvgIcon>
                        <Threads />
                      </SvgIcon>
                    ) : (
                      <Link />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          );
        })}
      </Stack>
    </>
  );
};

export default ArraySocialField;
