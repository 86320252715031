import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import * as Yup from "yup";
import useTranslation from "components/customHooks/translations";
import FormTextField from "components/Form/FormTextField/FormTextField";
import { Formik } from "formik";
import {
  createPolicy,
  deletePolicy,
  getAppsByTenantId,
  getPoliciesByTenant,
  updatePolicy,
  updateTenant,
} from "services/tenants";

import PapperCollapse from "components/PaperCollapse";
import { useLoadingContext } from "components/contexts/LoadingContext";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Drawer,
  IconButton,
  MenuItem,
  Popover,
  Stack,
  Switch,
  Toolbar,
  Typography,
} from "@mui/material";
import {
  Close,
  ContentCopyOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@mui/icons-material";
import { formattedDate } from "helpers";
import { drawerWidth } from "components/Layout/SideNav";
import SubmitCloseButton from "components/Form/SubmitCloseButton";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import QuillSnippetField from "components/Form/QuillSnippetField/QuillSnippetField";
import SelectField from "components/Form/SelectField";

const Policies = () => {
  const { selectedTenant, tenants } = useUserContext();
  const { loading, setLoading } = useLoadingContext();
  const { setSuccessMessage } = useSnackbarContext();
  const [policies, setPolicies] = useState<any>(null);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [policyForAction, setPolicyForAction] = useState<any>(null);
  const [action, setAction] = useState<null | "delete" | "edit" | "create">(
    null
  );
  const translation = useTranslation();

  const [app, setApp] = useState<any>(null);

  const policySchema = Yup.object().shape({
    name: Yup.string()
      .min(2, translation.signUpShortError)
      .max(50, translation.signUpLongError)
      .required(translation.required),
  });

  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const openPopOver = Boolean(anchorEl);

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    setLoading(true);

    if (tenants !== null) {
      getPoliciesByTenant(tenants[selectedTenant].id)
        .then((res) => {
          setPolicies(res.data["hydra:member"]);
          setLoading(false);
        })
        .catch((e) => console.log(e));
      getAppsByTenantId(tenants[selectedTenant].id)
        .then((res) => {
          setApp(res.data["hydra:member"][0]);
        })
        .catch((e) => console.log(e));
    }
  }, [selectedTenant, tenants, updateTrigger]);

  const handleEdit = (policy: any) => {
    setPolicyForAction(policy);
    setAction("edit");
  };

  const resetAction = () => {
    setPolicyForAction(null);
    setAction(null);
    setUpdateTrigger(!updateTrigger);
  };

  const handleDelete = (policy: any) => {
    setPolicyForAction(policy);
    setAction("delete");
  };

  const togglePolicyActive = (policy: any) => {
    setLoading(true);
    policy.isActive = !policy.isActive;
    updatePolicy(policy).then((response) => {
      setUpdateTrigger(!updateTrigger);
    });
  };

  return (
    <>
      <PapperCollapse createFunction={() => setAction("create")}>
        <Stack spacing={2} sx={{ pt: 4 }} divider={<Divider />}>
          {policies && policies.length > 0 ? (
            policies.map((policy: any) => {
              return (
                <Stack spacing={1} key={policy["@id"]}>
                  <Stack direction="row" spacing={2} alignItems={"center"}>
                    <Switch
                      disabled={loading}
                      checked={policy.isActive}
                      onChange={() => togglePolicyActive(policy)}
                    ></Switch>
                    <Box flexGrow={1}>
                      <Typography>{policy.name}</Typography>
                    </Box>
                    <Button
                      color="primary"
                      onClick={(event: React.MouseEvent<HTMLButtonElement>) => {
                        const url = app
                          ? "https://" + app.domain + "/" + policy.slug
                          : "/" + policy.slug;
                        navigator.clipboard.writeText(url);
                        setAnchorEl(event.currentTarget);
                        setTimeout(() => {
                          setAnchorEl(null);
                        }, 2000);
                      }}
                    >
                      <ContentCopyOutlined />
                      {translation.policies.copyUrl}
                    </Button>
                    <IconButton
                      aria-label="edit"
                      onClick={() => {
                        handleEdit(policy);
                      }}
                    >
                      <EditOutlined color="primary"></EditOutlined>
                    </IconButton>
                    <IconButton
                      aria-label="delete"
                      onClick={() => {
                        handleDelete(policy);
                      }}
                    >
                      <DeleteOutlined color="warning"></DeleteOutlined>
                    </IconButton>
                  </Stack>
                  <Typography variant="overline" color={"text.secondary"}>
                    {translation.policies.lastUpdated +
                      " " +
                      formattedDate(policy.updateAt)}
                  </Typography>
                </Stack>
              );
            })
          ) : (
            <>{!loading && <p>{translation.policies.noPolicies}</p>}</>
          )}
        </Stack>
      </PapperCollapse>
      <Popover
        id="simple-popover"
        sx={{
          pointerEvents: "none",
        }}
        open={openPopOver}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 1, color: "text.secondary" }}>
          {translation.policies.copiedUrl}
        </Typography>
      </Popover>
      <Drawer
        anchor="right"
        open={action !== null && action !== "delete"}
        sx={{
          zIndex: 1000,
          "& .MuiDrawer-paper": {
            width: `calc(100% - ${drawerWidth}px)`,
          },
        }}
        onClose={() => {
          setAction(null);
        }}
      >
        <Toolbar />
        <Formik
          initialValues={{
            name: action === "edit" ? policyForAction.name : "",
            slug: action === "edit" ? policyForAction.slug : "",
            type: action === "edit" ? policyForAction.type : "custom",
            content: action === "edit" ? policyForAction.content : "",
          }}
          validationSchema={policySchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            const slug =
              values.slug === ""
                ? values.name.replace(/\s+/g, "-").toLowerCase()
                : values.slug;
            if (action === "edit") {
              updatePolicy({
                id: policyForAction.id,
                name: values.name,
                slug: slug,
                content: values.content,
              }).then((res) => {
                setSubmitting(false);
                updateTenant(
                  {
                    ...tenants[selectedTenant].id,
                  },
                  tenants[selectedTenant].id
                );
                setSuccessMessage(translation.policies.policyUpdated);
                setUpdateTrigger(!updateTrigger);
              });
            } else {
              createPolicy(
                values.name,
                slug,
                values.content,
                tenants[selectedTenant].id
              ).then((res) => {
                setSubmitting(false);
                updateTenant(
                  {
                    ...tenants[selectedTenant].id,
                  },
                  tenants[selectedTenant].id
                );
                setSuccessMessage(translation.policies.policySaved);
                setUpdateTrigger(!updateTrigger);
              });
            }
          }}
        >
          {({ isSubmitting, submitForm, isValid, validateForm }) => (
            <Box sx={{ p: 4, height: "100%" }}>
              <Stack
                spacing={2}
                sx={{ minHeight: "100%" }}
                justifyContent="space-between"
              >
                <Box>
                  <Stack spacing={2}>
                    <Stack direction="row" justifyContent={"space-between"}>
                      <Typography variant="h5">
                        {action === "edit"
                          ? translation.policies.editPolicy
                          : translation.policies.newPolicy}
                      </Typography>
                      <IconButton onClick={() => setAction(null)}>
                        <Close></Close>
                      </IconButton>
                    </Stack>

                    <Stack spacing={2}>
                      <FormTextField
                        label={translation.policies.name}
                        name="name"
                      />
                      <FormTextField
                        label={translation.policies.slugLabel}
                        name="slug"
                      />
                      <SelectField
                        name="type"
                        label={translation.policies.typeLabel}
                      >
                        <MenuItem value={"custom"}>Custom</MenuItem>
                        <MenuItem value={"terms-and-conditions"}>
                          Terms and conditions
                        </MenuItem>
                        <MenuItem value={"cookie-policy"}>
                          Cookie policy
                        </MenuItem>
                        <MenuItem value={"privacy-policy"}>
                          Privacy policy
                        </MenuItem>
                        <MenuItem value={"refund-policy"}>
                          Refund policy
                        </MenuItem>
                        <MenuItem value={"shipping-policy"}>
                          Shipping policy
                        </MenuItem>
                        <MenuItem value={"payments-policy"}>
                          Payments policy
                        </MenuItem>
                      </SelectField>
                      <QuillSnippetField name="content" />
                    </Stack>
                  </Stack>
                </Box>
                <Box display={"flex"} justifyContent="flex-end">
                  <SubmitCloseButton
                    submitAction={submitForm}
                    isSubmitting={isSubmitting}
                    closeAction={() => setAction(null)}
                    isValid={isValid}
                    validateForm={validateForm}
                  />
                </Box>
              </Stack>
            </Box>
          )}
        </Formik>
      </Drawer>
      <Dialog
        open={action === "delete"}
        onClose={() => setAction(null)}
        aria-labelledby="alert-dialog-title-confirm-delete"
        aria-describedby="alert-dialog-confirm-delete"
      >
        <DialogTitle id="alert-dialog-title-confirm-delete">
          {translation.policies.deletePolicy}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-confirm-delete">
            <Typography>{translation.policies.deletePolicyMessage}</Typography>
            <Typography fontWeight={700}>
              {policyForAction ? policyForAction.name : ""}
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant={"outlined"} onClick={() => setAction(null)}>
            {translation.closeButton}
          </Button>
          <Button
            variant={"contained"}
            onClick={() => {
              deletePolicy(policyForAction).then(() => {
                setSuccessMessage(translation.policies.successDelete);
                resetAction();
              });
            }}
          >
            {translation.confirmDelete}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default Policies;
