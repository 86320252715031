import React from "react";
import Navbar from "./Navbar";
import SideNav from "./SideNav";
import { Box } from "@mui/system";
import CssBaseline from "@mui/material/CssBaseline";
import { Backdrop, Divider, Toolbar } from "@mui/material";
import { useUserContext } from "components/contexts/UserContext";
import { useLoadingContext } from "components/contexts/LoadingContext";
import LoadingIcon from "components/Feedback/LoadingIcon";
import SettingsMenu from "components//Menu/SettingsMenu";
import "dayjs/locale/ro";
import EcommerceMenu from "components/Menu/EcommerceMenu";
import EcommerceDesignMenu from "components/Menu/EcommerceDesignMenu";
import EcommerceCustomOptionsMenu from "components/Menu/EcommerceCustomOptionsMenu";

const Layout = ({
  hasSettingsMenu,
  hasEcommerceDesignMenu,
  hasEcoomerceCustomOptionsMenu,
  children,
}: {
  hasSettingsMenu?: boolean;
  hasEcommerceDesignMenu?: boolean;
  hasEcoomerceCustomOptionsMenu?: boolean;
  children: React.ReactNode;
}) => {
  const { tenantsIsLoading, isLoading, userId } = useUserContext();
  const { loading } = useLoadingContext();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      {userId && (
        <>
          <Navbar />
          <SideNav />
        </>
      )}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          bgcolor: "background.default",
          p: 2,
          mt: 2,
        }}
      >
        <Toolbar />
        {hasSettingsMenu && <SettingsMenu />}
        {(hasEcommerceDesignMenu || hasEcoomerceCustomOptionsMenu) && (
          <>
            <EcommerceMenu />
            <Divider />
            {hasEcommerceDesignMenu && <EcommerceDesignMenu />}
            {hasEcoomerceCustomOptionsMenu && <EcommerceCustomOptionsMenu />}
          </>
        )}
        {children}
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={isLoading || loading || tenantsIsLoading}
        >
          <LoadingIcon></LoadingIcon>
        </Backdrop>
        {/* {(isLoading || loading || tenantsIsLoading) && (
            
          )} */}
      </Box>
    </Box>
  );
};

export default Layout;
