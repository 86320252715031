import React, { useEffect, useState } from "react";
import useTranslation from "components/customHooks/translations";
import {
  Box,
  Button,
  CircularProgress,
  Collapse,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  Link,
  MenuItem,
  Paper,
  Popover,
  Select,
  SelectChangeEvent,
  Stack,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  TableRow,
  Tabs,
  Typography,
} from "@mui/material";
import { ICustomer } from "interfaces";
import { useUserContext } from "components/contexts/UserContext";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { getCustomersByTenant } from "services/tenants";
import {
  Add,
  Dangerous,
  EditOutlined,
  HiveOutlined,
  Warning,
  WarningAmber,
} from "@mui/icons-material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { errorMessage, formattedDate } from "helpers";
import { useNavigate } from "react-router-dom";
import EnhancedTableHead, {
  HeadCell,
} from "components/Table/EnhancedTableHead";
import { routeNames } from "routes";

interface Data {
  id: number;
  name: string;
  email: string;
  phoneNumber: string;
}

const headCells: HeadCell[] = [
  {
    id: "name",
    numeric: false,
    disablePadding: false,
    label: "Name",
    sorting: false,
  },
  {
    id: "email",
    numeric: true,
    disablePadding: false,
    label: "Email",
    sorting: false,
  },
  {
    id: "phoneNumber",
    numeric: true,
    disablePadding: false,
    label: "Phone Number",
    sorting: false,
  },
];

const Customers = () => {
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const { selectedTenant, tenants } = useUserContext();
  const [customers, setCustomers] = useState<ICustomer[]>([]);
  const [ordersCount, setOrdersCount] = useState(0);
  const [pagesNumber, setPagesNumber] = useState(0);
  const [statusFilter, setStatusFilter] = useState("");
  const [showFilters, setShowFilters] = useState(false);
  const [rows, setRows] = useState<Data[]>([]);
  const translation = useTranslation();
  const { loading, setLoading } = useLoadingContext();

  const navigate = useNavigate();

  const [order, setOrder] = React.useState("desc");
  const [orderBy, setOrderBy] = React.useState<string>("id");
  const [selected, setSelected] = React.useState<readonly string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);

  const handleRequestSort = (
    event: React.MouseEvent<unknown>,
    property: string
  ) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleClick = (event: React.MouseEvent<unknown>, orderId: number) => {
    navigate("/customers/" + orderId);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeStatusFilter = (event: SelectChangeEvent) => {
    setStatusFilter(event.target.value);
    setPage(0);
  };

  const customersToData = (customers: any) => {
    const data = customers.map((customer: ICustomer) => {
      return {
        id: customer.id,
        name: customer.name,
        email: customer.email,
        phoneNumber: customer.phoneNumber,
      };
    });

    return data;
  };
  useEffect(() => {
    setLoading(true);

    if (tenants !== null) {
      getCustomersByTenant(tenants[selectedTenant].id, page + 1, rowsPerPage)
        .then((res) => {
          setCustomers(res.data["hydra:member"]);
          setRows(customersToData(res.data["hydra:member"]));
          setOrdersCount(res.data["hydra:totalItems"]);
          setPagesNumber(Math.ceil(res.data["hydra:totalItems"] / rowsPerPage));
          setLoading(false);
        })
        .catch((e) => setErrorMessage(errorMessage(e)));
    }
  }, [
    selectedTenant,
    tenants,
    page,
    rowsPerPage,
    orderBy,
    order,
    statusFilter,
  ]);

  return (
    <>
      <Stack sx={{ mb: 4 }}>
        <Stack direction="row" justifyContent={"space-between"}>
          <Typography variant="h4">{translation.customersTitle}</Typography>
          <Stack direction="row" spacing={2}>
            <Box>
              <Button
                variant="contained"
                startIcon={<Add />}
                onClick={() => {
                  navigate(routeNames.newCustomer);
                }}
              >
                {translation.customers.newCustomer}
              </Button>
            </Box>
          </Stack>
        </Stack>
      </Stack>
      {rows.length > 0 ? (
        <>
          <Paper sx={{ width: "100%", mb: 2 }}>
            <TableContainer>
              <Table
                sx={{ minWidth: 750 }}
                aria-labelledby="tableTitle"
                size={"medium"}
              >
                <EnhancedTableHead
                  headCells={headCells}
                  numSelected={selected.length}
                  order={order as any}
                  orderBy={orderBy}
                  onRequestSort={handleRequestSort}
                  rowCount={rows.length}
                />
                <TableBody>
                  {!loading ? (
                    rows.map((row, index) => {
                      return (
                        <TableRow
                          hover
                          onClick={(event) => handleClick(event, row.id)}
                          tabIndex={-1}
                          key={row.id}
                          style={{
                            height: 100,
                          }}
                        >
                          <TableCell>{row.name}</TableCell>
                          <TableCell>{row.email}</TableCell>
                          <TableCell>{row.phoneNumber}</TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow
                      style={{
                        height: 100 * rowsPerPage,
                      }}
                    >
                      <TableCell colSpan={6}>
                        <Stack alignItems="center">
                          <CircularProgress />
                        </Stack>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[25, 50, 100]}
              component="div"
              count={ordersCount}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              backIconButtonProps={
                loading
                  ? {
                      disabled: loading,
                    }
                  : undefined
              }
              nextIconButtonProps={
                loading
                  ? {
                      disabled: loading,
                    }
                  : undefined
              }
            />
          </Paper>
        </>
      ) : (
        !loading && <Typography>{translation.customers.noCustomers}</Typography>
      )}
    </>
  );
};

export default Customers;
