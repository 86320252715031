import React from "react";
import { Field, useField } from "formik";
import TextField from "@mui/material/TextField";
import { NumericFormat, NumericFormatProps } from "react-number-format";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
}

const NumericFormatCustom = React.forwardRef<NumericFormatProps, CustomProps>(
  function NumericFormatCustom(props, ref) {
    const { onChange, ...other } = props;

    return (
      <NumericFormat
        {...other}
        getInputRef={ref}
        onValueChange={(values) => {
          onChange({
            target: {
              name: props.name,
              value: values.value,
            },
          });
        }}
        allowNegative={false}
        decimalScale={2}
        valueIsNumericString
      />
    );
  }
);

const NumericFormatNegativeCustom = React.forwardRef<
  NumericFormatProps,
  CustomProps
>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      allowNegative={true}
      decimalScale={2}
      valueIsNumericString
    />
  );
});

const FormNumberField = ({
  label,
  type = "float",
  name,
  placeholder,
  disabled = false,
  allowNegative = false,
}: {
  label?: string;
  type: "float" | "int";
  name: string;
  placeholder?: string;
  disabled?: boolean;
  allowNegative?: boolean;
}) => {
  const [field, meta, helper] = useField(name);
  const isValid = !meta.error;
  const isInvalid = meta.touched && !isValid;
  return (
    <TextField
      onChange={(event) => {
        if (type === "float") {
          helper.setValue(parseFloat(event.target.value));
        }
        if (type === "int") {
          helper.setValue(parseInt(event.target.value));
        }
      }}
      label={label}
      placeholder={placeholder}
      disabled={disabled}
      InputProps={{
        inputComponent: allowNegative
          ? (NumericFormatNegativeCustom as any)
          : (NumericFormatCustom as any),
      }}
      variant="standard"
      value={field.value}
      error={isInvalid}
      helperText={isInvalid ? meta.error : ""}
    />
  );
};

export default FormNumberField;
