import React, { useState } from "react";
import { Field, useField } from "formik";
import TextField from "@mui/material/TextField";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import { Button, Drawer, Stack, Typography } from "@mui/material";
import { formDrawerWidth } from "../constants";
import useTranslation from "components/customHooks/translations";
import { isValidHttpUrl } from "helpers";

const AddImageUrl = ({
  title,
  multiple = false,
  showForm = false,
  handleClose,
  handleSubmitUrls,
}: {
  title?: string;
  multiple: boolean;
  showForm: boolean;
  handleClose: any;
  handleSubmitUrls: any;
}) => {
  const translation = useTranslation();
  const [imageUrls, setImageUrls] = useState("");
  return (
    <Drawer
      anchor="right"
      open={showForm !== false}
      onClose={() => {
        handleClose();
        setImageUrls("");
      }}
      sx={{
        "& .MuiDrawer-paper": { width: formDrawerWidth },
      }}
    >
      <Stack spacing={2} sx={{ p: 2 }}>
        <Typography variant="h5">
          {translation.products.uploadImageUrl}
        </Typography>
        <Typography variant="h6">{title}</Typography>
        <TextField
          variant="standard"
          multiline={multiple}
          value={imageUrls}
          minRows={multiple ? 5 : 1}
          onChange={(e) => {
            setImageUrls(e.target.value);
          }}
          label={
            multiple
              ? translation.products.imageUrlsMultiple
              : translation.products.imageUrlsSingle
          }
        ></TextField>
        <Stack direction={"row"} spacing={2} justifyContent={"space-between"}>
          <Button
            variant={"outlined"}
            onClick={() => {
              handleClose();
              setImageUrls("");
            }}
          >
            {translation.closeButton}
          </Button>
          <Button
            variant="contained"
            onClick={() => {
              handleSubmitUrls(imageUrls);
              setImageUrls("");
            }}
          >
            {translation.saveButton}
          </Button>
        </Stack>
      </Stack>
    </Drawer>
  );
};

export default AddImageUrl;
