import React, { useEffect, useState } from "react";
import useTranslation from "components/customHooks/translations";
import * as Yup from "yup";
import { Formik, FormikHelpers } from "formik";
import FormTextField from "components/Form/FormTextField/FormTextField";
import SelectField from "components/Form/SelectField";
import QuillTextField from "components/Form/QuillTextField/QuillTextField";
import DropZoneProductImg from "components/Form/DropZoneProductImg";
import RadioField from "components/Form/RadioField/RadioField";
import { useUserContext } from "components/contexts/UserContext";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  createProduct,
  getParentCategoriesByTenant,
  editProduct,
  deleteProduct,
} from "services/products";
import LoadingIcon from "components/Feedback/LoadingIcon";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  MenuItem,
  Stack,
  Button,
  CircularProgress,
  Switch,
  FormControlLabel,
  FormGroup,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import CategoryForm from "pages/Categories/CategoryForm";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { errorMessage } from "helpers";
import SwitchField from "components/Form/SwitchField/SwitchField";
import FormNumberField from "components/Form/FormNumberField";
import { ICategory, ICategoryHierarchy, IImageObject, ITax } from "interfaces";
import currencies from "helpers/currencies";
import NumberPercentageField from "components/Form/NumberPercentageField";
import DateTimePickerField from "components/Form/DateTimePickerField";
import { useNavigate } from "react-router-dom";
import { routeNames } from "routes";
import ArrayVariationsField from "components/Form/ArrayVariationsField";
import { IVariation } from "interfaces";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import InfoPopover from "components/InfoPopover";
import ArraySpecificationsField from "components/Form/ArraySpecificationsField";
import DropZoneBrandField from "components/Form/DropZoneBrandField";
import { useTenantContext } from "components/contexts/TenantContext";
interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  max: number | undefined;
}

const NumericPercentageFormatCustom = React.forwardRef<
  NumericFormatProps,
  CustomProps
>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      max={100}
      min={0}
      isAllowed={(values) => {
        const { floatValue } = values;
        if (floatValue) return floatValue <= 100;
        else return true;
      }}
      allowNegative={false}
      decimalScale={2}
      valueIsNumericString
      suffix="%"
    />
  );
});

const NumericFloatFormatCustom = React.forwardRef<
  NumericFormatProps,
  CustomProps
>(function NumericFormatCustom(props, ref) {
  const { onChange, max, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      max={max}
      allowNegative={false}
      decimalScale={2}
      valueIsNumericString
    />
  );
});

const ProductsForm = ({ productToEdit }: { productToEdit?: any }) => {
  const { tenantsIsLoading, selectedTenant, tenants } = useUserContext();
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();
  const {
    categoriesList,
    parentCategories,
    updateCategoriesTrigger,
    setUpdateCategoriesTrigger,
    categoriesLoading,
    taxes,
    taxesLoading,
  } = useTenantContext();
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [showNewCategory, setShowNewCategory] = useState(false);

  const [openDelete, setOpenDelete] = useState(false);
  const [discountFieldFocus, setDiscountFieldFocus] = useState<
    "" | "percentage" | "amount"
  >("");
  const translation = useTranslation();
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [enabledCurrencies, setEnabledCurrencies] = useState([""]);
  const [discount, setDiscount] = useState(false);
  const navigate = useNavigate();

  const handleChangeAccordeon =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const productSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, translation.signUpShortError)
      .max(255, translation.signUpLongError)
      .required(translation.required),
    category: Yup.string().required(translation.required),
    price: Yup.string().required(translation.required),
  });

  useEffect(() => {
    if (tenants !== null) {
      if (
        tenants[selectedTenant].settings.enabledCurrencies &&
        tenants[selectedTenant].settings.enabledCurrencies.length > 0
      ) {
        setEnabledCurrencies(
          tenants[selectedTenant].settings.enabledCurrencies
        );
      } else {
        setEnabledCurrencies(Object.keys(currencies));
      }
    }
  }, [selectedTenant, tenants, updateTrigger]);

  useEffect(() => {
    if (productToEdit && productToEdit.productDiscount) {
      setDiscount(true);
    }
  }, [productToEdit]);
  async function submitForm(
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldError: any,
    resetForm: any,
    setFieldValue: any,
    action: "new" | "back" | "save"
  ) {
    const netPrice = Number(values.price);
    const grossPrice = Number(values.price);
    const variations = values.variations.map((variation: IVariation) => {
      let productDiscount = null;
      if (variation.productDiscount) {
        productDiscount = {
          ...variation.productDiscount,
          percentage: variation.productDiscount.percentage
            ? Number(variation.productDiscount.percentage)
            : 0,
          netPrice: variation.productDiscount.netPrice
            ? Number(variation.productDiscount.netPrice)
            : 0,
          grossPrice: variation.productDiscount.grossPrice
            ? Number(variation.productDiscount.grossPrice)
            : 0,
        };
      }
      return {
        ...variation,

        netPrice: Number(variation.netPrice),
        grossPrice: Number(variation.grossPrice),
        stockCount: Number(variation.stockCount),
        productDiscount: productDiscount,
      };
    });
    variations.pop();
    const specifications = [...values.specifications];
    specifications.pop();
    setSubmitting(true);
    if (discount) {
      if (values.productDiscount.percentage === "") {
        setFieldError("productDiscount.netPrice", translation.required);
        setFieldError("productDiscount.grossPrice", translation.required);
        setSubmitting(false);
        return;
      }
    }
    if (!productToEdit || productToEdit.id === null) {
      createProduct({
        name: values.name,
        description: values.description,
        benefits: values.benefits,
        specifications: specifications,
        slogan: values.slogan,
        brand: values.brand,
        brandLogo: values.brandLogo,
        unitOfMeasurement: values.unitOfMeasurement,
        subcategory: values.category,
        images: values.images,
        formattedDescription: values.description,
        sku: values.sku,
        stockCount: Number(values.stockCount),
        stockLimit: Number(values.stockLimit),
        netPrice,
        grossPrice,
        priceCurrency: values.priceCurrency,
        tenant: tenants[selectedTenant]["@id"],
        isVirtual: values.isVirtual,
        draft: values.draft,
        visibility: values.visibility,
        tax: values.tax,
        weight: Number(values.weight),
        length: Number(values.length),
        width: Number(values.width),
        height: Number(values.height),
        pn: values.pn,
        barcode: values.barcode,
        otherInfo: values.otherInfo,
        hasStock: values.hasStock,
        featured: values.featured,
        cost: Number(values.cost),
        productDiscount: discount
          ? {
              isPercentage: values.productDiscount.isPercentage,
              percentage: Number(values.productDiscount.percentage),
              netPrice: Number(values.productDiscount.netPrice),
              grossPrice: Number(values.productDiscount.grossPrice),
              startDate: values.productDiscount.startDate,
              endDate: values.productDiscount.endDate,
            }
          : null,
        isVariable: values.isVariable,
        variations: values.isVariable ? variations : [],
      })
        .then((response) => {
          setSuccessMessage(translation.products.productFormSuccess);
          setSubmitting(false);
          if (action === "new") {
            setExpanded("panel1");
            resetForm();
            setFieldValue("images", []);
          }
          if (action === "back") {
            navigate(routeNames.products);
          }
          if (action === "save") {
            navigate("/edit-product/" + response.data.id);
          }
        })
        .catch((error) => {
          setErrorMessage(errorMessage(error));
          setSubmitting(false);
        });
    } else {
      editProduct({
        id: productToEdit.id,
        name: values.name,
        description: values.description,
        benefits: values.benefits,
        specifications: specifications,
        slogan: values.slogan,
        brand: values.brand,
        brandLogo: values.brandLogo,
        unitOfMeasurement: values.unitOfMeasurement,
        subcategory: values.category,
        images: values.images,
        formattedDescription: values.description,
        sku: values.sku,
        stockCount: Number(values.stockCount),
        stockLimit: Number(values.stockLimit),
        netPrice,
        grossPrice,
        priceCurrency: values.priceCurrency,
        visibility: values.visibility,
        tax: values.tax,
        isVirtual: values.isVirtual,
        draft: values.draft,
        weight: Number(values.weight),
        length: Number(values.length),
        width: Number(values.width),
        height: Number(values.height),
        pn: values.pn,
        barcode: values.barcode,
        otherInfo: values.otherInfo,
        hasStock: values.hasStock,
        featured: values.featured,
        cost: Number(values.cost),
        productDiscount: discount
          ? {
              id: productToEdit.productDiscount
                ? productToEdit.productDiscount.id
                : null,
              isPercentage: values.productDiscount.isPercentage,
              percentage: Number(values.productDiscount.percentage),
              netPrice: Number(values.productDiscount.netPrice),
              grossPrice: Number(values.productDiscount.grossPrice),
              startDate: values.productDiscount.startDate,
              endDate: values.productDiscount.endDate,
            }
          : null,
        isVariable: values.isVariable,
        variations: values.isVariable ? variations : [],
      })
        .then(() => {
          setExpanded("panel1");
          setSuccessMessage(translation.products.productFormSuccess);
          setSubmitting(false);
          if (action === "new") {
            navigate(routeNames.newProducts);
          }
          if (action === "back") {
            navigate(routeNames.products);
          }
          if (action === "save") {
            setExpanded("panel1");
          }
        })
        .catch((error) => {
          setErrorMessage(errorMessage(error));
          setSubmitting(false);
        });
    }
  }

  return (
    <>
      {tenantsIsLoading || taxesLoading ? (
        <LoadingIcon />
      ) : (
        selectedTenant != null &&
        tenants != null && (
          <>
            <Formik
              initialValues={{
                name: productToEdit ? productToEdit.name : "",
                sku: productToEdit ? productToEdit.sku : "",
                brand: productToEdit ? productToEdit.brand : "",
                brandLogo: productToEdit ? productToEdit.brandLogo : null,
                slogan: productToEdit ? productToEdit.slogan : "",
                category: productToEdit?.subcategory
                  ? productToEdit.subcategory["@id"]
                  : categoriesList.length === 1
                  ? "/categories/" + categoriesList[0].id
                  : "",
                stockCount: productToEdit ? productToEdit.stockCount : "",
                stockLimit: productToEdit ? productToEdit.stockLimit : "",
                unitOfMeasurement: productToEdit
                  ? productToEdit.unitOfMeasurement
                  : "pcs",
                price: productToEdit
                  ? tenants[selectedTenant].settings.taxIncluded
                    ? productToEdit.netPrice
                    : productToEdit.grossPrice
                  : "",
                priceCurrency: productToEdit
                  ? productToEdit.priceCurrency
                  : tenants[selectedTenant].settings.defaultCurrency,
                tax: productToEdit
                  ? productToEdit.tax["@id"]
                  : taxes.length === 1
                  ? taxes[0]["@id"]
                  : "",
                description: productToEdit ? productToEdit.description : "",
                specifications: productToEdit
                  ? productToEdit.specifications
                    ? [
                        ...productToEdit.specifications,
                        { name: "", content: "" },
                      ]
                    : [{ name: "", content: "" }]
                  : [{ name: "", content: "" }],
                benefits: productToEdit ? productToEdit.benefits : "",
                images: productToEdit
                  ? productToEdit.images.sort(
                      (a: IImageObject, b: IImageObject) => {
                        if (
                          a.priority !== undefined &&
                          b.priority !== undefined
                        )
                          return a.priority - b.priority;
                        else return 1;
                      }
                    )
                  : [],
                visibility: productToEdit
                  ? productToEdit.visibility
                  : "visible",
                draft: productToEdit ? productToEdit.draft : false,
                isVirtual: productToEdit ? productToEdit.isVirtual : false,
                weight: productToEdit ? productToEdit.weight : "",
                length: productToEdit ? productToEdit.length : "",
                width: productToEdit ? productToEdit.width : "",
                height: productToEdit ? productToEdit.height : "",
                pn: productToEdit ? productToEdit.pn : "",
                barcode: productToEdit ? productToEdit.barcode : "",
                otherInfo: productToEdit ? productToEdit.otherInfo : "",
                hasStock: productToEdit ? productToEdit.hasStock : false,
                isVariable: productToEdit ? productToEdit.isVariable : false,
                featured: productToEdit ? productToEdit.featured : false,
                cost:
                  productToEdit && productToEdit.cost ? productToEdit.cost : "",
                productDiscount: {
                  percentage:
                    productToEdit && productToEdit.productDiscount
                      ? productToEdit.productDiscount.percentage
                      : "",
                  netPrice:
                    productToEdit && productToEdit.productDiscount
                      ? productToEdit.productDiscount.netPrice
                      : "",
                  grossPrice:
                    productToEdit && productToEdit.productDiscount
                      ? productToEdit.productDiscount.grossPrice
                      : "",
                  startDate:
                    productToEdit && productToEdit.productDiscount
                      ? productToEdit.productDiscount.startDate
                      : null,
                  endDate:
                    productToEdit && productToEdit.productDiscount
                      ? productToEdit.productDiscount.endDate
                      : null,
                },
                variations: productToEdit
                  ? [
                      ...productToEdit.variations,
                      {
                        name: "",
                        sku: "",
                        stockCount: "",
                        hasDiscount: false,
                        netPrice: "",
                        grossPrice: "",
                        barcode: "",
                      },
                    ]
                  : [
                      {
                        name: "",
                        sku: "",
                        stockCount: "",
                        hadDiscount: false,
                        netPrice: "",
                        grossPrice: "",
                        barcode: "",
                      },
                    ],
              }}
              validationSchema={productSchema}
              onSubmit={(values, actions) => {
                console.log("submited");
              }}
            >
              {({
                values,
                errors,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setSubmitting,
                setFieldError,
                resetForm,
              }) => {
                return (
                  <>
                    <form noValidate className="" onSubmit={handleSubmit}>
                      <Stack spacing={2}>
                        <Accordion
                          expanded={expanded === "panel1"}
                          onChange={handleChangeAccordeon("panel1")}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography
                              sx={{
                                width: "33%",
                                flexShrink: 0,
                              }}
                            >
                              {translation.products.basic}
                            </Typography>
                            <Typography
                              sx={{
                                color: "text.secondary",
                              }}
                            >
                              {translation.products.basicDescription}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack spacing={2}>
                              <Stack
                                direction={"row"}
                                spacing={2}
                                alignItems={"flex-end"}
                              >
                                <FormTextField
                                  label={translation.products.productNameField}
                                  name="name"
                                />
                                {categoriesLoading ? (
                                  <CircularProgress></CircularProgress>
                                ) : (
                                  categoriesList && (
                                    <>
                                      <SelectField
                                        name="category"
                                        label={
                                          translation.products.categoryField
                                        }
                                      >
                                        {categoriesList
                                          ? categoriesList.map(
                                              (
                                                category: ICategoryHierarchy
                                              ) => {
                                                return (
                                                  <MenuItem
                                                    value={
                                                      "/categories/" +
                                                      category.id
                                                    }
                                                    key={
                                                      "productCategory" +
                                                      category.id
                                                    }
                                                  >
                                                    <Stack
                                                      sx={{ minHeight: "50px" }}
                                                      justifyContent={
                                                        "flex-end"
                                                      }
                                                    >
                                                      {category.parentName && (
                                                        <Typography
                                                          sx={{
                                                            color:
                                                              "primary.dark",
                                                            fontSize: "10px",
                                                            fontWeight: 500,
                                                          }}
                                                        >
                                                          {category.parentName}
                                                        </Typography>
                                                      )}
                                                      <Typography>
                                                        {category.name}
                                                      </Typography>
                                                    </Stack>
                                                  </MenuItem>
                                                );
                                              }
                                            )
                                          : ""}
                                      </SelectField>
                                      <Button
                                        variant={"contained"}
                                        startIcon={<Add />}
                                        onClick={() =>
                                          setShowNewCategory(!showNewCategory)
                                        }
                                      >
                                        {translation.newCategory}
                                      </Button>
                                    </>
                                  )
                                )}
                              </Stack>
                              <Stack
                                direction={"row"}
                                spacing={2}
                                alignItems="flex-end
                              "
                              >
                                <FormNumberField
                                  type="float"
                                  label={translation.products.priceField}
                                  name="price"
                                />

                                <SelectField
                                  name="priceCurrency"
                                  shrink={productToEdit ? true : undefined}
                                  label={
                                    translation.products.priceCurrencyField
                                  }
                                >
                                  {enabledCurrencies.map((currency) => {
                                    return (
                                      <MenuItem
                                        value={currency}
                                        key={"default-" + currency}
                                      >
                                        {currency}
                                      </MenuItem>
                                    );
                                  })}
                                </SelectField>

                                {taxesLoading ? (
                                  <CircularProgress></CircularProgress>
                                ) : taxes.length > 0 ? (
                                  <SelectField
                                    name="tax"
                                    label={
                                      translation.products.taxPercentageField
                                    }
                                  >
                                    {taxes.map((tax: ITax) => {
                                      return (
                                        <MenuItem
                                          value={"/taxes/" + tax.id}
                                          key={"productTax" + tax.id}
                                        >
                                          {tax.taxPercentage + "%"}
                                        </MenuItem>
                                      );
                                    })}
                                  </SelectField>
                                ) : (
                                  <Button
                                    variant="outlined"
                                    color={"error"}
                                    onClick={() => {
                                      navigate(routeNames.generalCustomOptions);
                                    }}
                                  >
                                    {translation.products.setTaxes}
                                  </Button>
                                )}
                              </Stack>
                              <Box>
                                <Box sx={{ my: 4 }}>
                                  <DropZoneProductImg
                                    name="images"
                                    label={translation.products.imagesField}
                                    labelDescription={
                                      translation.products
                                        .imagesDescriptionField
                                    }
                                    productName={values.name}
                                  ></DropZoneProductImg>
                                </Box>
                              </Box>
                              <RadioField
                                name="visibility"
                                label={translation.products.visibilityField}
                                options={[
                                  {
                                    value: "visible",
                                    label: "Visible",
                                  },
                                  {
                                    value: "hidden",
                                    label: "Hidden from catalog",
                                  },
                                  {
                                    value: "gone",
                                    label: "Hidden",
                                  },
                                ]}
                              ></RadioField>
                              <SwitchField
                                name="draft"
                                label={translation.products.isDraft}
                              ></SwitchField>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel2"}
                          onChange={handleChangeAccordeon("panel2")}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                          >
                            <Typography
                              sx={{
                                width: "33%",
                                flexShrink: 0,
                              }}
                            >
                              {translation.products.description}
                            </Typography>
                            <Typography
                              sx={{
                                color: "text.secondary",
                              }}
                            >
                              {translation.products.descriptionDescription}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack spacing={3}>
                              <Stack direction={"row"} spacing={2}>
                                <Box>
                                  <FormTextField
                                    label={translation.products.brandField}
                                    name="brand"
                                  />
                                </Box>
                                <Box>
                                  <FormTextField
                                    label={translation.products.sloganField}
                                    name="slogan"
                                  />
                                </Box>
                              </Stack>
                              <Box sx={{ width: 500 }}>
                                <DropZoneBrandField
                                  name="brandLogo"
                                  label={translation.products.brandLogoField}
                                  labelDescription={
                                    translation.products.brandLogoDescription
                                  }
                                ></DropZoneBrandField>
                              </Box>
                              <Typography>
                                {translation.products.descriptionField}
                              </Typography>
                              <QuillTextField name="description" />
                              <Typography>
                                {translation.products.benefitsField}
                              </Typography>
                              <QuillTextField name="benefits" />

                              {/* <Typography>
                                {translation.products.specificationsLabel}
                              </Typography> */}
                              <ArraySpecificationsField
                                label={translation.products.specificationsLabel}
                                name="specifications"
                              />
                              <Divider sx={{ my: 4 }} />
                              {!productToEdit?.isVariable && (
                                <>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={discount}
                                          onChange={() =>
                                            setDiscount(!discount)
                                          }
                                        ></Switch>
                                      }
                                      label={translation.products.discoutLabel}
                                    />
                                  </FormGroup>

                                  {discount && (
                                    <Stack spacing={2}>
                                      <Box>
                                        <TextField
                                          variant="standard"
                                          value={
                                            values.productDiscount.percentage
                                          }
                                          onFocus={() =>
                                            setDiscountFieldFocus("percentage")
                                          }
                                          onBlur={() =>
                                            setDiscountFieldFocus("")
                                          }
                                          onChange={(e) => {
                                            if (e.target.value == "") {
                                              setFieldValue(
                                                "productDiscount.percentage",
                                                ""
                                              );
                                              setFieldValue(
                                                tenants[selectedTenant].settings
                                                  .taxIncluded
                                                  ? "productDiscount.netPrice"
                                                  : "productDiscount.grossPrice",
                                                ""
                                              );
                                              return;
                                            }
                                            const discountPercentage =
                                              parseFloat(e.target.value);
                                            setFieldValue(
                                              "productDiscount.percentage",
                                              discountPercentage
                                            );
                                            if (
                                              discountFieldFocus == "percentage"
                                            ) {
                                              setFieldValue(
                                                tenants[selectedTenant].settings
                                                  .taxIncluded
                                                  ? "productDiscount.netPrice"
                                                  : "productDiscount.grossPrice",
                                                values.price -
                                                  (values.price *
                                                    discountPercentage) /
                                                    100
                                              );
                                            }
                                          }}
                                          sx={{ width: 60 }}
                                          placeholder="%"
                                          error={
                                            tenants[selectedTenant].settings
                                              .taxIncluded
                                              ? errors.productDiscount
                                                ? !!errors.productDiscount
                                                    .netPrice
                                                : false
                                              : errors.productDiscount
                                              ? !!errors.productDiscount
                                                  .grossPrice
                                              : false
                                          }
                                          InputProps={{
                                            inputComponent:
                                              NumericPercentageFormatCustom as any,
                                          }}
                                        />
                                      </Box>

                                      <Box>
                                        <TextField
                                          onChange={(event) => {
                                            if (event.target.value == "") {
                                              setFieldValue(
                                                tenants[selectedTenant].settings
                                                  .taxIncluded
                                                  ? "productDiscount.netPrice"
                                                  : "productDiscount.grossPrice",
                                                ""
                                              );
                                              setFieldValue(
                                                "productDiscount.percentage",
                                                ""
                                              );
                                              return;
                                            }
                                            let salePrice = parseFloat(
                                              event.target.value
                                            );
                                            if (salePrice > values.price) {
                                              salePrice = values.price;
                                            }
                                            setFieldValue(
                                              tenants[selectedTenant].settings
                                                .taxIncluded
                                                ? "productDiscount.netPrice"
                                                : "productDiscount.grossPrice",
                                              salePrice
                                            );
                                            setFieldValue(
                                              "productDiscount.percentage",
                                              ((values.price - salePrice) /
                                                values.price) *
                                                100
                                            );
                                          }}
                                          label={
                                            translation.products
                                              .productDiscountPriceField
                                          }
                                          InputProps={{
                                            inputComponent:
                                              NumericFloatFormatCustom as any,
                                          }}
                                          variant="standard"
                                          value={
                                            tenants[selectedTenant].settings
                                              .taxIncluded
                                              ? values.productDiscount.netPrice
                                              : values.productDiscount
                                                  .grossPrice
                                          }
                                          error={
                                            tenants[selectedTenant].settings
                                              .taxIncluded
                                              ? errors.productDiscount
                                                ? !!errors.productDiscount
                                                    .netPrice
                                                : false
                                              : errors.productDiscount
                                              ? !!errors.productDiscount
                                                  .grossPrice
                                              : false
                                          }
                                        />
                                      </Box>

                                      <Box>
                                        <DateTimePickerField
                                          name="productDiscount.startDate"
                                          label={
                                            translation.products
                                              .discountStartDateField
                                          }
                                        ></DateTimePickerField>
                                      </Box>
                                      <Box>
                                        <DateTimePickerField
                                          name="productDiscount.endDate"
                                          label={
                                            translation.products
                                              .discountEndDateField
                                          }
                                        ></DateTimePickerField>
                                      </Box>
                                    </Stack>
                                  )}
                                </>
                              )}

                              <SwitchField
                                name="featured"
                                label={translation.products.featured}
                              ></SwitchField>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>

                        <Accordion
                          expanded={expanded === "panel3"}
                          onChange={handleChangeAccordeon("panel3")}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel4bh-content"
                            id="panel4bh-header"
                          >
                            <Typography
                              sx={{
                                width: "33%",
                                flexShrink: 0,
                              }}
                            >
                              {translation.products.inventory}
                            </Typography>
                            <Typography
                              sx={{
                                color: "text.secondary",
                              }}
                            >
                              {translation.products.inventoryDescription}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack spacing={2}>
                              <Stack
                                direction={"row"}
                                flexWrap={"wrap"}
                                spacing={2}
                              >
                                <FormTextField
                                  label={translation.products.skuField}
                                  name="sku"
                                />
                                <FormTextField
                                  label={translation.products.pnLabel}
                                  name="pn"
                                />
                                <FormTextField
                                  label={translation.products.barcodeLabel}
                                  name="barcode"
                                />
                                <FormTextField
                                  label={translation.products.otherInfoLabel}
                                  name="otherInfo"
                                />
                              </Stack>
                              <SwitchField
                                name="hasStock"
                                label={translation.products.hasStockLabel}
                              ></SwitchField>
                              {values.isVariable == false && (
                                <Stack spacing={2}>
                                  <Box>
                                    <FormNumberField
                                      type="int"
                                      label={
                                        translation.products.stockCountField
                                      }
                                      disabled={!values.hasStock}
                                      name="stockCount"
                                    />
                                  </Box>
                                  <Stack direction={"row"} spacing={2}>
                                    <FormNumberField
                                      type="int"
                                      label={
                                        translation.products.stockLimitField
                                      }
                                      name="stockLimit"
                                    />
                                    <InfoPopover
                                      infoContent={
                                        translation.products
                                          .stockLimitDescription
                                      }
                                    ></InfoPopover>
                                  </Stack>
                                </Stack>
                              )}
                              <SwitchField
                                name="isVariable"
                                label={translation.products.isVariableField}
                              ></SwitchField>
                              {values.isVariable && (
                                <Box>
                                  <Box>
                                    <ArrayVariationsField name="variations" />
                                  </Box>
                                </Box>
                              )}
                              <Box>
                                <FormNumberField
                                  type="float"
                                  label={translation.products.costLabel}
                                  name="cost"
                                />
                              </Box>

                              <Box>
                                <FormTextField
                                  label={
                                    translation.products.unitOfMeasurementField
                                  }
                                  name="unitOfMeasurement"
                                />
                              </Box>
                              <SwitchField
                                name="isVirtual"
                                label={translation.products.isVirtualLabel}
                              ></SwitchField>
                              {!values.isVirtual && (
                                <Stack
                                  spacing={2}
                                  direction="row"
                                  flexWrap={"wrap"}
                                >
                                  <FormNumberField
                                    type="int"
                                    name="weight"
                                    label={translation.products.weightLabel}
                                  />
                                  <FormNumberField
                                    type="int"
                                    name="length"
                                    label={translation.products.lengthLabel}
                                  />
                                  <FormNumberField
                                    type="int"
                                    name="width"
                                    label={translation.products.widthLabel}
                                  />
                                  <FormNumberField
                                    type="int"
                                    name="height"
                                    label={translation.products.heightLabel}
                                  />
                                </Stack>
                              )}
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Stack direction={"row"} spacing={2}>
                            <LoadingButton
                              variant="contained"
                              disabled={!(taxes.length > 0)}
                              loading={isSubmitting}
                              onClick={() =>
                                submitForm(
                                  values,
                                  setSubmitting,
                                  setFieldError,
                                  resetForm,
                                  setFieldValue,
                                  "back"
                                )
                              }
                            >
                              {translation.saveAndBackButton}
                            </LoadingButton>
                            <LoadingButton
                              variant="outlined"
                              disabled={!(taxes.length > 0)}
                              loading={isSubmitting}
                              onClick={() =>
                                submitForm(
                                  values,
                                  setSubmitting,
                                  setFieldError,
                                  resetForm,
                                  setFieldValue,
                                  "save"
                                )
                              }
                            >
                              {translation.saveButton}
                            </LoadingButton>

                            <LoadingButton
                              variant="outlined"
                              disabled={!(taxes.length > 0)}
                              loading={isSubmitting}
                              onClick={() =>
                                submitForm(
                                  values,
                                  setSubmitting,
                                  setFieldError,
                                  resetForm,
                                  setFieldValue,
                                  "new"
                                )
                              }
                            >
                              {translation.saveAndNewButton}
                            </LoadingButton>
                          </Stack>
                          {productToEdit ? (
                            productToEdit.id ? (
                              <LoadingButton
                                variant="outlined"
                                color="warning"
                                loading={isSubmitting}
                                onClick={() => {
                                  setOpenDelete(true);
                                }}
                              >
                                {translation.deleteButton}
                              </LoadingButton>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </Stack>
                      </Stack>
                    </form>
                    <CategoryForm
                      showNewCategory={showNewCategory}
                      setShowNewCategory={setShowNewCategory}
                      parentCategories={parentCategories}
                      updateTrigger={updateCategoriesTrigger}
                      setUpdateTrigger={setUpdateCategoriesTrigger}
                      setNewCategory={(newCategory: string) => {
                        setFieldValue("category", newCategory);
                      }}
                    ></CategoryForm>
                  </>
                );
              }}
            </Formik>{" "}
            <Dialog
              open={openDelete}
              onClose={() => setOpenDelete(false)}
              aria-labelledby="alert-dialog-title-confirm-delete"
              aria-describedby="alert-dialog-confirm-delete"
            >
              <DialogTitle id="alert-dialog-title-confirm-delete">
                {translation.products.deleteProduct}
              </DialogTitle>
              <DialogContent>
                <Typography>
                  {translation.products.deleteProductMessage}
                </Typography>
                <Typography fontWeight={700}>{productToEdit?.name}</Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  variant={"outlined"}
                  onClick={() => setOpenDelete(false)}
                >
                  {translation.closeButton}
                </Button>
                <Button
                  variant={"contained"}
                  onClick={() => {
                    if (productToEdit) {
                      deleteProduct(productToEdit)
                        .then(() => {
                          setOpenDelete(false);
                          setUpdateTrigger(
                            (updateTrigger: any) => !updateTrigger
                          );
                          setSuccessMessage(
                            translation.products.deletedProduct
                          );

                          navigate(routeNames.products);
                        })
                        .catch((e) => {
                          setOpenDelete(false);
                          setErrorMessage(e.response.data["hydra:description"]);
                        });
                    }
                  }}
                >
                  {translation.confirmDelete}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )
      )}
    </>
  );
};

export default ProductsForm;
