import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import * as Yup from "yup";
import useTranslation from "components/customHooks/translations";
import FormTextField from "components/Form/FormTextField/FormTextField";
import SelectField from "components/Form/SelectField";
import { Formik } from "formik";
import { createSubsCategory, updateSubsCategory } from "services/products";
import {
  Box,
  Button,
  Drawer,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import SwitchField from "components/Form/SwitchField/SwitchField";
import { formDrawerWidth } from "components/Form/constants";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { ISubsCategory } from "interfaces";
import { errorMessage } from "helpers";

const SubsCategoryForm = ({
  showNewSubsCategory,
  setShowNewSubsCategory,
  subsCategoryToEdit,
  setSubsCategoryToEdit,
  updateTrigger,
  setUpdateTrigger,
  setNewSubsCategory,
}: {
  showNewSubsCategory: boolean;
  setShowNewSubsCategory: React.Dispatch<React.SetStateAction<boolean>>;
  subsCategoryToEdit?: ISubsCategory | null;
  setSubsCategoryToEdit?: React.Dispatch<
    React.SetStateAction<ISubsCategory | null>
  >;
  updateTrigger: boolean;
  setUpdateTrigger: React.Dispatch<React.SetStateAction<boolean>>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  setNewSubsCategory?: any;
}) => {
  const { selectedTenant, tenants } = useUserContext();
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();

  const translation = useTranslation();

  const subsCategorySchema = Yup.object().shape({
    name: Yup.string()
      .min(2, translation.signUpShortError)
      .max(50, translation.signUpLongError)
      .required(translation.required),
  });

  return (
    <>
      <Drawer
        anchor="right"
        open={showNewSubsCategory}
        onClose={() => {
          setShowNewSubsCategory(false);
          if (setSubsCategoryToEdit) {
            setSubsCategoryToEdit(null);
          }
        }}
        sx={{
          "& .MuiDrawer-paper": { width: formDrawerWidth },
        }}
      >
        <Formik
          initialValues={{
            name: subsCategoryToEdit ? subsCategoryToEdit.name : "",
          }}
          validationSchema={subsCategorySchema}
          enableReinitialize
          onSubmit={(values, { setSubmitting }) => {
            setSubmitting(true);
            if (subsCategoryToEdit) {
              updateSubsCategory({
                ...subsCategoryToEdit,
                name: values.name,
              })
                .then(() => {
                  setSubmitting(false);
                  setShowNewSubsCategory(false);
                  if (setSubsCategoryToEdit) {
                    setSubsCategoryToEdit(null);
                  }
                  setSuccessMessage(translation.savedMessage);
                  setUpdateTrigger(!updateTrigger);
                })
                .catch((e) => {
                  setErrorMessage(errorMessage(e));
                  setSubmitting(false);
                });
            } else {
              createSubsCategory(values.name, tenants[selectedTenant].id)
                .then((res) => {
                  setSubmitting(false);
                  setShowNewSubsCategory(false);
                  if (setSubsCategoryToEdit) {
                    setSubsCategoryToEdit(null);
                  }
                  setSuccessMessage(translation.savedMessage);
                  setUpdateTrigger(!updateTrigger);
                  if (setNewSubsCategory) {
                    setNewSubsCategory(res.data["@id"]);
                  }
                })
                .catch((e) => {
                  setErrorMessage(errorMessage(e));
                  setSubmitting(false);
                });
            }
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <form noValidate className="" onSubmit={handleSubmit}>
              <Box sx={{ p: 4 }}>
                <Typography variant="h5">
                  {subsCategoryToEdit
                    ? translation.subscriptions.newSubsCategory
                    : translation.subscriptions.editSubsCategory}
                </Typography>
                <Stack spacing={2}>
                  <FormTextField label={translation.categoryName} name="name" />

                  <Stack
                    direction={"row"}
                    spacing={2}
                    justifyContent={"space-between"}
                  >
                    <Button
                      variant={"outlined"}
                      onClick={() => {
                        setShowNewSubsCategory(false);
                        if (setSubsCategoryToEdit) {
                          setSubsCategoryToEdit(null);
                        }
                      }}
                    >
                      {translation.closeButton}
                    </Button>
                    <Button
                      variant="contained"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      {translation.saveButton}
                    </Button>
                  </Stack>
                </Stack>
              </Box>
            </form>
          )}
        </Formik>
      </Drawer>
    </>
  );
};

export default SubsCategoryForm;
