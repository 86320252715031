import React, { useEffect, useState } from "react";
import useTranslation from "components/customHooks/translations";
import * as Yup from "yup";
import { Formik, FormikHelpers } from "formik";
import FormTextField from "components/Form/FormTextField/FormTextField";
import SelectField from "components/Form/SelectField";
import QuillTextField from "components/Form/QuillTextField/QuillTextField";
import DropZoneProductImg from "components/Form/DropZoneProductImg";
import RadioField from "components/Form/RadioField/RadioField";
import { useUserContext } from "components/contexts/UserContext";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  createSubscription,
  getSubsCategoriesByTenant,
  editSubscription,
  deleteSubscription,
} from "services/products";
import LoadingIcon from "components/Feedback/LoadingIcon";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Box,
  MenuItem,
  Stack,
  Button,
  CircularProgress,
  FormGroup,
  FormControlLabel,
  Switch,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { errorMessage } from "helpers";
import SwitchField from "components/Form/SwitchField/SwitchField";
import FormNumberField from "components/Form/FormNumberField";
import {
  ICategory,
  ICategoryHierarchy,
  IImageObject,
  ISubsCategory,
  ITax,
} from "interfaces";
import { getTaxesByTenant } from "services/tenants";
import currencies from "helpers/currencies";
import { useNavigate } from "react-router-dom";
import { routeNames } from "routes";
import { IVariation } from "interfaces";
import { NumericFormat, NumericFormatProps } from "react-number-format";
import InfoPopover from "components/InfoPopover";
import SubsCategoryForm from "pages/SubsCategory/SubsCategoryForm";
import NumberPercentageField from "components/Form/NumberPercentageField";
import { useTenantContext } from "components/contexts/TenantContext";

interface CustomProps {
  onChange: (event: { target: { name: string; value: string } }) => void;
  name: string;
  max: number | undefined;
}

const NumericPercentageFormatCustom = React.forwardRef<
  NumericFormatProps,
  CustomProps
>(function NumericFormatCustom(props, ref) {
  const { onChange, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      max={100}
      min={0}
      isAllowed={(values) => {
        const { floatValue } = values;
        if (floatValue) return floatValue <= 100;
        else return true;
      }}
      allowNegative={false}
      decimalScale={2}
      valueIsNumericString
      suffix="%"
    />
  );
});

const NumericFloatFormatCustom = React.forwardRef<
  NumericFormatProps,
  CustomProps
>(function NumericFormatCustom(props, ref) {
  const { onChange, max, ...other } = props;

  return (
    <NumericFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      max={max}
      allowNegative={false}
      decimalScale={2}
      valueIsNumericString
    />
  );
});

const SubscriptionsForm = ({
  subscriptionToEdit,
}: {
  subscriptionToEdit?: any;
}) => {
  const { taxes, taxesLoading } = useTenantContext();
  const { tenantsIsLoading, selectedTenant, tenants } = useUserContext();
  const { setErrorMessage, setSuccessMessage } = useSnackbarContext();

  const [categories, setCategories] = useState<ICategory[]>([]);
  const [updateTrigger, setUpdateTrigger] = useState(false);
  const [categoriesLoading, setCategoriesLoading] = useState(true);
  const [openDelete, setOpenDelete] = useState(false);
  const [prepaidDiscount, setPrepaidDiscount] = useState(false);
  const [showNewSubsCategory, setShowNewSubsCategory] = useState(false);
  const translation = useTranslation();
  const [expanded, setExpanded] = React.useState<string | false>("panel1");
  const [enabledCurrencies, setEnabledCurrencies] = useState([""]);
  const [discount, setDiscount] = useState(false);
  const navigate = useNavigate();
  const handleChangeAccordeon =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const subscriptionSchema = Yup.object().shape({
    name: Yup.string()
      .min(2, translation.signUpShortError)
      .max(255, translation.signUpLongError)
      .required(translation.required),
    type: Yup.string().required(translation.required),
    price: Yup.string().required(translation.required),
  });

  useEffect(() => {
    console.log(subscriptionToEdit);
    setCategoriesLoading(true);
    if (tenants !== null) {
      getSubsCategoriesByTenant(tenants[selectedTenant].id)
        .then((res) => {
          setCategories(res.data["hydra:member"]);
          setCategoriesLoading(false);
        })
        .catch((e: any) => console.log(e));

      if (
        tenants[selectedTenant].settings.enabledCurrencies &&
        tenants[selectedTenant].settings.enabledCurrencies.length > 0
      ) {
        setEnabledCurrencies(
          tenants[selectedTenant].settings.enabledCurrencies
        );
      } else {
        setEnabledCurrencies(Object.keys(currencies));
      }
    }
  }, [selectedTenant, tenants, updateTrigger]);

  useEffect(() => {
    if (subscriptionToEdit) {
      if (
        subscriptionToEdit.qDiscount != null ||
        subscriptionToEdit.yDiscount != null
      ) {
        setPrepaidDiscount(true);
      }
    }
  }, [subscriptionToEdit]);

  async function submitForm(
    values: any,
    setSubmitting: (isSubmitting: boolean) => void,
    setFieldError: any,
    resetForm: any,
    setFieldValue: any,
    action: "new" | "back" | "save"
  ) {
    const netPrice = Number(values.price);
    const grossPrice = Number(values.price);

    setSubmitting(true);

    if (!subscriptionToEdit || subscriptionToEdit.id === null) {
      createSubscription({
        name: values.name,
        description: values.description,
        slogan: values.slogan,
        brand: values.brand,
        unitOfMeasurement: values.unitOfMeasurement,
        type: values.type,
        images: values.images,
        sku: values.sku,
        netPrice,
        grossPrice,
        priceCurrency: values.priceCurrency,
        tenant: tenants[selectedTenant]["@id"],
        tax: values.tax,
        frequency: values.frequency,
        qDiscount: Number(values.qDiscount),
        yDiscount: Number(values.yDiscount),
      })
        .then((response: any) => {
          setSuccessMessage(translation.savedMessage);
          setSubmitting(false);
          if (action === "new") {
            setExpanded("panel1");
            resetForm();
            setFieldValue("images", []);
          }
          if (action === "back") {
            navigate(routeNames.subscriptions);
          }
          if (action === "save") {
            navigate("/edit-subscription/" + response.data.id);
          }
        })
        .catch((error: any) => {
          setErrorMessage(errorMessage(error));
          setSubmitting(false);
        });
    } else {
      editSubscription({
        id: subscriptionToEdit.id,
        name: values.name,
        description: values.description,
        slogan: values.slogan,
        brand: values.brand,
        unitOfMeasurement: values.unitOfMeasurement,
        type: values.type,
        images: values.images,
        sku: values.sku,
        netPrice,
        grossPrice,
        priceCurrency: values.priceCurrency,
        tenant: tenants[selectedTenant]["@id"],
        tax: values.tax,
        frequency: values.frequency,
        qDiscount: Number(values.qDiscount),
        yDiscount: Number(values.yDiscount),
      })
        .then(() => {
          setExpanded("panel1");
          setSuccessMessage(translation.savedMessage);
          setSubmitting(false);
          if (action === "new") {
            navigate(routeNames.newSubscriptions);
          }
          if (action === "back") {
            navigate(routeNames.subscriptions);
          }
          if (action === "save") {
            setExpanded("panel1");
          }
        })
        .catch((error: any) => {
          setErrorMessage(errorMessage(error));
          setSubmitting(false);
        });
    }
  }
  console.log(subscriptionToEdit);

  return (
    <>
      {tenantsIsLoading || taxesLoading ? (
        <LoadingIcon />
      ) : (
        selectedTenant != null &&
        tenants != null && (
          <>
            <Formik
              initialValues={{
                name: subscriptionToEdit ? subscriptionToEdit.name : "",
                sku: subscriptionToEdit ? subscriptionToEdit.sku : "",
                brand: subscriptionToEdit ? subscriptionToEdit.brand : "",
                slogan: subscriptionToEdit ? subscriptionToEdit.slogan : "",
                type: subscriptionToEdit?.type
                  ? subscriptionToEdit.type["@id"]
                  : categories.length === 1
                  ? "/subs_categories/" + categories[0].id
                  : "",
                price: subscriptionToEdit
                  ? tenants[selectedTenant].settings.taxIncluded
                    ? subscriptionToEdit.netPrice
                    : subscriptionToEdit.grossPrice
                  : "",
                priceCurrency: subscriptionToEdit
                  ? subscriptionToEdit.priceCurrency
                  : tenants[selectedTenant].settings.defaultCurrency,
                tax: subscriptionToEdit
                  ? subscriptionToEdit.tax
                  : taxes.length === 1
                  ? taxes[0]["@id"]
                  : "",
                description: subscriptionToEdit
                  ? subscriptionToEdit.description
                  : "",
                images: subscriptionToEdit
                  ? subscriptionToEdit.images.sort(
                      (a: IImageObject, b: IImageObject) => {
                        if (
                          a.priority !== undefined &&
                          b.priority !== undefined
                        )
                          return a.priority - b.priority;
                        else return 1;
                      }
                    )
                  : [],
                visibility: subscriptionToEdit
                  ? subscriptionToEdit.visibility
                  : "visible",

                otherInfo: subscriptionToEdit
                  ? subscriptionToEdit.otherInfo
                  : "",
                frequency: subscriptionToEdit
                  ? subscriptionToEdit.frequency
                  : [],
                yDiscount: subscriptionToEdit
                  ? subscriptionToEdit.yDiscount
                  : "",
                qDiscount: subscriptionToEdit
                  ? subscriptionToEdit.qDiscount
                  : "",
              }}
              validationSchema={subscriptionSchema}
              onSubmit={(values, actions) => {
                console.log("submited");
              }}
            >
              {({
                values,
                errors,
                handleSubmit,
                isSubmitting,
                setFieldValue,
                setSubmitting,
                setFieldError,
                resetForm,
              }) => {
                return (
                  <>
                    <form noValidate className="" onSubmit={handleSubmit}>
                      <Stack spacing={2}>
                        <Accordion
                          expanded={expanded === "panel1"}
                          onChange={handleChangeAccordeon("panel1")}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                          >
                            <Typography
                              sx={{
                                width: "33%",
                                flexShrink: 0,
                              }}
                            >
                              {translation.subscriptions.basic}
                            </Typography>
                            <Typography
                              sx={{
                                color: "text.secondary",
                              }}
                            >
                              {translation.subscriptions.basicDescription}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack spacing={2}>
                              <Stack
                                direction={"row"}
                                spacing={2}
                                alignItems={"flex-end"}
                              >
                                <FormTextField
                                  label={
                                    translation.subscriptions
                                      .subscriptionNameField
                                  }
                                  name="name"
                                />
                                {categoriesLoading ? (
                                  <CircularProgress></CircularProgress>
                                ) : (
                                  categories && (
                                    <>
                                      <SelectField
                                        name="type"
                                        label={
                                          translation.subscriptions
                                            .categoryField
                                        }
                                      >
                                        {categories
                                          ? categories.map(
                                              (subsCategory: ISubsCategory) => {
                                                return (
                                                  <MenuItem
                                                    value={
                                                      "/subs_categories/" +
                                                      subsCategory.id
                                                    }
                                                    key={
                                                      "subscriptionCategory" +
                                                      subsCategory.id
                                                    }
                                                  >
                                                    <Typography>
                                                      {subsCategory.name}
                                                    </Typography>
                                                  </MenuItem>
                                                );
                                              }
                                            )
                                          : ""}
                                      </SelectField>
                                      <Button
                                        variant={"contained"}
                                        startIcon={<Add />}
                                        onClick={() =>
                                          setShowNewSubsCategory(
                                            !showNewSubsCategory
                                          )
                                        }
                                      >
                                        {
                                          translation.subscriptions
                                            .newSubsCategory
                                        }
                                      </Button>
                                    </>
                                  )
                                )}
                              </Stack>
                              <Stack
                                direction={"row"}
                                spacing={2}
                                alignItems="flex-end
                              "
                              >
                                <FormNumberField
                                  type="float"
                                  label={translation.subscriptions.priceField}
                                  name="price"
                                />

                                <SelectField
                                  name="priceCurrency"
                                  shrink={subscriptionToEdit ? true : undefined}
                                  label={
                                    translation.subscriptions.priceCurrencyField
                                  }
                                >
                                  {enabledCurrencies.map((currency) => {
                                    return (
                                      <MenuItem
                                        value={currency}
                                        key={"default-" + currency}
                                      >
                                        {currency}
                                      </MenuItem>
                                    );
                                  })}
                                </SelectField>

                                {taxesLoading ? (
                                  <CircularProgress></CircularProgress>
                                ) : taxes.length > 0 ? (
                                  <SelectField
                                    name="tax"
                                    label={
                                      translation.subscriptions
                                        .taxPercentageField
                                    }
                                  >
                                    {taxes.map((tax: ITax) => {
                                      return (
                                        <MenuItem
                                          value={"/taxes/" + tax.id}
                                          key={"subscriptionTax" + tax.id}
                                        >
                                          {tax.taxPercentage + "%"}
                                        </MenuItem>
                                      );
                                    })}
                                  </SelectField>
                                ) : (
                                  <Button
                                    variant="outlined"
                                    color={"error"}
                                    onClick={() => {
                                      navigate(routeNames.generalCustomOptions);
                                    }}
                                  >
                                    {translation.subscriptions.setTaxes}
                                  </Button>
                                )}
                              </Stack>
                              <Stack direction={"row"} spacing={2}>
                                <FormTextField
                                  label={translation.products.skuField}
                                  name="sku"
                                />
                                <SelectField
                                  name="frequency"
                                  label={
                                    translation.subscriptions.frequenciesLabel
                                  }
                                  multiple={true}
                                >
                                  <MenuItem value={"monthly"}>
                                    {translation.subscriptions.monthly}
                                  </MenuItem>
                                  <MenuItem value={"quarterly"}>
                                    {translation.subscriptions.quarterly}
                                  </MenuItem>
                                  <MenuItem value={"yearly"}>
                                    {translation.subscriptions.yearly}
                                  </MenuItem>
                                </SelectField>
                              </Stack>
                              {(values.frequency.includes("quarterly") ||
                                values.frequency.includes("yearly")) && (
                                <>
                                  <FormGroup>
                                    <FormControlLabel
                                      control={
                                        <Switch
                                          checked={prepaidDiscount}
                                          onChange={(
                                            event: React.ChangeEvent<HTMLInputElement>
                                          ) => {
                                            setPrepaidDiscount(
                                              event.target.checked
                                            );
                                          }}
                                        />
                                      }
                                      label={
                                        translation.subscriptions
                                          .prepaidDiscount
                                      }
                                    />
                                  </FormGroup>
                                </>
                              )}
                              {prepaidDiscount && (
                                <>
                                  {values.frequency.includes("quarterly") && (
                                    <NumberPercentageField
                                      name="qDiscount"
                                      label={
                                        translation.subscriptions.quarterly
                                      }
                                    ></NumberPercentageField>
                                  )}
                                  {values.frequency.includes("yearly") && (
                                    <NumberPercentageField
                                      name="yDiscount"
                                      label={translation.subscriptions.yearly}
                                    ></NumberPercentageField>
                                  )}
                                </>
                              )}
                              <Box>
                                <Box sx={{ my: 4 }}>
                                  <DropZoneProductImg
                                    name="images"
                                    label={
                                      translation.subscriptions.imagesField
                                    }
                                    labelDescription={
                                      translation.subscriptions
                                        .imagesDescriptionField
                                    }
                                  ></DropZoneProductImg>
                                </Box>
                              </Box>
                              <RadioField
                                name="visibility"
                                label={
                                  translation.subscriptions.visibilityField
                                }
                                options={[
                                  {
                                    value: "visible",
                                    label: "Visible",
                                  },
                                  {
                                    value: "hidden",
                                    label: "Hidden from catalog",
                                  },
                                  {
                                    value: "gone",
                                    label: "Hidden",
                                  },
                                ]}
                              ></RadioField>
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                        <Accordion
                          expanded={expanded === "panel2"}
                          onChange={handleChangeAccordeon("panel2")}
                        >
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel2bh-content"
                            id="panel2bh-header"
                          >
                            <Typography
                              sx={{
                                width: "33%",
                                flexShrink: 0,
                              }}
                            >
                              {translation.subscriptions.description}
                            </Typography>
                            <Typography
                              sx={{
                                color: "text.secondary",
                              }}
                            >
                              {translation.subscriptions.descriptionDescription}
                            </Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <Stack spacing={3}>
                              <Stack direction={"row"} spacing={2}>
                                <Box>
                                  <FormTextField
                                    label={translation.subscriptions.brandField}
                                    name="brand"
                                  />
                                </Box>
                                <Box>
                                  <FormTextField
                                    label={
                                      translation.subscriptions.sloganField
                                    }
                                    name="slogan"
                                  />
                                </Box>
                              </Stack>
                              <Typography>
                                {translation.subscriptions.descriptionField}
                              </Typography>
                              <QuillTextField name="description" />
                            </Stack>
                          </AccordionDetails>
                        </Accordion>
                        <Stack
                          direction={"row"}
                          justifyContent={"space-between"}
                        >
                          <Stack direction={"row"} spacing={2}>
                            <LoadingButton
                              variant="contained"
                              disabled={!(taxes.length > 0)}
                              loading={isSubmitting}
                              onClick={() =>
                                submitForm(
                                  values,
                                  setSubmitting,
                                  setFieldError,
                                  resetForm,
                                  setFieldValue,
                                  "save"
                                )
                              }
                            >
                              {translation.saveButton}
                            </LoadingButton>

                            <LoadingButton
                              variant="outlined"
                              disabled={!(taxes.length > 0)}
                              loading={isSubmitting}
                              onClick={() =>
                                submitForm(
                                  values,
                                  setSubmitting,
                                  setFieldError,
                                  resetForm,
                                  setFieldValue,
                                  "new"
                                )
                              }
                            >
                              {translation.saveAndNewButton}
                            </LoadingButton>
                            <LoadingButton
                              variant="outlined"
                              disabled={!(taxes.length > 0)}
                              loading={isSubmitting}
                              onClick={() =>
                                submitForm(
                                  values,
                                  setSubmitting,
                                  setFieldError,
                                  resetForm,
                                  setFieldValue,
                                  "back"
                                )
                              }
                            >
                              {translation.saveAndBackButton}
                            </LoadingButton>
                          </Stack>
                          {subscriptionToEdit ? (
                            subscriptionToEdit.id ? (
                              <LoadingButton
                                variant="outlined"
                                color="warning"
                                loading={isSubmitting}
                                onClick={() => {
                                  setOpenDelete(true);
                                }}
                              >
                                {translation.deleteButton}
                              </LoadingButton>
                            ) : (
                              ""
                            )
                          ) : (
                            ""
                          )}
                        </Stack>
                      </Stack>
                    </form>
                    <SubsCategoryForm
                      showNewSubsCategory={showNewSubsCategory}
                      setShowNewSubsCategory={setShowNewSubsCategory}
                      updateTrigger={updateTrigger}
                      setUpdateTrigger={setUpdateTrigger}
                      setNewSubsCategory={(newSubsCategory: string) => {
                        setFieldValue("type", newSubsCategory);
                      }}
                    ></SubsCategoryForm>
                  </>
                );
              }}
            </Formik>{" "}
            <Dialog
              open={openDelete}
              onClose={() => setOpenDelete(false)}
              aria-labelledby="alert-dialog-title-confirm-delete"
              aria-describedby="alert-dialog-confirm-delete"
            >
              <DialogTitle id="alert-dialog-title-confirm-delete">
                {translation.subscriptions.deleteSubscription}
              </DialogTitle>
              <DialogContent>
                <Typography>
                  {translation.subscriptions.deleteSubscriptionMessage}
                </Typography>
                <Typography fontWeight={700}>
                  {subscriptionToEdit?.name}
                </Typography>
              </DialogContent>
              <DialogActions>
                <Button
                  variant={"outlined"}
                  onClick={() => setOpenDelete(false)}
                >
                  {translation.closeButton}
                </Button>
                <Button
                  variant={"contained"}
                  onClick={() => {
                    if (subscriptionToEdit) {
                      deleteSubscription(subscriptionToEdit)
                        .then(() => {
                          setOpenDelete(false);
                          setUpdateTrigger(
                            (updateTrigger: any) => !updateTrigger
                          );
                          setSuccessMessage(
                            translation.subscriptions.deletedSubscription
                          );

                          navigate(routeNames.subscriptions);
                        })
                        .catch((e) => {
                          setOpenDelete(false);
                          setErrorMessage(e.response.data["hydra:description"]);
                        });
                    }
                  }}
                >
                  {translation.confirmDelete}
                </Button>
              </DialogActions>
            </Dialog>
          </>
        )
      )}
    </>
  );
};

export default SubscriptionsForm;
