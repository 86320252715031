import React, { useEffect, useState } from "react";
import { useUserContext } from "components/contexts/UserContext";
import useTranslation from "components/customHooks/translations";
import { Formik } from "formik";
import {
  createSettings,
  createTax,
  getSettingsByTenant,
  getTaxesByTenant,
  updateSettings,
} from "services/tenants";

import PapperCollapse from "components/PaperCollapse";
import { useLoadingContext } from "components/contexts/LoadingContext";
import { Box, Stack } from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";
import { ISettings, ITax } from "interfaces";
import SwitchField from "components/Form/SwitchField/SwitchField";
import ArrayNumberPercentageField from "components/Form/ArrayNumberPercentageField";
import { LoadingButton } from "@mui/lab";
import { errorMessage } from "helpers";
import { useTenantContext } from "components/contexts/TenantContext";

const Taxes = ({ settings }: { settings: ISettings }) => {
  const { selectedTenant, tenants, updateTrigger, setUpdateTrigger } =
    useUserContext();
  const { taxes, taxesLoading, setUpdateTaxesTrigger, updateTaxesTrigger } =
    useTenantContext();
  const { setLoading } = useLoadingContext();
  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();

  const [isEditingTax, setIsEditingTax] = useState(false);
  const translation = useTranslation();

  const submitForm = async (values: any, actions: any) => {
    let abortFlag = false;
    values.taxes.forEach((tax: ITax, index: number) => {
      if (index !== values.taxes.length - 1 && tax.taxPercentage === "") {
        setErrorMessage(translation.taxes.taxPercentageRequired);
        abortFlag = true;
        actions.setFieldError("taxes", translation.taxes.taxPercentageRequired);
      }
    });
    if (abortFlag) {
      actions.setSubmitting(false);
      return;
    }
    const newTaxes = values.taxes.map((tax: ITax) => {
      if (typeof tax.taxPercentage === "string") {
        return {
          ...tax,
          taxPercentage: parseFloat(tax.taxPercentage),
          tenant: tenants[selectedTenant]["@id"],
        };
      } else {
        return tax;
      }
    });
    newTaxes.pop();
    let fullRefreshFlag = false;
    try {
      await updateSettings({
        id: settings.id,
        taxIncluded: values.taxIncluded,
      });
      if (values.taxIncluded !== settings.taxIncluded) {
        fullRefreshFlag = true;
      }
    } catch (e) {
      actions.setSubmitting(false);
      setErrorMessage(errorMessage(e));
      return;
    }
    let taxesErrorFlag = false;
    for (let i = 0; i < newTaxes.length; i++) {
      if (newTaxes[i].id === undefined) {
        try {
          await createTax(newTaxes[i]);
        } catch (e) {
          taxesErrorFlag = true;
          setErrorMessage(errorMessage(e));
        }
      }
    }

    actions.setSubmitting(false);
    if (fullRefreshFlag) {
      setUpdateTrigger(!updateTrigger);
      setUpdateTaxesTrigger(!updateTaxesTrigger);
    } else {
      setUpdateTaxesTrigger(!updateTaxesTrigger);
    }
    if (!taxesErrorFlag) {
      setSuccessMessage(translation.taxes.taxesSaved);
    }
  };

  return (
    <>
      <PapperCollapse title={translation.taxesTitle}>
        <Formik
          initialValues={{
            taxIncluded: settings.taxIncluded,
            taxes: [...taxes, { label: "", taxPercentage: "", isActive: true }],
          }}
          enableReinitialize
          onSubmit={(values, actions) => {
            actions.setSubmitting(true);
            submitForm(values, actions);
          }}
        >
          {({ isSubmitting, submitForm, values, resetForm }) => (
            <Box sx={{ height: "100%" }}>
              <Stack spacing={2}>
                <SwitchField
                  disabled={isEditingTax}
                  name="taxIncluded"
                  label={translation.taxes.taxIncluedLabel}
                ></SwitchField>

                <Box>
                  <ArrayNumberPercentageField
                    name="taxes"
                    taxes={taxes}
                    label={translation.taxes.setTaxesLabel}
                    setIsEditingTax={setIsEditingTax}
                    setUpdateTrigger={setUpdateTaxesTrigger}
                    resetForm={resetForm}
                  />
                </Box>

                <Box display={"flex"} justifyContent="flex-start">
                  <LoadingButton
                    type="submit"
                    onClick={submitForm}
                    variant="contained"
                    loading={isSubmitting}
                    disabled={isEditingTax}
                  >
                    {translation.save}
                  </LoadingButton>
                </Box>
              </Stack>
            </Box>
          )}
        </Formik>
      </PapperCollapse>
    </>
  );
};

export default Taxes;
