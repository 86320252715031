import React, { useEffect, useState } from "react";

import Divider from "@mui/material/Divider";

import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import {
  Stack,
  Box,
  CardMedia,
  Card,
  TextField,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemAvatar,
  ListItemText,
  Button,
} from "@mui/material";
import defaultImage from "assets/img/placeholder-image.png";
import { errorMessage, getApiResponseData, isDiscountActive } from "helpers";
import { useUserContext } from "components/contexts/UserContext";
import { IProduct, IVariation } from "interfaces";
import useTranslation from "components/customHooks/translations";
import { searchProducts } from "services/products";
import { useSnackbarContext } from "components/contexts/SnackbarContext";

export default function ProductSearch({ handleAddProduct }: any) {
  const t = useTranslation();

  const { setSuccessMessage, setErrorMessage } = useSnackbarContext();
  const { selectedTenant, tenants } = useUserContext();

  const [productSearch, setProductSearch] = useState("");
  const [searchedProducts, setSearchedProducts] = useState<IProduct[]>([]);
  const [loadingSearchedProducts, setLoadingSearchedProducts] = useState(false);

  useEffect(() => {
    const getData = setTimeout(() => {
      console.log(productSearch);
      if (productSearch.length > 1) {
        setLoadingSearchedProducts(true);
        setSearchedProducts([]);
        searchProducts(productSearch, tenants[selectedTenant].id)
          .then((response) => {
            setLoadingSearchedProducts(false);

            setSearchedProducts(getApiResponseData(response));
          })
          .catch((e) => {
            setLoadingSearchedProducts(false);
            setErrorMessage(errorMessage(e));
          });
      }
    }, 1000);

    return () => clearTimeout(getData);
  }, [productSearch]);

  const handleCloseNewProduct = () => {
    setSearchedProducts([]);
    setProductSearch("");
  };

  return (
    <Stack spacing={2}>
      <Box>
        <TextField
          placeholder={t.order.searchProduct}
          variant="standard"
          value={productSearch}
          helperText={t.order.searchProductHelp}
          onChange={(e) => setProductSearch(e.target.value)}
        ></TextField>
      </Box>
      <Box>
        {loadingSearchedProducts && (
          <Box display="flex" justifyContent="center">
            <CircularProgress />
          </Box>
        )}
        {searchedProducts.length > 0 && (
          <List
            sx={{
              maxHeight: "400px",
              overflow: "auto",
            }}
          >
            {searchedProducts.map((product) => {
              return (
                <ListItem
                  key={"searchedProduct-" + product.id}
                  disablePadding
                  sx={{
                    width: "100%",

                    py: 1,
                  }}
                >
                  {!product.isVariable ? (
                    <ListItemButton
                      onClick={async () => {
                        await handleAddProduct(product, undefined);
                        handleCloseNewProduct();
                      }}
                    >
                      <Stack direction="row" spacing={2}>
                        {product.images.length > 0 && (
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                width: 75,
                                height: 75,
                              }}
                              variant="rounded"
                              src={product.images[0].contentUrl}
                            ></Avatar>
                          </ListItemAvatar>
                        )}
                        {product.images.length === 0 && (
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                width: 75,
                                height: 75,
                              }}
                              variant="rounded"
                            >
                              {product.name[0]}
                            </Avatar>
                          </ListItemAvatar>
                        )}
                        <ListItemText primary={product.name}></ListItemText>
                      </Stack>
                    </ListItemButton>
                  ) : (
                    <Stack flexGrow={1}>
                      <Stack
                        direction="row"
                        sx={{
                          p: "8px 16px",
                        }}
                        flexGrow={1}
                        spacing={2}
                      >
                        {product.images.length > 0 && (
                          <ListItemAvatar>
                            <Avatar
                              variant="rounded"
                              sx={{
                                width: 75,
                                height: 75,
                              }}
                              src={product.images[0].contentUrl}
                            ></Avatar>
                          </ListItemAvatar>
                        )}
                        {product.images.length === 0 && (
                          <ListItemAvatar>
                            <Avatar
                              sx={{
                                width: 75,
                                height: 75,
                              }}
                              variant="rounded"
                            >
                              {product.name[0]}
                            </Avatar>
                          </ListItemAvatar>
                        )}
                        <Stack spacing={1}>
                          <ListItemText primary={product.name}></ListItemText>
                          <Stack spacing={2} direction="row">
                            {product.variations &&
                              product.variations.map(
                                (variation: IVariation) => {
                                  return (
                                    <Box
                                      key={
                                        "variation-" +
                                        product.id +
                                        "-" +
                                        variation.id
                                      }
                                    >
                                      <Button
                                        variant="outlined"
                                        size="small"
                                        onClick={async () => {
                                          await handleAddProduct(
                                            product,
                                            variation
                                          );
                                          handleCloseNewProduct();
                                        }}
                                      >
                                        {variation.name}
                                      </Button>
                                    </Box>
                                  );
                                }
                              )}
                          </Stack>
                        </Stack>
                      </Stack>
                    </Stack>
                  )}
                </ListItem>
              );
            })}
          </List>
        )}
      </Box>
    </Stack>
  );
}
