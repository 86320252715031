import axios from "axios";
import { backendURL } from "config";
import { parseJwt } from "helpers";

export async function createNewUser(user: {
  firstName: string;
  lastName: string;
  email: string;
  password: string;
}) {
  const URL = backendURL + "/register/admin";

  const res = axios
    .post(
      URL,
      JSON.stringify({
        ...user,
        roles: ["ROLE_ADMIN"],
      })
    )
    .then((res) => res)
    .catch((error) => error);
  return res;
}

export async function login(email: string, password: string) {
  return axios
    .post(backendURL + "/login/admin", {
      email,
      password,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("token", response.data["token"]);
        localStorage.setItem("refresh_token", response.data["refreshToken"]);
        localStorage.setItem("id", response.data["id"]);
        localStorage.setItem("tenantIds", response.data["tenantIds"]);
      }
      return response.data;
    })
    .catch((e) => {
      throw e;
    });
}

export async function requestPasswordReset(email: string) {
  return axios
    .get(
      backendURL + "/admin/password-reset?email=" + encodeURIComponent(email)
    )
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw e;
    });
}

export async function passwordReset(token: string, password: string) {
  return axios
    .post(backendURL + "/admin/password-reset", {
      token,
      password,
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      throw e;
    });
}

export async function refreshLogin() {
  return axios
    .post(backendURL + "/login/admin/refresh", {
      refreshToken: `${localStorage.getItem("refresh_token")}`,
      token: `${localStorage.getItem("token")}`,
    })
    .then((response) => {
      if (response.data.token) {
        localStorage.setItem("user", JSON.stringify(response.data));
        localStorage.setItem("token", response.data["token"]);
        localStorage.setItem("id", response.data["id"]);
        localStorage.setItem("tenantIds", response.data["tenantIds"]);
      }
      return response.data;
    })
    .catch(() => {
      localStorage.setItem("user", "error");
      return false;
    });
}

export async function logout() {
  localStorage.removeItem("user");
  localStorage.removeItem("token");
  localStorage.removeItem("refresh_token");
  localStorage.removeItem("id");
  localStorage.removeItem("role");
  localStorage.removeItem("tenantIds");
}

export function authHeader() {
  const jwt = localStorage.getItem("user");
  if (jwt !== null) {
    const user = JSON.parse(jwt);
    if (user && user.token) {
      return { Authorization: "Bearer " + user.token };
    } else {
      return {};
    }
  } else {
    return {};
  }
}

export function getUser() {
  const jwt = localStorage.getItem("user");

  if (jwt !== null) {
    const user = JSON.parse(jwt);

    if (user && user.token) {
      return user;
    } else {
      return {};
    }
  } else {
    return {};
  }
}
