import React from "react";
import { Field } from "formik";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { FormHelperText, TextField } from "@mui/material";
import { TextFields } from "@mui/icons-material";

const SelectField = ({
  label,
  shrink,
  name,
  children,
  disabled = false,
  multiple = false,
  width = 220,
}: {
  label?: string;
  shrink?: boolean;
  name: string;
  children: React.ReactNode;
  disabled?: boolean;
  multiple?: boolean;
  width?: number | string;
}) => {
  return (
    <Field name={name}>
      {({ field, form }: any) => {
        const isValid = !form.errors[field.name];
        const isInvalid = form.touched[field.name] && !isValid;
        return (
          <FormControl
            variant="standard"
            sx={{ minWidth: width, maxWidth: width }}
            error={isInvalid}
          >
            <InputLabel id={name + "-select-standard-label"} shrink={shrink}>
              {label}
            </InputLabel>
            <Select
              multiple={multiple}
              disabled={disabled}
              {...field}
              labelId={name + "-select-standard-label"}
              id={name + "-select-standard"}
              label={label}
              value={field.value}
              error={isInvalid}
              MenuProps={{
                PaperProps: { sx: { maxHeight: 300 } },
              }}
            >
              {children}
            </Select>
            {isInvalid && (
              <FormHelperText>{form.errors[field.name]}</FormHelperText>
            )}
          </FormControl>
        );
      }}
    </Field>
  );
};

export default SelectField;
