import * as React from "react";
import { InfoOutlined } from "@mui/icons-material";
import {
  Alert,
  IconButton,
  Popover,
  Snackbar,
  Typography,
} from "@mui/material";
import { useSnackbarContext } from "components/contexts/SnackbarContext";

const SnackbarError = () => {
  const [open, setOpen] = React.useState(false);
  const { errorMessage, setErrorMessage } = useSnackbarContext();
  React.useEffect(() => {
    setOpen(Boolean(errorMessage));
  }, [errorMessage]);

  const handleClose = () => {
    setOpen(false);
    setErrorMessage("");
  };

  return (
    <>
      <Snackbar
        open={open}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        autoHideDuration={2000}
        onClose={handleClose}
      >
        <Alert
          variant="filled"
          elevation={6}
          onClose={handleClose}
          severity="error"
          sx={{ width: "100%" }}
        >
          Error: {errorMessage}
        </Alert>
      </Snackbar>
    </>
  );
};

export default SnackbarError;
