const timezones = [
  {
    name: "Africa/Algiers",
    group: "Africa",
  },
  {
    name: "Africa/Gaborone",
    group: "Africa",
  },
  {
    name: "Africa/Douala",
    group: "Africa",
  },
  {
    name: "Africa/Bangui",
    group: "Africa",
  },
  {
    name: "Africa/Ndjamena",
    group: "Africa",
  },
  {
    name: "Africa/Kinshasa",
    group: "Africa",
  },
  {
    name: "Africa/Djibouti",
    group: "Africa",
  },
  {
    name: "Africa/Cairo",
    group: "Africa",
  },
  {
    name: "Africa/Malabo",
    group: "Africa",
  },
  {
    name: "Africa/Asmara",
    group: "Africa",
  },
  {
    name: "Africa/Addis_Ababa",
    group: "Africa",
  },
  {
    name: "Africa/Libreville",
    group: "Africa",
  },
  {
    name: "Africa/Banjul",
    group: "Africa",
  },
  {
    name: "Africa/Accra",
    group: "Africa",
  },
  {
    name: "Africa/Conakry",
    group: "Africa",
  },
  {
    name: "Africa/Bissau",
    group: "Africa",
  },
  {
    name: "Africa/Abidjan",
    group: "Africa",
  },
  {
    name: "Africa/Nairobi",
    group: "Africa",
  },
  {
    name: "Africa/Maseru",
    group: "Africa",
  },
  {
    name: "Africa/Monrovia",
    group: "Africa",
  },
  {
    name: "Africa/Tripoli",
    group: "Africa",
  },
  {
    name: "Africa/Blantyre",
    group: "Africa",
  },
  {
    name: "Africa/Bamako",
    group: "Africa",
  },
  {
    name: "Africa/Nouakchott",
    group: "Africa",
  },
  {
    name: "Africa/Casablanca",
    group: "Africa",
  },
  {
    name: "Africa/Maputo",
    group: "Africa",
  },
  {
    name: "Africa/Windhoek",
    group: "Africa",
  },
  {
    name: "Africa/Niamey",
    group: "Africa",
  },
  {
    name: "Africa/Lagos",
    group: "Africa",
  },
  {
    name: "Africa/Brazzaville",
    group: "Africa",
  },
  {
    name: "Africa/Kigali",
    group: "Africa",
  },
  {
    name: "Africa/Sao_Tome",
    group: "Africa",
  },
  {
    name: "Africa/Dakar",
    group: "Africa",
  },
  {
    name: "Africa/Freetown",
    group: "Africa",
  },
  {
    name: "Africa/Mogadishu",
    group: "Africa",
  },
  {
    name: "Africa/Johannesburg",
    group: "Africa",
  },
  {
    name: "Africa/Juba",
    group: "Africa",
  },
  {
    name: "Africa/Khartoum",
    group: "Africa",
  },
  {
    name: "Africa/Mbabane",
    group: "Africa",
  },
  {
    name: "Africa/Dar_es_Salaam",
    group: "Africa",
  },
  {
    name: "Africa/Lome",
    group: "Africa",
  },
  {
    name: "Africa/Tunis",
    group: "Africa",
  },
  {
    name: "Africa/Kampala",
    group: "Africa",
  },
  {
    name: "Africa/El_Aaiun",
    group: "Africa",
  },
  {
    name: "Africa/Lusaka",
    group: "Africa",
  },
  {
    name: "Africa/Harare",
    group: "Africa",
  },
  {
    name: "America/Nassau",
    group: "America",
  },
  {
    name: "America/Belize",
    group: "America",
  },
  {
    name: "America/Noronha",
    group: "America",
  },
  {
    name: "America/Tortola",
    group: "America",
  },
  {
    name: "America/St_Johns",
    group: "America",
  },
  {
    name: "America/Cayman",
    group: "America",
  },
  {
    name: "America/Santiago",
    group: "America",
  },
  {
    name: "America/Bogota",
    group: "America",
  },
  {
    name: "America/Costa_Rica",
    group: "America",
  },
  {
    name: "America/Havana",
    group: "America",
  },
  {
    name: "America/Curacao",
    group: "America",
  },
  {
    name: "America/Dominica",
    group: "America",
  },
  {
    name: "America/Santo_Domingo",
    group: "America",
  },
  {
    name: "America/Guayaquil",
    group: "America",
  },
  {
    name: "America/El_Salvador",
    group: "America",
  },
  {
    name: "America/Cayenne",
    group: "America",
  },
  {
    name: "America/Godthab",
    group: "America",
  },
  {
    name: "America/Grenada",
    group: "America",
  },
  {
    name: "America/Guadeloupe",
    group: "America",
  },
  {
    name: "America/Guatemala",
    group: "America",
  },
  {
    name: "America/Guyana",
    group: "America",
  },
  {
    name: "America/Port-au-Prince",
    group: "America",
  },
  {
    name: "America/Tegucigalpa",
    group: "America",
  },
  {
    name: "America/Jamaica",
    group: "America",
  },
  {
    name: "America/Martinique",
    group: "America",
  },
  {
    name: "America/Mexico_City",
    group: "America",
  },
  {
    name: "America/Montserrat",
    group: "America",
  },
  {
    name: "America/Managua",
    group: "America",
  },
  {
    name: "America/Panama",
    group: "America",
  },
  {
    name: "America/Asuncion",
    group: "America",
  },
  {
    name: "America/Lima",
    group: "America",
  },
  {
    name: "America/Puerto_Rico",
    group: "America",
  },
  {
    name: "America/St_Kitts",
    group: "America",
  },
  {
    name: "America/St_Lucia",
    group: "America",
  },
  {
    name: "America/Marigot",
    group: "America",
  },
  {
    name: "America/Miquelon",
    group: "America",
  },
  {
    name: "America/St_Vincent",
    group: "America",
  },
  {
    name: "America/Lower_Princes",
    group: "America",
  },
  {
    name: "America/Paramaribo",
    group: "America",
  },
  {
    name: "America/Port_of_Spain",
    group: "America",
  },
  {
    name: "America/Grand_Turk",
    group: "America",
  },
  {
    name: "America/St_Thomas",
    group: "America",
  },
  {
    name: "America/New_York",
    group: "America",
  },
  {
    name: "America/Montevideo",
    group: "America",
  },
  {
    name: "America/Caracas",
    group: "America",
  },
  {
    name: "Asia/Thimphu",
    group: "Asia",
  },
  {
    name: "Asia/Phnom_Penh",
    group: "Asia",
  },
  {
    name: "Asia/Shanghai",
    group: "Asia",
  },
  {
    name: "Asia/Nicosia",
    group: "Asia",
  },
  {
    name: "Asia/Dili",
    group: "Asia",
  },
  {
    name: "Asia/Tbilisi",
    group: "Asia",
  },
  {
    name: "Asia/Hong_Kong",
    group: "Asia",
  },
  {
    name: "Asia/Kolkata",
    group: "Asia",
  },
  {
    name: "Asia/Jakarta",
    group: "Asia",
  },
  {
    name: "Asia/Tehran",
    group: "Asia",
  },
  {
    name: "Asia/Baghdad",
    group: "Asia",
  },
  {
    name: "Asia/Jerusalem",
    group: "Asia",
  },
  {
    name: "Asia/Tokyo",
    group: "Asia",
  },
  {
    name: "Asia/Amman",
    group: "Asia",
  },
  {
    name: "Asia/Almaty",
    group: "Asia",
  },
  {
    name: "Asia/Kuwait",
    group: "Asia",
  },
  {
    name: "Asia/Bishkek",
    group: "Asia",
  },
  {
    name: "Asia/Vientiane",
    group: "Asia",
  },
  {
    name: "Asia/Beirut",
    group: "Asia",
  },
  {
    name: "Asia/Macau",
    group: "Asia",
  },
  {
    name: "Asia/Kuala_Lumpur",
    group: "Asia",
  },
  {
    name: "Asia/Ulaanbaatar",
    group: "Asia",
  },
  {
    name: "Asia/Rangoon",
    group: "Asia",
  },
  {
    name: "Asia/Kathmandu",
    group: "Asia",
  },
  {
    name: "Asia/Pyongyang",
    group: "Asia",
  },
  {
    name: "Asia/Muscat",
    group: "Asia",
  },
  {
    name: "Asia/Karachi",
    group: "Asia",
  },
  {
    name: "Asia/Gaza",
    group: "Asia",
  },
  {
    name: "Asia/Manila",
    group: "Asia",
  },
  {
    name: "Asia/Qatar",
    group: "Asia",
  },
  {
    name: "Asia/Riyadh",
    group: "Asia",
  },
  {
    name: "Asia/Singapore",
    group: "Asia",
  },
  {
    name: "Asia/Seoul",
    group: "Asia",
  },
  {
    name: "Asia/Colombo",
    group: "Asia",
  },
  {
    name: "Asia/Damascus",
    group: "Asia",
  },
  {
    name: "Asia/Taipei",
    group: "Asia",
  },
  {
    name: "Asia/Dushanbe",
    group: "Asia",
  },
  {
    name: "Asia/Bangkok",
    group: "Asia",
  },
  {
    name: "Asia/Ashgabat",
    group: "Asia",
  },
  {
    name: "Asia/Samarkand",
    group: "Asia",
  },
  {
    name: "Asia/Ho_Chi_Minh",
    group: "Asia",
  },
  {
    name: "Asia/Aden",
    group: "Asia",
  },
  {
    name: "Atlantic/Cape_Verde",
    group: "Atlantic",
  },
  {
    name: "Atlantic/Stanley",
    group: "Atlantic",
  },
  {
    name: "Atlantic/Faroe",
    group: "Atlantic",
  },
  {
    name: "Atlantic/Reykjavik",
    group: "Atlantic",
  },
  {
    name: "Atlantic/St_Helena",
    group: "Atlantic",
  },
  {
    name: "Atlantic/South_Georgia",
    group: "Atlantic",
  },
  {
    name: "Europe/Minsk",
    group: "Europe",
  },
  {
    name: "Europe/Zagreb",
    group: "Europe",
  },
  {
    name: "Europe/Prague",
    group: "Europe",
  },
  {
    name: "Europe/Copenhagen",
    group: "Europe",
  },
  {
    name: "Europe/Tallinn",
    group: "Europe",
  },
  {
    name: "Europe/Helsinki",
    group: "Europe",
  },
  {
    name: "Europe/Paris",
    group: "Europe",
  },
  {
    name: "Europe/Berlin",
    group: "Europe",
  },
  {
    name: "Europe/Gibraltar",
    group: "Europe",
  },
  {
    name: "Europe/Athens",
    group: "Europe",
  },
  {
    name: "Europe/Guernsey",
    group: "Europe",
  },
  {
    name: "Europe/Budapest",
    group: "Europe",
  },
  {
    name: "Europe/Dublin",
    group: "Europe",
  },
  {
    name: "Europe/Isle_of_Man",
    group: "Europe",
  },
  {
    name: "Europe/Rome",
    group: "Europe",
  },
  {
    name: "Europe/Jersey",
    group: "Europe",
  },
  {
    name: "Europe/Riga",
    group: "Europe",
  },
  {
    name: "Europe/Vaduz",
    group: "Europe",
  },
  {
    name: "Europe/Vilnius",
    group: "Europe",
  },
  {
    name: "Europe/Luxembourg",
    group: "Europe",
  },
  {
    name: "Europe/Skopje",
    group: "Europe",
  },
  {
    name: "Europe/Malta",
    group: "Europe",
  },
  {
    name: "Europe/Chisinau",
    group: "Europe",
  },
  {
    name: "Europe/Monaco",
    group: "Europe",
  },
  {
    name: "Europe/Podgorica",
    group: "Europe",
  },
  {
    name: "Europe/Amsterdam",
    group: "Europe",
  },
  {
    name: "Europe/Oslo",
    group: "Europe",
  },
  {
    name: "Europe/Warsaw",
    group: "Europe",
  },
  {
    name: "Europe/Lisbon",
    group: "Europe",
  },
  {
    name: "Europe/Bucharest",
    group: "Europe",
  },
  {
    name: "Europe/Kaliningrad",
    group: "Europe",
  },
  {
    name: "Europe/San_Marino",
    group: "Europe",
  },
  {
    name: "Europe/Belgrade",
    group: "Europe",
  },
  {
    name: "Europe/Bratislava",
    group: "Europe",
  },
  {
    name: "Europe/Ljubljana",
    group: "Europe",
  },
  {
    name: "Europe/Madrid",
    group: "Europe",
  },
  {
    name: "Europe/Stockholm",
    group: "Europe",
  },
  {
    name: "Europe/Zurich",
    group: "Europe",
  },
  {
    name: "Europe/Istanbul",
    group: "Europe",
  },
  {
    name: "Europe/Kiev",
    group: "Europe",
  },
  {
    name: "Europe/London",
    group: "Europe",
  },
  {
    name: "Europe/Vatican",
    group: "Europe",
  },
  {
    name: "Indian/Chagos",
    group: "Indian",
  },
  {
    name: "Indian/Christmas",
    group: "Indian",
  },
  {
    name: "Indian/Cocos",
    group: "Indian",
  },
  {
    name: "Indian/Comoro",
    group: "Indian",
  },
  {
    name: "Indian/Kerguelen",
    group: "Indian",
  },
  {
    name: "Indian/Antananarivo",
    group: "Indian",
  },
  {
    name: "Indian/Maldives",
    group: "Indian",
  },
  {
    name: "Indian/Mauritius",
    group: "Indian",
  },
  {
    name: "Indian/Mayotte",
    group: "Indian",
  },
  {
    name: "Indian/Reunion",
    group: "Indian",
  },
  {
    name: "Indian/Mahe",
    group: "Indian",
  },
  {
    name: "Pacific/Rarotonga",
    group: "Pacific",
  },
  {
    name: "Pacific/Fiji",
    group: "Pacific",
  },
  {
    name: "Pacific/Tahiti",
    group: "Pacific",
  },
  {
    name: "Pacific/Guam",
    group: "Pacific",
  },
  {
    name: "Pacific/Tarawa",
    group: "Pacific",
  },
  {
    name: "Pacific/Majuro",
    group: "Pacific",
  },
  {
    name: "Pacific/Chuuk",
    group: "Pacific",
  },
  {
    name: "Pacific/Nauru",
    group: "Pacific",
  },
  {
    name: "Pacific/Noumea",
    group: "Pacific",
  },
  {
    name: "Pacific/Auckland",
    group: "Pacific",
  },
  {
    name: "Pacific/Niue",
    group: "Pacific",
  },
  {
    name: "Pacific/Norfolk",
    group: "Pacific",
  },
  {
    name: "Pacific/Saipan",
    group: "Pacific",
  },
  {
    name: "Pacific/Palau",
    group: "Pacific",
  },
  {
    name: "Pacific/Port_Moresby",
    group: "Pacific",
  },
  {
    name: "Pacific/Pitcairn",
    group: "Pacific",
  },
  {
    name: "Pacific/Apia",
    group: "Pacific",
  },
  {
    name: "Pacific/Guadalcanal",
    group: "Pacific",
  },
  {
    name: "Pacific/Fakaofo",
    group: "Pacific",
  },
  {
    name: "Pacific/Tongatapu",
    group: "Pacific",
  },
  {
    name: "Pacific/Funafuti",
    group: "Pacific",
  },
  {
    name: "Pacific/Johnston",
    group: "Pacific",
  },
  {
    name: "Pacific/Efate",
    group: "Pacific",
  },
  {
    name: "Pacific/Wallis",
    group: "Pacific",
  },
];

export default timezones;
